import React, { Component } from 'react'
import Autosuggest from 'react-autosuggest';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import { BsX } from "react-icons/bs";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import SearchServices from '../services/SearchServices';
import { toast } from 'react-toastify';
import mapboxgl from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import Pagination from "react-js-pagination";
import { errorMessages, cancelErrorMessages, search } from '../constants/Constants';
import {configTimers} from '../constants/Timers';
import axios from 'axios';
import '../map/styles/map.scss';

const toastId = "toast-id";
let indexArray = [];
let placeDataArray = [];
// let source = axios.CancelToken.source();
export class SearchAdd extends Component {
    markers = [];
    timeout = 0;
    cancelToken;
    satBounds = [];
    constructor(props) {
        super(props);
        this.searchService = new SearchServices();
        this.state = {
            value: '',
            suggestions: [],
            searchPlaces: [],
            enterKeyHit: false,
            showSpinner: false,
            addressCounter: 1
        }
        this.popups = [];
    }

    componentWillUnmount() {
        this.removeMarkers();
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });

    };

    getSuggestions = value => {
        if (value && value.length > 2 && !this.state.enterKeyHit) {
            this.setState({
                showSpinner: true
            })
            //Check if there are any previous pending requests
            if (typeof this.cancelToken != typeof undefined) {
                this.cancelToken.cancel("Operation canceled due to new request.")
            }

            this.cancelToken = axios.CancelToken.source();
            this.searchService.getSuggestions(this.props.mapCenter(), value, this.cancelToken).then(
                (response) => {
                    if (response.status == 200 && response.data) {
                        if (response.data.suggestions && response.data.suggestions.length > 0) {
                            if (this.state.enterKeyHit) {
                                this.setState({ suggestions: this.state.enterKeyHit ? [] : response.data.suggestions, showSpinner: false });
                            } else {
                                this.removeMarkers();
                                this.setState({
                                    suggestions: response.data.suggestions,
                                    showSpinner: false,
                                    searchPlaces: []
                                });
                            }
                        } else {
                            this.setState({
                                showSpinner: false,
                            });
                        }
                    }
                }, error => {
                    this.setState({
                        showSpinner: false,
                    });
                    if (error.code == configTimers.cancelStatusCode) {
                        this.removeMarkers();
                        this.setState({ searchPlaces: [] });
                        toast.error(cancelErrorMessages.searchTimeOut, { toastId: toastId });
                    } else {
                        this.handleError(error, errorMessages.suggestionError);
                    }
                }
            );
        }
    };

    onSuggestionsFetchRequested = ({ value, reason }) => {
        if (!this.props.showMap)
            return;
        if (reason !== 'input-focused') {
            if (this.timeout)
                clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.getSuggestions(value);
            }, 500);
        }
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
        // this.removeMarkers();
    };

    getSuggestionValue = (suggestion) => {
        return suggestion.line1 + this.formatLine(suggestion.line2);
    }

    renderSuggestion = (suggestion) => {
        return (
            <span>{suggestion.line1 + this.formatLine(suggestion.line2)}</span>
        );
    }

    formatLine = (line) => {
        return line && line !== '' ? (', ' + line) : '';
    }

    onSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            showSpinner: true
        })
        this.searchService.getSearchResultsBySuggestionFilter(this.props.mapCenter(), suggestion.search_id).then(
            (response) => {
                this.handleResponse(response);
            }, error => {
                if (error.code == configTimers.cancelStatusCode) {
                    this.removeMarkers();
                    this.setState({ searchPlaces: [] });
                    toast.error(cancelErrorMessages.searchTimeOut, { toastId: toastId });
                } else {
                    this.handleError(error, errorMessages.suggestionError);
                }
                this.setState({
                    showSpinner: false,
                });
            });
    }

    onKeyUp = (event) => {
        if (!this.props.showMap)
            return;
        if (event.keyCode === 13) {
            this.setState({
                showSpinner: true,
                enterKeyHit: true
            })
            event.preventDefault();
            event.stopPropagation();
            this.handleSearch(event);
        } else {
            if (this.state.value.length === 0) {
                this.clearPOISearch();
            }
            this.setState({ suggestions: [], enterKeyHit: false })
        }
    }

    onBlur = (event) => {
        if (event.target)
            event.target.blur();
    }

    handleSearch = (event) => {
        if (!this.props.showMap)
            return;
        this.onBlur(event);
        if (!this.state.value || !this.state.value.trim()) {
            toast.error(errorMessages.searchEmptyError, { toastId: toastId });
            this.setState({
                showSpinner: false,
                enterKeyHit: false
            })
        } else {
            this.searchService.getAddressSearchResultsByQuery(this.props.mapCenter(), this.state.value).then((response) => {
                this.handleResponse(response);
            }, error => {
                if (error.code == configTimers.cancelStatusCode) {
                    this.removeMarkers();
                    this.setState({ searchPlaces: [] });
                    toast.error(cancelErrorMessages.searchTimeOut, { toastId: toastId });
                } else {
                    this.handleError(error, errorMessages.searchError);
                }
                this.setState({
                    showSpinner: false,
                });
            });
        }
    }

    handleAddress = (data, id, e) => {
        let bounds = [];
        let satBounds = [];
        let place;
        let searchPlaces = this.state.searchPlaces;
        searchPlaces.map((list, index) => {
            if (id === index) {
                place = list;
                searchPlaces[index].show = true;
            }
            else {
                searchPlaces[index].show = false;
            }
        })
        this.setState({
            searchPlaces
        })
        if (this.markers && this.markers.length > 0) {
            this.markers.map((pin, index) => {
                const pinPopup = pin.getPopup();
                if (id === index) {
                    this.setPopUpData(pinPopup, place, id);
                    bounds.push(pinPopup.getLngLat());
                    this.setBounds(bounds, this.props.map, search.pinZoom);
                    //setTimeout(() => pinPopup.addTo(this.props.map), 800);
                    pinPopup.addTo(this.props.map);
                } else {
                    pinPopup.remove();
                }
            })
        }
    }

    setPopUpData = (pinPopup, pageItem, coun) => {
        const lnglat = pinPopup.getLngLat();
        let paginationCount = 0;
        indexArray = [];
        placeDataArray = [];
        this.state.searchPlaces.forEach((place, j) => {
            const location1 = place.location;
            if (lnglat.lng === location1.coordinates[0].longitude
                && lnglat.lat === location1.coordinates[0].latitude) {
                paginationCount++;
                indexArray.push(j);
                placeDataArray.push(place);
            }
        });

        if (paginationCount > 1) {
            indexArray.forEach((placeId, count) => {
                const searchItem = this.state.searchPlaces[placeId];
                if (coun === placeId)
                    pinPopup.setDOMContent(this.addPopupContent(searchItem, count, indexArray));
            });
        }
    }

    setBounds = (bounds, map, zoomLevel) => {
        if (map) {
            var bbox = new mapboxgl.LngLatBounds();
            if (bounds.length === 1) {
                map.flyTo({
                    center: bounds[0],
                    zoom: zoomLevel,
                    bearing: 0,
                    speed: 2, // make the flying slow
                    curve: 4.5, // change the speed at which it zooms out
                    easing: function (t) { return t; }
                });
            } else if (bounds.length > 1) {
                bounds.forEach((coordinate) => {
                    bbox.extend(coordinate);
                });

                if ((bbox.getSouthWest().lat === bbox.getNorthEast().lat) && (bbox.getSouthWest().lng === bbox.getNorthEast().lng)) {
                    map.flyTo({
                        center: bounds[0],
                        zoom: zoomLevel,
                        bearing: 0,
                        speed: 2, // make the flying slow
                        curve: 4.5, // change the speed at which it zooms out
                        easing: function (t) { return t; }
                    });
                } else {
                    map.fitBounds(bbox, {
                        maxZoom: zoomLevel,
                        padding: { top: 35, bottom: 25, left: 25, right: 25 }
                    });
                }
            }
        }
    }

    satSetBounds = (bounds, map, zoomLevel) => {
        if (map) {
            var bbox = new mapboxgl.LngLatBounds();
            if (bounds.length === 1) {
                map.flyTo({
                    center: bounds[0],
                    zoom: zoomLevel,
                    bearing: 0,
                    screenSpeed: 4, // make the flying slow
                    minZoom: 12,
                    easing: function (t) { return t; }
                });
            } else if (bounds.length > 1) {
                bounds.forEach((coordinate) => {
                    bbox.extend(coordinate);
                });

                if ((bbox.getSouthWest().lat === bbox.getNorthEast().lat) && (bbox.getSouthWest().lng === bbox.getNorthEast().lng)) {
                    map.flyTo({
                        center: bounds[0],
                        zoom: zoomLevel,
                        bearing: 0,
                        screenSpeed: 4, // make the flying slow
                        minZoom: 12,
                        easing: function (t) { return t; }
                    });
                } else {
                    map.fitBounds(bbox, {
                        maxZoom: zoomLevel,
                        padding: { top: 35, bottom: 25, left: 25, right: 25 }
                    });
                }
            }
        }
    }

    clearPOISearch = () => {
        this.setState({
            value: '',
            showSpinner: false,
            searchPlaces: []
        });
        this.removeMarkers();
    }

    handleUp = () => {
        this.setState({
            showAddressList: false,
            addressCounter: --this.state.addressCounter
        })
    }

    handleDown = () => {
        this.setState({
            showAddressList: true,
            addressCounter: ++this.state.addressCounter
        })
    }

    handleError = (error, msg) => {
        let errorMessage = msg;
        this.setState({
            showSpinner: false,
        })
        this.removeMarkers();
        this.setState({ searchPlaces: [] });
        if (error && error.response && error.response.status) {
            errorMessage = error.response.data.detail;
        }
        if (errorMessage && errorMessage.length > 0) {
            toast.dismiss();
            toast.error(errorMessage, { toastId: toastId });
        }
    }

    handleResponse = (response) => {
        if (response.status === 200 && response.data.places.length > 0) {
            if (response.data.places.length == 1) {
                response.data.places.map((place, index) => {
                    place.showSinglePopup = (index == 0);
                })
            }
            this.setState({
                searchPlaces: response.data.places,
            }, () => {
                this.createMarkers();
            })
        } else {
            this.removeMarkers();
            this.setState({
                searchPlaces: [],
                showSpinner: false,
            });
            toast.dismiss();
            toast.warning(errorMessages.noDataFound, { toastId: toastId });
        }
        this.setState({
            addressCounter: 1
        })
    }

    highlightPOI = (id) => {
        let searchPlaces = this.state.searchPlaces;
        searchPlaces.map((list, index) => {
            if (id === index) {
                searchPlaces[index].show = true;
            }
            else {
                searchPlaces[index].show = false;
            }
        })
        this.setState({
            searchPlaces
        })
        const searchElement = document.querySelector('.search-list');
        if (searchElement) {
            searchElement.scrollTop = (id * 53);
        }
    }

    onPopupClose = () => {
        this.popups.forEach(popup => {
            popup.remove();
        });
        let searchPlaces = this.state.searchPlaces;
        searchPlaces.map((list) => {
            list.show = false;
        })
        this.setState({
            searchPlaces
        })
    }

    createMarkers = () => {
        this.removeMarkers();
        let bounds = [];
        this.satBounds = [];
        let addPopUp = false;
        this.state.searchPlaces.forEach((place, index) => {
            let el = document.createElement('div');
            // el.addEventListener('click', this.highlightAddress);
            el.addEventListener('click', this.highlightPOI.bind(this, index));
            el.className = 'search-marker';
            let options = { element: el, anchor: 'center', color: '#ff5a1d', scale: 0.8 };
            if (place.hasOwnProperty('showSinglePopup') && place.showSinglePopup) {
                let location = place.location;
                const lngLat = new mapboxgl.LngLat(location.coordinates[0].longitude, location.coordinates[0].latitude);
                const marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.map);
                var popupOptions = { offset: 16, closeButton: false };
                const popup = new mapboxgl.Popup(popupOptions);
                popup.setDOMContent(this.addPopup(place, place.location, index));
                marker.setPopup(popup);
                if (place.hasOwnProperty('show') && place.show) {
                    popup.addTo(this.props.map);
                } else if (place.hasOwnProperty('showSinglePopup') && place.showSinglePopup) {
                    popup.addTo(this.props.map);
                }
                this.markers.push(marker);
                bounds.push(lngLat);
                this.setBounds(bounds, this.props.map, search.pinZoom);
                this.setState({ showSpinner: false })
                this.popups.push(popup);
            } else {
                let location = place.location;
                const lngLat = new mapboxgl.LngLat(location.coordinates[0].longitude, location.coordinates[0].latitude);
                const marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.map);
                this.markers.push(marker);
                bounds.push(lngLat);
                this.setBounds(bounds, this.props.map, search.pinZoom);
                addPopUp = true;
            }
        });
        if (addPopUp) {
            this.addPopupToMarker();
        }
        this.setState({ showSpinner: false });
    }

    addPopupToMarker = () => {
        this.markers.forEach((marker, i) => {
            const lnglat = marker.getLngLat();
            let paginationCount = 0;
            indexArray = [];
            placeDataArray = [];
            this.state.searchPlaces.forEach((place, j) => {
                const location1 = place.location;
                if (lnglat.lng === location1.coordinates[0].longitude
                    && lnglat.lat === location1.coordinates[0].latitude) {
                    paginationCount++;
                    indexArray.push(j);
                    placeDataArray.push(place);
                }
            });
            if (paginationCount <= 1) {
                let place = placeDataArray[0];
                var popupOptions = { offset: [0, -36], closeButton: false };
                const popup = new mapboxgl.Popup(popupOptions);
                popup.setDOMContent(this.addPopup(place, place.location, 0));
                marker.setPopup(popup);
                if (place.hasOwnProperty('show') && place.show) {
                    popup.addTo(this.props.map);
                } else if (place.hasOwnProperty('showSinglePopup') && place.showSinglePopup) {
                    popup.addTo(this.props.map);
                }
                this.popups.push(popup);
            } else {
                var popupOptions = { offset: [0, -36], closeButton: false };
                indexArray.forEach((placeId, coun) => {
                    const paginatedPopup = new mapboxgl.Popup(popupOptions);
                    if (i === placeId) {
                        marker.setPopup(paginatedPopup);
                    }
                    if (coun === indexArray.length - 1) {
                        const pageItem = this.state.searchPlaces[placeId];
                        paginatedPopup.setDOMContent(this.addPopupContent(pageItem, coun, indexArray, placeId));
                    }
                    this.popups.push(paginatedPopup);
                });
            }
        });
        this.setState({
            showSpinner: false,
        })
    }

    addPopup(place, location, index) {
        const placeholder = document.createElement('div');
        const jsx = <div className='marker_popup address-marker'>
            <div>
                <span className="close-popup" >
                    <BsX className="hand-cursor" onClick={this.onPopupClose}>close</BsX>
                </span>
            </div>
            {
                place.result_type === 'region' || place.result_type === 'polygon'
                    ? <div className='popup-text'> <div>{place.location.formatted_address}</div> </div>
                    : <div className='popup-text'>
                        <div><b>{place.name}</b></div>
                        <div>{place.location.house_number} {place.location.street}</div>
                        <div>{place.location && place.location.unit && place.location.unit.trim() ? ('Unit ' + place.location.unit) : ''}
                            {place.location && place.location.unit && place.location.unit.trim() &&
                                place.location.floor && place.location.floor.trim() ? ', ' : ''}
                            {place.location && place.location.floor && place.location.floor.trim() ? ('Floor ' + place.location.floor) : ''}</div>
                        <div>{place.location.city}, {place.location.state_code} {place.location.postal}</div>
                    </div>
            }
        </div>
        var root = createRoot(placeholder);
        root.render(jsx);
        return placeholder
    }

    addPopupContent = (place, index, indexArray) => {
        const placeholder = document.createElement('div');
        const jsx = <div className='marker_popup address-marker'>
            <div className='close-popup'>
                <BsX className="hand-cursor" onClick={this.onPopupClose}>close</BsX>
            </div>
            <div className="report-menu d-flex align-items-center justify-content-between">
                {indexArray.length > 1 ?
                    <div className='title'><span className='active'>{(index + 1)}</span> of  {indexArray.length}</div>
                    : ''}
            </div>
            {
                place.result_type === 'region' || place.result_type === 'polygon'
                    ? <div className='popup-text'> <div>{place.location.formatted_address}</div> </div>
                    : <div className='popup-text'>
                        <div><b>{place.name}</b></div>
                        <div>{place.location.house_number} {place.location.street}</div>
                        <div>{place.location && place.location.unit && place.location.unit.trim() ? ('Unit ' + place.location.unit) : ''}
                            {place.location && place.location.unit && place.location.unit.trim() &&
                                place.location.floor && place.location.floor.trim() ? ', ' : ''}
                            {place.location && place.location.floor && place.location.floor.trim() ? ('Floor ' + place.location.floor) : ''}</div>
                        <div>{place.location.city}, {place.location.state_code} {place.location.postal}</div>
                    </div>
            }

            <Pagination
                activePage={index + 1}
                itemsCountPerPage={1}
                totalItemsCount={indexArray.length}
                onChange={this.handlePageChange.bind(this, place, indexArray)}
                firstPageText={''}
                lastPageText={''}
                linkClassPrev='arrow-left'
                linkClassNext='arrow-right'
            />
        </div>
        var root = createRoot(placeholder);
        root.render(jsx);
        return placeholder
    }

    handlePageChange = (data, index, pageNumber) => {
        let placeId = index[pageNumber - 1];
        const pageItem = this.state.searchPlaces[placeId];
        this.handleAddress(pageItem, placeId);
        document.querySelector('.search-list').scrollTop = (placeId * 53);
    }

    removeMarkers = () => {
        this.markers.forEach(m => m.remove());
        this.markers = [];
    }

    render() {
        const { value, suggestions, searchPlaces, showSpinner, addressCounter } = this.state;
        const inputProps = {
            placeholder: 'Enter an address or Lat, Lon',
            value,
            onChange: this.onChange,
            onKeyUp: this.onKeyUp,
            onBlur: this.onBlur
        };
        return (
            <div>
                {value !== '' && !showSpinner ?
                    <BsX className='poi-close-icon hand-cursor' onClick={this.clearPOISearch}>close</BsX>
                    : ''
                }
                {showSpinner ?
                    <Spinner animation="border" variant="primary" size="sm" />
                    : ''
                }
                <div>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={this.renderSuggestion}
                        onSuggestionSelected={this.onSuggestionSelected}
                        focusInputOnSuggestionClick={false}
                        inputProps={inputProps}
                    />
                </div>

                {
                    searchPlaces.length > 1 ?
                        <div className="list-container">
                            <div className='list-group-heading d-flex justify-content-between'>{searchPlaces.length} results
                                <div>
                                    <FaAngleUp className={addressCounter < 1 ? 'disable-arrw' : 'hand-cursor'} onClick={this.handleUp}>up</FaAngleUp>
                                    <FaAngleDown className={addressCounter > 1 ? 'disable-arrw' : 'hand-cursor'} onClick={this.handleDown}>down</FaAngleDown>
                                </div>
                            </div>
                            <div className={`search-list ${addressCounter > 1 ? 'show-list' : addressCounter === 1 ? 'default-view' : 'hide'}`}>
                                {
                                    searchPlaces.length > 0 ?
                                        <ListGroup variant="flush">
                                            {searchPlaces.map((list, index) => {
                                                return (
                                                    <ListGroup.Item key={index} className={`hand-cursor ${list.show ? 'active' : ''}`} onClick={this.handleAddress.bind(this, list, index)} >
                                                        <div>
                                                            {
                                                                list.name && list.name.length > 0 ?
                                                                    <>
                                                                        <div>{list.name}</div>
                                                                        <div className='poi-text'>{list.location.formatted_address}</div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div>{list.location.house_number} <span>{list.location.street}</span> {list.location.house_number || list.location.street ? ',' : ''}</div>
                                                                        <div><span>{list.location.unit && list.location.unit.trim() ? ('Unit ' + list.location.unit) : ''}</span> {list.location && list.location.floor && list.location.floor.trim() ? (', Floor ' + list.location.floor) : ''}</div>
                                                                        <div>{list.location.city}{list.location.city ? ',' : ''} {list.location.state_code} <span>{list.location.postal}</span></div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </ListGroup.Item>
                                                )
                                            })}
                                        </ListGroup>
                                        : ""
                                }
                            </div>
                        </div>
                        : ''}
            </div>
        )
    }
}

export default SearchAdd;