import React from "react";
import wph1 from '../../assets/call_wph1_active.png';
import wph2 from '../../assets/call_wph2_active.png';
import landline from '../../assets/call_landline_active.png';
import telematics from '../../assets/call_telematics_active.png';
import payphone from '../../assets/call_payphone_active.png';
import other from '../../assets/call_other_active.png';

const icons = {
    'WPH1': wph1,
    'WPH2': wph2,
    'RESD': landline,
    'BUSN': landline,
    'VOIP': other,
    'TELE': telematics,
    'CENT': landline,
    'COIN': payphone,
    'WRLS': other,
    'UNK': other,
    'default': other
};

function CallsIcons(props) {

    return (
        <>
            <img src={icons[props.icon]} className='call-icon hand-cursor' onClick={props.handleCalls} />
        </>
    )
}

export default CallsIcons;