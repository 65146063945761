import React, { useEffect, useState, createRef } from 'react';
import moment from "moment";
import './conversation.scss';
import { STORAGE_KEYS } from '../constants/SessionConstants';
import { PiDeviceMobileCameraLight } from "react-icons/pi";
import { FaHeadset } from "react-icons/fa6";
import icon_bot from "../assets/icon_bot.svg"


function Conversations(props) {

    const messagesEndRef = createRef();

    useEffect(() => {
        scrollToBottom();
    }, [props.messages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth',block: 'nearest'});
    }

    return (
        <>
            <div className={`conversation-panel ${props.isMap && props.isToolbar ? 'dual-panel' : ''} ${!props.isMap && props.isToolbar ? 'single-panel' : ''} ${props.isMap && !props.isToolbar ? 'left-navbar' : ''} ${!props.isMap && !props.isToolbar ? 'message-view' : ''}`}>
                <div className='header-panel'>
                    Chatbox
                </div>
                <div className='conversation-body'>
                    {props.messages.length ?
                        props.messages.map((message, index) => {
                            return (
                                message && <div
                                    key={`${message.conversationId}_${index}`}
                                    className={`message-text ${(message.owner === 'Agent') ? 'user-message' : 'received-message'}`}
                                >
                                    <div className={`d-flex align-items-baseline timestamp ${(message.owner === "Agent") ? 'flex-row-reverse' : ''} `}>
                                        <span className={`${(message.owner === "Customer") ? 'senderName' : 'receiverName'}`}>
                                            {message.author}
                                        </span>
                                        <span className="timestamp">{moment(new Date(message.date_updated)).format('MM-DD-YYYY hh:mm:ss A')}</span>
                                        
                                    </div>
                                    <div className={`d-flex ${(message.owner === "Agent") ? 'flex-row-reverse' : ''} `}>
                                        {message.owner === "Agent" ? <FaHeadset /> : <PiDeviceMobileCameraLight />}
                                        <div className="messageText" ref={index === props.messages.length - 1 && !message.body.translatedMessage ? messagesEndRef : null}> {message && message.body ? message.body.message : ""}</div>

                                    </div>


                                    {message.body.translatedMessage ?
                                        <div className={`d-flex ${(message.owner === "Agent") ? 'flex-row-reverse' : ''} `}>
                                            <img
                                                className={`m-t-5px ${(message.owner === "Agent") ? 'translated-icon-sender' : 'translated-icon-receiver'} `}
                                                src={icon_bot}
                                            />
                                            <div className="messageText m-t-5px" >
                                                <div className={`${(message.owner === "Customer") ? 'translation-header' : 'translation-header-receiver'}`} >
                                                    {`AI-Translation : ${message.body.translatedMessageLang}`}
                                                </div>
                                                <div className={`${(message.owner === "Customer") ? 'translation-body' : 'translation-body-receiver'}`} ref={index === props.messages.length - 1 ? messagesEndRef : null}>
                                                    {message && message.body ? message.body.translatedMessage : ""}
                                                </div>
                                            </div>

                                        </div> : ''
                                    }
                                </div>
                            )
                        })
                        : (<div className='no-data-found'>No Conversations Found</div>)}
                </div>
                {/* <div className='footer-panel'></div> */}
            </div>
        </>
    )
}

export default Conversations;