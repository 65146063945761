export const authConfigs = {
  authType: 'okta', //Accepted values: okta / cognito
}
export const urls = {
  enableSimulationData: false,
  simulated911FeedUrl: 'https://api.dev.smartresponse.net/geo/psap/sim911',
  simulated911FeedApiKey: 'YDeuWJpOT3WQN2jdF93z1UfTVhp0oQfTUwZKpMIL',
  debuggLogs: true,
  discrepancyUrl: 'https://apisls.dev.smartresponse.net',
  smsUrl: 'https://apisls.dev.smartresponse.net',
  statusCheckUrl: 'https://api.dev.smartresponse.net',
  notificationsType: 'aws-iot', //Accepted values: "aws-iot" / "web-socket"
  awsWebSocketUrl: 'wss://ah85ft6zyd.execute-api.us-east-1.amazonaws.com/prod/',
};