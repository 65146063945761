import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getLoginToken } from "../utilities/Utils";
import { ROUTE_ENDPOINTS } from "../constants/RouteConstants";

export const PrivateRoute = ({ children }) => {
  const isLoggedIn = getLoginToken();

  if (!isLoggedIn) {
    return <Navigate to={{ pathname: ROUTE_ENDPOINTS.LOGIN }} />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default PrivateRoute;