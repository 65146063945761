import { STORAGE_KEYS } from "../constants/SessionConstants";
import { authConfig, AUTH_TYPES, callConfigs, featureLabels } from "../constants/Constants";
import { ROUTE_ENDPOINTS } from "../constants/RouteConstants";

export const configAuthType = () => {
  return authConfig && authConfig.authType ? authConfig.authType : AUTH_TYPES.OKTA;
}

export function getLoginToken() {
  return sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) || getOktaIdTokenFromSession();
}

export function getApiKey() {
  return sessionStorage.getItem(STORAGE_KEYS.API_KEY);
}

export function getBaseUrl() {
  return sessionStorage.getItem(STORAGE_KEYS.BASE_URL);
}

export function getUserName() {
  return sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER);
}
export function getShortUserName() {
  return sessionStorage.getItem(STORAGE_KEYS.USER);
}
export function getGroupProfile() {
  return JSON.parse(sessionStorage.getItem(STORAGE_KEYS.GROUP_PROFILE));
}

export const getOktaIdTokenFromSession = () => {
  const currentUser = sessionStorage.getItem("currentUser");
  if (currentUser && currentUser != null) {
    const oktaTokens = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
    return (oktaTokens && oktaTokens.idToken) ? oktaTokens.idToken.idToken : null;
  } else {
    return sessionStorage.getItem('idToken') || null;
  }
}

export const getOktaAccessTokenFromSession = () => {
  const currentUser = sessionStorage.getItem("currentUser");
  if (currentUser && currentUser != null) {
    const oktaTokens = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
    return (oktaTokens && oktaTokens.accessToken) ? oktaTokens.accessToken.accessToken : null;
  } else {
    return sessionStorage.getItem('accessToken') || null;
  }
}

export const getOktaAccessToken = () => {
  return sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) || getOktaAccessTokenFromSession();
}
export const getCurrentUser = () => {
  return sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER);
}

export const setStationIdInLocalStorage = (name, stationId) => {
  /* setting the station Id pref for multiple users in ocal storage */
  var temp = [];
  let stationIdArray = localStorage.getItem(STORAGE_KEYS.STATION_ID_PREF) // Get Previous station Id lists to temp = []
  if (stationIdArray !== null) {
    temp = [...JSON.parse(stationIdArray)]
    // const people = this.state.people.slice();
    const index = temp.findIndex(item => item.userName === name);
    if(index >= 0) {
      temp[index].stationId = stationId;
    } else {
      temp.push({
        userName: name,
        stationId: stationId,
      })
    }
  } else {
    temp.push({
      userName: name,
      stationId: stationId,
    })
  }
  localStorage.setItem(STORAGE_KEYS.STATION_ID_PREF, JSON.stringify(temp))
};

export const getStationIdFromLocalStorage = () => {
  var stationId = null;
  var stationIdList = JSON.parse(localStorage.getItem(STORAGE_KEYS.STATION_ID_PREF));
  var currentUser = sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER) || sessionStorage.getItem(STORAGE_KEYS.USER);
  if(stationIdList && stationIdList != null && currentUser && currentUser != null) {
    stationIdList.map(station => {
      if(station.userName === currentUser) {
        stationId = station.stationId;
        return stationId;
      }
    }) 
    return stationId;
  }
  return stationId;
}

export const setEndCallTimeInLocalStorage = (userName, endCallTime) => {
  var temp = [];
  let endCallTimerArray = localStorage.getItem(STORAGE_KEYS.END_CALL_TIME) // Get Previous endCallTimer lists to temp = []
  if (endCallTimerArray !== null) {
    temp = [...JSON.parse(endCallTimerArray)]
    const index = temp.findIndex(item => item.userName === userName);
    if(index >= 0) {
      temp[index].endCallTimer = endCallTime;
    } else {
      temp.push({
        userName: userName,
        endCallTimer: endCallTime,
      })
    }
  } else {
    temp.push({
      userName: userName,
      endCallTimer: endCallTime,
    })
  }
  localStorage.setItem(STORAGE_KEYS.END_CALL_TIME, JSON.stringify(temp))
};

export const getEndCallTimeFromLocalStorage = () => {
  var endCallTime = null;
  var endCallTimeList = JSON.parse(localStorage.getItem(STORAGE_KEYS.END_CALL_TIME));
  var currentUser = sessionStorage.getItem("user");
  if(endCallTimeList && endCallTimeList != null && currentUser && currentUser != null) {
    endCallTimeList.map(endCall => {
      if(endCall.userName === currentUser) {
        endCallTime = endCall.endCallTimer;
        return endCallTime;
      }
    }) 
    return endCallTime;
  }
  return endCallTime;
}

export function isFeatureAllowed(feature) {
  const groupProfile = getGroupProfile();
  if (!feature || !groupProfile || !groupProfile.features) {
    return false;
  }

  return groupProfile.features.includes(feature);
}

export function isEmpty(str) {
  if (!str || str.trim().length === 0) {
    return false;
  }
  return true;
}

export function setSimulationEnabled() {
  if (callConfigs.enableSimulation) {
    let simulationDisabled = sessionStorage.getItem("isSimulationEnabled");
    if (simulationDisabled === null) {
      sessionStorage.setItem("isSimulationEnabled", true);
      callConfigs.isSimulationEnabled = true;
    }
  } else {
    sessionStorage.setItem("isSimulationEnabled", false);
    callConfigs.isSimulationEnabled = false;
  }
}

export const findDefaultRoute = () => {
  const groupFeatures = getGroupProfile();
  if (groupFeatures && groupFeatures.features.includes(featureLabels.call)) {
    return ROUTE_ENDPOINTS.CALLS;
  } else if (groupFeatures.features.includes(featureLabels.incident)) {
    return ROUTE_ENDPOINTS.ALL_CALLS;
  } else if (groupFeatures.features.includes(featureLabels.analytics)) {
    return ROUTE_ENDPOINTS.ANALYTICS;
  }
  return ROUTE_ENDPOINTS.LOGIN;
}

export function SolacomGroup() {
  const groupFeatures = getGroupProfile();
  var solacomGroup = false;
  if(sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER) !== null && groupFeatures && groupFeatures.call_vendor && groupFeatures.call_vendor.length && (groupFeatures.call_vendor[0]).toLowerCase() == callConfigs.solacomTopic){
      solacomGroup = true;
  }
  else{
      solacomGroup = false;
  }
  return solacomGroup;
}
export function isMotorolaGroup() {
  const groupFeatures = getGroupProfile();
  var motorolaGroup = false;
  if(sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER) !== null && groupFeatures && groupFeatures.call_vendor && groupFeatures.call_vendor.length && (groupFeatures.call_vendor[0]).toLowerCase() == callConfigs.motorolaTopic){
    motorolaGroup = true;
  }
  else{
    motorolaGroup = false;
  }
  return motorolaGroup;
}

export function swapCoordinates(arr) {
  let geometry = [];
  if (arr) {
    if (arr.length > 0) {
      if (arr[0] instanceof Array) {
        arr.forEach(coordinate => {
          if (coordinate && coordinate.length > 1) {
            geometry.push([coordinate[1], coordinate[0]]);
          }
        });
      }
    }
  }
  return geometry;
}

export function isSecondaryMapAvailable() {
  const groupFeatures = getGroupProfile();
  if (groupFeatures && groupFeatures.secondMap && groupFeatures.secondMap.length) {
    return true
  }
  return false;
}