export const STORAGE_KEYS = {
  GROUP_PROFILE: 'groupProfile',
  CURRENT_USER: 'currentUser',
  CURRENT_REGION: 'currentRegion',
  USER: 'user',
  API_KEY: 'api_key',
  ID_TOKEN: 'idToken',
  ACCESS_TOKEN: 'accessToken',
  GROUP_NAME: 'groupName',
  BASE_URL: 'base',
  END_CALL_TIME: 'endCallTime',
  USER_INFO: 'userinfo',
  OKTA_TOKENS: 'oktaTokens',
  SHOW_NOTIFICATIONS : 'showNotifications',
  STATION_ID_PREF: 'stationIdPref',
  isSimulationEnabled: 'isSimulationEnabled',
  USER_APPLICATIONS: 'userApplications',
  TOOLBAR: 'toolbar',
  MAP: 'map'
}
