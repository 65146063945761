export const ROUTE_ENDPOINTS = {
  LOGIN: '/login',
  CALLS: '/calls',
  ALL_CALLS: '/allcalls',
  ANALYTICS: '/analytics',
  ACCOUNT: '/account',
  ABOUT: '/about',
  DOCUMENTATION: '/documentation',
  SETTINGS: '/settings'
};

export const EXCLUDE_MAP = [
  ROUTE_ENDPOINTS.ACCOUNT,
  ROUTE_ENDPOINTS.ABOUT,
  ROUTE_ENDPOINTS.DOCUMENTATION,
  ROUTE_ENDPOINTS.SETTINGS
];