import {
  FETCH_SPLIT_SCREEN,
  FETCH_MAP_THEME_REQUEST, 
  FETCH_MAP_THEME_SUCCESS, 
  FETCH_MAP_THEME_FAIL, 
  FETCH_MAP_THEME_URL, 
  FETCH_MAP_THEME_CANCEL,
  FETCH_RAPID_SOS,
  FETCH_UNCERTAINITY, SET_TOOLBAR, SET_MAP
} from '../actions/actionTypes';
import axios from 'axios';
import { serverUrls, cancelErrorMessages } from '../../constants/Constants';
import { configTimers } from '../../constants/ConfigTimers';
import { getBaseUrl, getApiKey, getUserName, getGroupProfile } from '../../utilities/Utils';

export const fetchSplitScreen = (val) => {
  return {
      type: FETCH_SPLIT_SCREEN,
      data: val
  }    
}

const fetchMapThemeRequest = () => {
  return {
      type: FETCH_MAP_THEME_REQUEST
  }
}

const fetchMapThemeSuccess = (data) => {
  return {
      type: FETCH_MAP_THEME_SUCCESS,
      data: data
  }
}

const fetchMapThemeFail = (error) => {
  return {
      type: FETCH_MAP_THEME_FAIL,
      data: error
  }
}

const fetchMapThemeCancel = (val, retry) => {
  return {
      type: FETCH_MAP_THEME_CANCEL,
      error: val,
      retires: retry ? retry : false
  }
}

export const fetchMapThemeUrl = (url) => {
  return {
      type: FETCH_MAP_THEME_URL,
      data: url
  }
}

// export const fetchRenderMapThemes = (val) => {
//   return{
//       type: FETCH_RENDER_THEME,
//       data: val
//   }
// }

// let mapStyles = [] , callRetriesCount  = 0;
export const getMapThemesData = () => {
  let mapStyles = [] , callRetriesCount  = 0;

  return function (dispatch) {
    var gisStyleUrl = ""
    var defaultStyleUrl = getBaseUrl() + serverUrls.stylesUrl + 'default?api_key=' + getApiKey()
    const groupFeatures = getGroupProfile();
    if (groupFeatures != null && groupFeatures.gis_id != null && groupFeatures.gis_id != "") {
        gisStyleUrl = getBaseUrl() + serverUrls.stylesUrl + 'default?api_key=' + getApiKey()  + '&gis_id=' + groupFeatures.gis_id
    }
    var customStylesUrl = getBaseUrl() + serverUrls.stylesUrl + getUserName() + '?api_key=' + getApiKey()

    const defaultStyleRequest = axios.get(defaultStyleUrl);
    const customStyleRequuest = axios.get(customStylesUrl);
    var finalUrl = []
    if (gisStyleUrl !== "") {
        const gisStyleRequest = axios.get(gisStyleUrl);
        finalUrl = [customStyleRequuest, gisStyleRequest, defaultStyleRequest]
    } else {
        finalUrl = [customStyleRequuest, defaultStyleRequest]
    }

    axios.all(finalUrl, { timeout: configTimers.mapDataTimeout })
    .then(axios.spread((...responses) => {
      if (responses && responses.length) {
        responses.map(styleData => {
          if (styleData && styleData.data && styleData.data.Resources) {
            mapStyles.push(...styleData.data.Resources)
          }
        })
      }
      let styleObjs = [];
      if (mapStyles.length) {
        mapStyles.forEach((style, ind) => {
          style['label'] = style.Name;
          style['value'] = style.Name
          styleObjs.push(style);
        });
        dispatch(fetchMapThemeCancel(null, false));
        dispatch(fetchMapThemeUrl(styleObjs[0].Url));
        dispatch(fetchMapThemeSuccess(styleObjs));
        // dispatch(fetchRenderMapThemes(1));
        // dispatch(fetchRenderWeather(1));
        // dispatch(fetchRenderUncertainity(0));
      }
    }))
    .catch(error => {
      if (error.code === configTimers.cancelStatusCode) {
        callRetriesCount = callRetriesCount + 1;
        if (callRetriesCount > 2) {
            dispatch(fetchMapThemeCancel(cancelErrorMessages.mapUserMsg, false));
            // dispatch(fetchRenderMapThemes(1));
        }
        else {
            dispatch(fetchMapThemeCancel(null, true));
            // dispatch(fetchRenderMapThemes(1));
        }
      }
      else if (error.response) {
        dispatch(fetchMapThemeCancel(error.response.statusText));
        // dispatch(fetchRenderMapThemes(1));
      } else {
        dispatch(fetchMapThemeFail(error.message));
      }
    })

  }
}

export const fetchRapidSOS = (val) => {
  return{
      type: FETCH_RAPID_SOS,
      data: val
  }
}

export const fetchUncertainity = (val) => {
  return{
      type: FETCH_UNCERTAINITY,
      data: val
  }
}

export const setToolbarVisibility = (val) => {
  return{
      type: SET_TOOLBAR,
      data: val
  }
}

export const setMapVisibility = (val) => {
  return{
      type: SET_MAP,
      data: val
  }
}