import { OktaAuth } from '@okta/okta-auth-js';
import { oktaUrls } from "../../../oktaexport-client";
import { configTimers } from "../../../constants/ConfigTimers";
import { STORAGE_KEYS } from "../../../constants/SessionConstants";
import { AUTH_TYPES, errorMessages } from '../../../constants/Constants';
import logo from '../../../assets/comtech_corp_logo.svg';

export const oktaAuthClientObj = (authType) => {
  return (authType && authType === AUTH_TYPES.OKTA) ? (new OktaAuth(oktaAuthConfig())) : {};
}

export const oktaAuthConfig = () => {
  return {
    clientId: oktaUrls.CLIENT_ID,
    issuer: oktaUrls.OKTA_BASE_URL + oktaUrls.ISSUER,
    redirectUri: `${window.location.origin}/login-callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    useClassicEngine: true,
    useInteractionCode: false,
    tokenManager: {
      storage: 'sessionStorage',
      storageKey: 'oktaTokens',
    },
    autoRenew: false,
  }
}

export const oktaSignInWidgetConfig = () => {
  return {
    clientId: oktaUrls.CLIENT_ID,
    redirectUri: `${window.location.origin}/login-callback`,
    issuer: oktaUrls.OKTA_BASE_URL + oktaUrls.ISSUER,
    authParams: {
      issuer: oktaUrls.OKTA_BASE_URL + oktaUrls.ISSUER,
      scopes: ['openid', 'profile', 'email'],
      pkce: true,
    },
    useClassicEngine: true,
    useInteractionCodeFlow: false,
    logo,
    i18n: {
      en: {
        'primaryauth.title': 'Sign in',
      },
    },
  }
}

export const oktaRoleConfigData = () => {
  return {
    roleARN: oktaUrls.ROLE_ARN,
    roleName: oktaUrls.ROLE_NAME,
    sessionDuration: configTimers.notificationSessionDuration, // duration in seconds (12 hours)
  }
}

/* Refreshing Okta session */
let sessionExpiryCount = 0;
let sessionExpiryTimeoutId = null;
export const refreshOktaSession = (oktaAuth) => {
  return new Promise((resolve, reject) => {
    oktaAuth.session.refresh().then((session) => {
      sessionExpiryCount = 0;
      let currentDateTime = new Date().getTime();
      let sessionExpireTime = (new Date(session.expiresAt)).getTime();
      /* Refresh session - 60 sec. before to actual session expiry time */ 
      let nextTimeout = (sessionExpireTime - currentDateTime) - 60000;
      resolve(nextTimeout);
    }).catch((exception) => {
      const exceptionMsg = (exception.message) ? exception.message : exception;
      console.log('setSessionExpiry Exception::::::: ' + exceptionMsg);
      if (sessionExpiryTimeoutId) clearTimeout(sessionExpiryTimeoutId);
      sessionExpiryTimeoutId = setTimeout(() => {
        sessionExpiryCount += 1;
        refreshOktaSession()
        .then(nextTimeout => {
          resolve(nextTimeout);
        })
        .catch(reject);
        if (sessionExpiryCount > 3) {
          console.log('--- logout ---');
          sessionExpiryCount = 0;
        }
      }, configTimers.sessionTokenRetry);
    })
  });
}

// tokenType: idToken / accessToken
export const getOktaSessionStatus = (oktaAuth) => {
  return new Promise((resolve, reject) => {
    oktaAuth.session.get()
    .then((session) => {
      resolve(session);
    })
    .catch((err) => {
      console.log(`::::::::: getOktaSessionStatus Exception :::::::::::::: `, err);
      reject(err);
    });
  });
}

export const refreshOktaToken = (oktaAuth, tokenType) => {
  return new Promise((resolve, reject) => {
    getOktaSessionStatus(oktaAuth)
    .then((session) => {
      console.log('session status:', session.status);
      if (session && session.status && session.status === 'ACTIVE') {
        const oktaTokens = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
        const tokenToRenew = oktaTokens[tokenType];
        oktaAuth.token.renew(tokenToRenew)
        .then((renewedToken) => {
          resolve(renewedToken);
        })
        .catch((err) => {
          reject(err);
        })
      } else {
        handleOktaExpiredSession();
        reject('No active session exist');
      }
    })
    .catch((err) => {
      console.log(`::::::::: getOktaSessionStatus Exception :::::::::::::: `, err);
    });
  });
}

export const oktaSignOut = (oktaAuth) => {
  oktaAuth.signOut().then(() => {
    sessionStorage.clear();
    window.location.href = "/";
  })
  .catch(e => {
    console.log(e);
  });
}

export const handleOktaExpiredSession = (type) => {
  console.log(`::::::::: No active session exist ::::::::::::::`);
  console.log('::::::::: logout :::::::::');
  sessionStorage.clear();
  const errMsg = (type === 'tokens') ? errorMessages.tokensExpiredMsg : errorMessages.sessionExpiredMsg;
  localStorage.setItem('showSignInErrMsg', true);
  localStorage.setItem('signInErrMsg', errMsg);
  window.location.href = "/";
}