import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import CallsComponent from 'sa-common/calls/CallsComponent';
// import '../styles/calls.scss'
import '../styles/markerPopup.scss'
import { getSimulationCallsData, getNotificationCallsData } from '../redux/actions/callsActions';
import { setSimulationEnabled, getStationIdFromLocalStorage, isFeatureAllowed, findDefaultRoute, isSecondaryMapAvailable } from '../utilities/Utils';
import { MapContext } from '../context/MapContext';
import { callConfigs, markerImages, featureLabels, groupLocations } from '../constants/Constants';
import turfBuffer from '@turf/buffer';
import turfBbox from '@turf/bbox';
import turfNearestPointOnLine from "@turf/nearest-point-on-line";
import { createGeoJSONCircle } from 'sa-common/utils/CommonUtils';

var turfPoint = require("turf-point");
var markers = [];
var rapidSOSMarkers = [];
var markersOnSM = [];
var rapidSOSMarkersOnSM = [];
var cylinderLayers = [];
var rapidSOSDottedLineLayers = [];
var unCertaintyLayers = [];
let dottedLineId = 'dotCircles';
let unCertaintyCircleId = 'liveCircle';
let unCertaintyCircleBorder = 'liveBorder';
var breadcrumbLayers = [];
var rapidSOSBreadcrumbLayers = [];
var endCallMarkers = [];
var satEndCallMarkers = [];


var rapidSOSCircleLayers = [], renderContour = false;

function CallsRoute(props) {
  const prevMapUrlRef = useRef(null);
  const navigate = useNavigate();
  const { mapContainer, mapRef, mapLoadStatus, secondMapContainer, locateMeClick } = useContext(MapContext);
  const [contourLineProperties, setContourLineProperties] = useState(0);
  const [selectedEndCallId, setSelectedEndCallId] = useState(null);
  

  useEffect(() => {
    setSimulationEnabled();
    if (callConfigs.isSimulationEnabled) {
      props.getSimulationCallsData();
    } else {
      props.getNotificationCallsData();
    }
  }, [])

  useEffect(() => {
    if (mapContainer && mapRef.current) {
      if (props.callsInfo) {
        drawCallMarker()
      } else {
        mapContainer.jumpTo(groupLocations.default.center, groupLocations.default.zoom, mapContainer)
        if (isSecondaryMapAvailable()) {
          mapContainer.jumpTo(groupLocations.default.center, groupLocations.default.zoom, secondMapContainer)
        }
      }
    }
  }, [mapContainer, mapRef]);

  useEffect(() => {
    if (props.mapUrl && mapLoadStatus) {
      if (!prevMapUrlRef.current) {
        prevMapUrlRef.current = props.mapUrl; // Update reference
      } else if (props.mapUrl !== prevMapUrlRef.current) {
        prevMapUrlRef.current = props.mapUrl; // Update reference
        drawCallMarker()
      }
    }
  }, [mapLoadStatus])

  useEffect(() => {
    if (locateMeClick)
      activeMarkerClickEvent();
  }, [locateMeClick]);

  useEffect(() => {
    if (props.callsInfo && mapContainer && mapRef.current) {
      drawCallMarker()
    }
  }, [props.callsInfo])

  useEffect(() => {
    if (props.endCallsData && props.endCallsData.length > 0 && mapContainer && mapRef.current) {
      drawEndCallMarkers()
    } else {
      removeEndCallMarkers()
    }
  }, [props.endCallsData])

  useEffect(() => {
    if (mapContainer && mapRef.current) {
      mapContainer.enableSplitScreen(props.isSplitScreen)
    }
  }, [props.isSplitScreen])

  useEffect(() => {
    if (mapContainer && mapRef.current && !isRapidSOSDataAvailable()) {
      hideRapidSOSLayers()
    } else if (rapidSOSMarkers && rapidSOSMarkers.length) {
      showRapidSOSLayers()
    }
  }, [props.isRapiSOSEnable])

  useEffect(() => {
    if (mapContainer && mapRef.current && props.uncertainity) {
      showUncertainityLayers()
    } else if ((rapidSOSCircleLayers && rapidSOSCircleLayers.length) || (unCertaintyLayers && unCertaintyLayers.length)) {
      hideUncertainityLayers()
    }
  }, [props.uncertainity])

  const drawCallMarker = () => {
    if (props.callsInfo && mapContainer && mapRef.current) {
      removeAllLayers()
      drawMarker(props.callsInfo.classOfService, 0, callConfigs.defaultZoom, mapContainer)
      if (isSecondaryMapAvailable())
        drawMarker(props.callsInfo.classOfService, callConfigs.satelliteMapPitch, callConfigs.satelliteMapZoom, secondMapContainer)
    }
  }

  const drawMarker = (classOfService, pitch, zoomLevel, container) => {
    if (mapContainer && container && props.callsInfo.geometry && props.callsInfo.geometry.coordinates) {
      var coorLength = props.callsInfo.geometry.coordinates.length;
      if (coorLength && props.callsInfo.geometry.coordinates[coorLength - 1].length) {
        let el = document.createElement('div');
        el.addEventListener('click', activeMarkerClickEvent.bind(this, container, pitch, zoomLevel))
        el.className = 'incident-marker overlay-index';
        if (markerImages.activeMarkers[classOfService]) {
          el.style.backgroundImage = `url(${markerImages.activeMarkers[classOfService]})`;
        }
        else {
          el.style.backgroundImage = `url(${markerImages.activeMarkers['default']})`;
        }
        let options = { element: el, anchor: 'bottom' };

        const lngLat = mapContainer.createLngLatObjFrom(props.callsInfo.geometry.coordinates[props.callsInfo.geometry.coordinates.length - 1][0], props.callsInfo.geometry.coordinates[props.callsInfo.geometry.coordinates.length - 1][1])
        const marker = mapContainer.drawMarker(options, lngLat, container)
        if (props.uncertainity && props.callsInfo.radius) {
          let layers = [];
          layers.push(unCertaintyCircleId, unCertaintyCircleBorder);
          unCertaintyLayers = layers;
          mapContainer.addCircle(container, [Number(props.callsInfo.geometry.coordinates[props.callsInfo.geometry.coordinates.length - 1][0]),
          Number(props.callsInfo.geometry.coordinates[props.callsInfo.geometry.coordinates.length - 1][1])], (props.callsInfo.radius / 1000), unCertaintyCircleId, unCertaintyCircleBorder);
        }
        addBreadCrumb(props.callsInfo.geometry.coordinates, '#888', container)
        createRapidSOSMarker(container)
        addDotedLine(container)
        if (container === mapContainer) {
          markers.push(marker);
        } else if (container === secondMapContainer) {
          markersOnSM.push(marker);
        }
        mapContainer.jumpTo(lngLat, zoomLevel, container)
      }
    }
  }

  const addBreadCrumb = (coordinates, circleColor, container) => {
    if (coordinates.length > 1) {
      let layers = breadcrumbLayers;
      let circleId = 'initCircle';
      let lineId = 'circles';
      let polylineId = 'polyline';
      layers.push(lineId, circleId, polylineId);
      breadcrumbLayers = layers;
      mapContainer.addBreadCrumb(container, coordinates, circleColor, circleId, lineId, polylineId)
    }
  }

  const addRapidSOSBreadCrumb = (coordinates, circleColor, container) => {
    let layers = rapidSOSBreadcrumbLayers;
    let circleId = 'sosinitCircle';
    let lineId = 'soscircles';
    let polylineId = 'sospolyline';
    layers.push(lineId, circleId, polylineId);
    rapidSOSBreadcrumbLayers = layers;
    mapContainer.addBreadCrumb(container, coordinates, circleColor, circleId, lineId, polylineId)
  }

  const createRapidSOSMarker = (container) => {
    try {
      if (isRapidSOSDataAvailable()) {
        let sosElement = document.createElement('div');
        sosElement.className = 'incident-marker incident-marker-sos call-icon-overlay';
        sosElement.style.backgroundImage = `url(${markerImages.activeMarkers['RAPIDSOS']})`;
        let sosOptions = { element: sosElement, anchor: 'bottom' };
        let rapidSOSlngLat = mapContainer.createLngLatObjFrom(props.callsInfo.rapidSOS.geometry.coordinates[props.callsInfo.rapidSOS.geometry.coordinates.length - 1][0], props.callsInfo.rapidSOS.geometry.coordinates[props.callsInfo.rapidSOS.geometry.coordinates.length - 1][1]);
        let rapidSOSMarker = mapContainer.drawMarker(sosOptions, rapidSOSlngLat, container)
        addRapidSOSBreadCrumb(props.callsInfo.rapidSOS.geometry.coordinates, '#ea0e0e', container);
        renderContour = false;
        if (container === mapContainer) {
          rapidSOSMarkers.push(rapidSOSMarker);
          mapRef.current.once('idle', () => {
            if (!renderContour) {
              drawCylinder();
            }
            mapRef.current.on('zoom', () => {
              if (!renderContour) {
                drawCylinder();
              }
            });
          })
        } else if (container === secondMapContainer) {
          rapidSOSMarkersOnSM.push(rapidSOSMarker);
          addRapidSOSCircle(secondMapContainer, props.callsInfo.rapidSOS.geometry, props.callsInfo.rapidSOS.radius / 1000);
        }
      }
    } catch (error) {
      console.log("::::::::: error createRapidSOSMarker ::::::::::: ")
    }
  }

  const isRapidSOSDataAvailable = () => {
    if (props.isRapiSOSEnable && props.callsInfo && props.callsInfo.hasOwnProperty('rapidSOS') && props.callsInfo.rapidSOS && props.callsInfo.rapidSOS.geometry && props.callsInfo.rapidSOS.geometry.coordinates && props.callsInfo.rapidSOS.geometry.coordinates.length) {
      return true;
    } else {
      return false;
    }
  }

  const activeMarkerClickEvent = (map, pitch, zoomLevel) => {
    if (mapContainer && props.callsInfo && props.callsInfo.geometry && props.callsInfo.geometry.coordinates) {
      const lngLat = mapContainer.createLngLatObjFrom(props.callsInfo.geometry.coordinates[props.callsInfo.geometry.coordinates.length - 1][0], props.callsInfo.geometry.coordinates[props.callsInfo.geometry.coordinates.length - 1][1])
      mapContainer.jumpTo(lngLat, callConfigs.defaultZoom, mapContainer)
      if (isSecondaryMapAvailable())
        mapContainer.jumpTo(lngLat, callConfigs.satelliteMapZoom, secondMapContainer)
    } else {
      mapContainer.jumpTo(groupLocations.default.center, callConfigs.defaultZoom, mapContainer)
      if (isSecondaryMapAvailable())
        mapContainer.jumpTo(groupLocations.default.center, callConfigs.satelliteMapZoom, secondMapContainer)
    }
  }

  const addDotedLine = (map) => {
    try {
      if (map && isRapidSOSDataAvailable() && props.callsInfo.geometry && props.callsInfo.geometry.coordinates && props.callsInfo.geometry.coordinates.length) {
        let layers = rapidSOSDottedLineLayers;
        layers.push(dottedLineId);
        rapidSOSDottedLineLayers = layers;
        mapContainer.drawDotedLine(dottedLineId, [props.callsInfo.rapidSOS.geometry.coordinates[props.callsInfo.rapidSOS.geometry.coordinates.length - 1], props.callsInfo.geometry.coordinates[props.callsInfo.geometry.coordinates.length - 1]], "#3d81c3")
      }
    } catch (error) {
      console.log("::::::::: error addDotedLine ::::::::::: ")
    }
  }

  const hideRapidSOSLayers = () => {
    rapidSOSMarkers.forEach(marker => {
      marker.remove();
    });
    rapidSOSMarkersOnSM.forEach(marker => {
      marker.remove();
    });
    rapidSOSDottedLineLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, false));
    rapidSOSBreadcrumbLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, false));
    if (isSecondaryMapAvailable()) {
      rapidSOSDottedLineLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, false));
      rapidSOSBreadcrumbLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, false));
    }
    cylinderLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, false));
    hideRapidSOSUncertainityLayers(mapContainer);
    if (isSecondaryMapAvailable())
      hideRapidSOSUncertainityLayers(secondMapContainer);
  }

  const showRapidSOSLayers = () => {
    rapidSOSMarkers.forEach(marker => {
      mapContainer.addMarkerOnMap(marker);
    });
    rapidSOSMarkersOnSM.forEach(marker => {
      mapContainer.addMarkerOnSecondMap(marker);
    });
    rapidSOSDottedLineLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, true));
    rapidSOSBreadcrumbLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, true));
    if (isSecondaryMapAvailable()) {
      rapidSOSDottedLineLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, true));
      rapidSOSBreadcrumbLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, true));
    }
    cylinderLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, true));
    showUncertainityLayers();
  }

  const showUncertainityLayers = () => {
    if (mapContainer && props.uncertainity) {
      rapidSOSCircleLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, true));
      unCertaintyLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, true));
      if (isSecondaryMapAvailable()) {
        rapidSOSCircleLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, true));
        unCertaintyLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, true));
      }
    }
  }

  const hideUncertainityLayers = () => {
    rapidSOSCircleLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, false));
    unCertaintyLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, false));
    if (isSecondaryMapAvailable()) {
      rapidSOSCircleLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, false));
      unCertaintyLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, false));
    }
  }

  const hideRapidSOSUncertainityLayers = (container) => {
    for (var i = 0; i < rapidSOSCircleLayers.length; i++) {
      mapContainer.handleLayerVisibility(container, rapidSOSCircleLayers[i], false);
    }
  }

  const removeAllLayers = () => {
    // Remove all markers from both maps
    markers = removeAll(markers, marker => marker.remove());
    markersOnSM = removeAll(markersOnSM, marker => marker.remove());
    rapidSOSMarkers = removeAll(rapidSOSMarkers, marker => marker.remove());
    rapidSOSMarkersOnSM = removeAll(rapidSOSMarkersOnSM, marker => marker.remove());

    // Remove all layers
    rapidSOSDottedLineLayers = removeAll(rapidSOSDottedLineLayers, layer => mapContainer.removeLayer(layer));
    rapidSOSCircleLayers = removeAll(rapidSOSCircleLayers, layer => mapContainer.removeLayer(layer));
    unCertaintyLayers = removeAll(unCertaintyLayers, layer => mapContainer.removeLayer(layer));
    cylinderLayers = removeAll(cylinderLayers, layer => mapContainer.removeLayer(layer));
  }

  // Function to remove all items from a collection and then reset the collection
  const removeAll = (collection, removeMethod) => {
    collection.forEach(item => removeMethod(item));
    return [];
  };

  const handleCallsData = () => {
    if (callConfigs.isSimulationEnabled) {
      props.getSimulationCallsData();
    }
  }

  const addRapidSOSCircle = (geometry, radius) => {
    try {
      if (mapContainer && geometry.coordinates && geometry.coordinates.length) {
        var allLayers = mapContainer.getMapStyles();
        var cameraLayersId;
        // for (var i = 0; i < allLayers.length; i++) {
        //   if (allLayers[i].id === "geofence-camera" || allLayers[i].id === "camera" ||
        //     allLayers[i].id === "selected_camera" || allLayers[i].id === "dotCircles" || allLayers[i].id === "sosinitCircle" || allLayers[i].id === "soscircles"
        //     || allLayers[i].id === "sospolyline") {
        //     cameraLayersId = allLayers[i].id;
        //     break;
        //   }
        // }
        let layers = [];
        let circleId = 'sosliveCircle';
        let circleBorder = 'sosliveBorder';
        layers.push(circleId, circleBorder);
        rapidSOSCircleLayers = layers;
        mapContainer.addCircle([Number(geometry.coordinates[geometry.coordinates.length - 1][0]), Number(geometry.coordinates[geometry.coordinates.length - 1][1])], radius, circleId, circleBorder);

      }
    } catch (error) {
      console.log("::::::::: error addRapidSOSCircle ::::::::::: ")
    }
  }

  const prepareProperties = (baseHeight, height, color) => {
    return {
      name: "3D Cylynder",
      height: height,
      base_height: baseHeight,
      color: color,
    };
  }

  const getNearestContourLine = (coords) => {
    var point = turfPoint(coords);
    var buffered = turfBuffer(point, callConfigs.contourLineDistance, {
      units: "miles",
    });
    var coordinate1, coordinate2, closest, lineCoords = [];
    if (buffered) {
      var bbox = turfBbox(buffered);
      coordinate1 = mapContainer.mapProject(bbox[0], bbox[1]);
      coordinate2 = mapContainer.mapProject(bbox[2], bbox[3]);
      var features = mapContainer.getFeatures([coordinate1, coordinate2]);
      if (features) {
        features.forEach((feature, index) => {
          if (
            feature &&
            feature.sourceLayer &&
            feature.sourceLayer.toLowerCase() === callConfigs.contourLayer
          ) {
            var snapped = turfNearestPointOnLine(feature, point, {
              units: "miles",
            });
            if (!closest) {
              closest = {};
              closest.calcData = snapped;
              closest.feature = feature;
            } else if (
              closest.calcData.properties.dist > snapped.properties.dist
            ) {
              closest.calcData = snapped;
              closest.feature = feature;
            }
          }
        });
      }
    }
    return closest;
  }

  const drawCylinder = () => {
    var rapidSOS = props.callsInfo.rapidSOS;
    try {
      let uncertyCylinder = callConfigs.uncertyCylinder;
      let zCylinder = callConfigs.zCylinder;
      if (mapContainer && rapidSOS && rapidSOS.geometry) {
        var allLayers = mapContainer.getMapStyles();
        var geometry = rapidSOS.geometry;
        var contourLayerExist = false;
        for (var i = 0; i < allLayers.length; i++) {
          if (allLayers[i].id.toLowerCase() == callConfigs.contourLayer) {
            contourLayerExist = true;
            break;
          }
        }
        if (contourLayerExist) {
          if (rapidSOS.altitude === null || rapidSOS.altitude === undefined || rapidSOS.altitude === 0) {
            if (isRapidSOSDataAvailable() && props.uncertainity) {
              addRapidSOSCircle(geometry, rapidSOS.radius / 1000);
            }
          } else {
            cylinderLayers.push(uncertyCylinder, zCylinder);
            mapContainer.uncertainityCylinder(uncertyCylinder);
            mapContainer.zAxisCylinder(zCylinder);
            let uncertyData = {
              features: [],
              type: "FeatureCollection",
            };

            let zData = {
              features: [],
              type: "FeatureCollection",
            };

            let coordinates =
              geometry.coordinates[geometry.coordinates.length - 1];
            let closestContourLine = getNearestContourLine(coordinates);

            let circleCoordinates = createGeoJSONCircle(
              coordinates,
              rapidSOS.radius / 1000
            );
            let feature = circleCoordinates.data.features[0];
            let uncertyFeature = JSON.parse(JSON.stringify(feature));
            let zFeature = JSON.parse(JSON.stringify(feature));
            if (closestContourLine && rapidSOS.altitude) {
              hideRapidSOSUncertainityLayers(mapContainer);
              if (isFeatureAllowed(featureLabels.z_axis)) {
                console.log("closestContourLine");
                setContourLineProperties(closestContourLine);
                uncertyFeature.properties = prepareProperties(
                  parseFloat(rapidSOS.altitude * callConfigs.meterToFeet) -
                  parseFloat(closestContourLine.feature.properties.topo_elev) -
                  parseFloat(closestContourLine.feature.properties.H ? closestContourLine.feature.properties.H : 0),
                  parseFloat(rapidSOS.altitude * callConfigs.meterToFeet) -
                  parseFloat(closestContourLine.feature.properties.topo_elev) -
                  parseFloat(closestContourLine.feature.properties.H ? closestContourLine.feature.properties.H : 0) +
                  parseFloat((rapidSOS.zuncer ? rapidSOS.zuncer : 0) * callConfigs.meterToFeet),
                  "orange"
                );
                zFeature.properties = prepareProperties(
                  parseFloat(rapidSOS.altitude * callConfigs.meterToFeet) -
                  parseFloat(closestContourLine.feature.properties.topo_elev) -
                  parseFloat(closestContourLine.feature.properties.H ? closestContourLine.feature.properties.H : 0) -
                  parseFloat((rapidSOS.zuncer ? rapidSOS.zuncer : 0) * callConfigs.meterToFeet),
                  parseFloat(rapidSOS.altitude * callConfigs.meterToFeet) -
                  parseFloat(closestContourLine.feature.properties.topo_elev) -
                  parseFloat(closestContourLine.feature.properties.H ? closestContourLine.feature.properties.H : 0),
                  "grey"
                );

                uncertyData.features.push(uncertyFeature);
                zData.features.push(zFeature);
                mapContainer.getSetData(uncertyCylinder, uncertyData);
                mapContainer.getSetData(zCylinder, zData);
              }
              else {
                setContourLineProperties(0);
                if (isRapidSOSDataAvailable() && props.uncertainity) {
                  addRapidSOSCircle(geometry, rapidSOS.radius / 1000);
                }
              }
              renderContour = true;
            } else {
              renderContour = false;
              setContourLineProperties(0);
              if (isRapidSOSDataAvailable() && props.uncertainity) {
                addRapidSOSCircle(geometry, rapidSOS.radius / 1000);
              }
            }
          }
        } else {
          setContourLineProperties(0);
          if (isRapidSOSDataAvailable() && props.uncertainity) {
            addRapidSOSCircle(geometry, rapidSOS.radius / 1000);
          }
        }
      }

    } catch (error) {
      console.log("::::::::: error drawcylinder ::::::::::: ")
    }
  }

  if (!isFeatureAllowed(featureLabels.call)) navigate(findDefaultRoute());

  const handleEndCallClick = (callData) => {
    // removeEndCallMarkers();
    let selectedNenaID = (selectedEndCallId !== callData.nenaIncidentId) ? callData.nenaIncidentId : ''
    setSelectedEndCallId(selectedNenaID);
    drawEndCallMarkers(selectedNenaID)
  };

  const drawEndCallMarkers = (id) => {
    if (props.endCallsData && props.endCallsData.length > 0 && mapContainer && mapRef.current) {
      removeEndCallMarkers()
      createEndCallsMarkers(0, callConfigs.defaultZoom, mapContainer, id)
      if (isSecondaryMapAvailable())
        createEndCallsMarkers(callConfigs.satelliteMapPitch, callConfigs.satelliteMapZoom, secondMapContainer, id)
    }
  }

  const createEndCallsMarkers = (pitch, zoomLevel, container, id) => {
    props.endCallsData.forEach((place, ind) => {
      if (place && place.geometry && place.geometry.coordinates && place.geometry.coordinates.length) {
        if (mapContainer && mapRef.current) {
          let el = document.createElement('div');
          el.addEventListener('click', handleEndCallClick.bind(this, place))
          el.className = 'incident-marker';
          if (id === place.nenaIncidentId) {
            el.className = 'incident-marker active-icon-overlay';
            if (markerImages.selectMarkers[place.classOfService]) {
              el.style.backgroundImage = `url(${markerImages.selectMarkers[place.classOfService]})`;
            }
            else {
              el.style.backgroundImage = `url(${markerImages.selectMarkers['default']})`;
            }
            let lngLat = mapContainer.createLngLatObjFrom(place.geometry.coordinates[place.geometry.coordinates.length - 1][0], place.geometry.coordinates[place.geometry.coordinates.length - 1][1])
            mapContainer.jumpTo(lngLat, zoomLevel, container)
          }else {
            if (markerImages.activeMarkers[place.classOfService]) {
              if (place.callState === callConfigs.ended) {
                el.style.backgroundImage = `url(${markerImages.endedMarkers[place.classOfService]})`;
              }
            } else {
              if (place.callState === callConfigs.ended) {
                el.style.backgroundImage = `url(${markerImages.endedMarkers['default']})`;
              }
            }
            if (id === '' && props.callsInfo && (props.callsInfo.callState === callConfigs.active || props.callsInfo.callState === callConfigs.release)) {
              let lngLat = props.callsInfo.geometry.coordinates[props.callsInfo.geometry.coordinates.length - 1]
              mapContainer.jumpTo(lngLat, zoomLevel, container)
            }
          }
          let options = { element: el, anchor: 'bottom' };
          const lngLat = mapContainer.createLngLatObjFrom(place.geometry.coordinates[place.geometry.coordinates.length - 1][0], place.geometry.coordinates[place.geometry.coordinates.length - 1][1])
          const marker = mapContainer.drawMarker(options, lngLat, container)
          if (container === mapContainer) {
            endCallMarkers.push(marker);
          } else if (container === secondMapContainer) {
            satEndCallMarkers.push(marker);
          }
        }
      }
    });
  }
  

  const removeEndCallMarkers = () => {
    endCallMarkers = removeAll(endCallMarkers, marker => marker.remove());
    satEndCallMarkers = removeAll(satEndCallMarkers, marker => marker.remove());
  }

  return (
    <>
      {(mapContainer && mapRef.current) ?
        <>
          <CallsComponent stationId={getStationIdFromLocalStorage()} callData={props.callsInfo} showRapidSOS={props.isRapiSOSEnable} handleCallClick={handleCallsData}
            identity={props.personIdentityInfo} criminalData={props.criminalInfo} contourLineProperties={contourLineProperties} endCallsData={props.endCallsData} 
            handleEndCallClick={handleEndCallClick} selectedEndCallId={selectedEndCallId}/>
        </>
        : ''}
    </>
  );
}

const mapStateToProps = (state) => {
  // console.log(state.callsInfo);
  return {
    mapUrl: state.mapInfo ? state.mapInfo.mapUrl : null,
    callsInfo: state.callsInfo ? state.callsInfo.data : null,
    endCallsData: state.callsInfo ? state.callsInfo.endCallsData : [],
    isRapiSOSEnable: state.mapInfo ? state.mapInfo.isRapiSOSEnable : false,
    isSplitScreen: state.mapInfo ? state.mapInfo.isSplitScreen : false,
    personIdentityInfo: state.identity ? state.identity.identity : state.identity,
    criminalInfo: state.criminalData ? state.criminalData.criminalData : state.criminalData,
    uncertainity: state.mapInfo ? state.mapInfo.isUncertainity : true

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSimulationCallsData: () => dispatch(getSimulationCallsData()),
    getNotificationCallsData: () => dispatch(getNotificationCallsData())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallsRoute);