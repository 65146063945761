import React, { useContext, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { BsBellFill, BsFillInfoCircleFill, BsGrid3X3GapFill, BsFillPeopleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import Dropdown from "react-bootstrap/Dropdown";
import { MapContext } from '../context/MapContext';
import { STORAGE_KEYS } from "../constants/SessionConstants";
import { ROUTE_ENDPOINTS, EXCLUDE_MAP } from "../constants/RouteConstants";
import { AuthContext } from "../context/AuthContext";
import GroupName from './views/header/GroupName.jsx'
import MapStyles from './views/header/MapStyles'
import MapLayers from './views/header/MapLayers'
import UserIcon from './views/header/UserIcon'
import OtherApps from './views/header/OtherApps'
import { mapStyle, featureLabels, AUTH_TYPES, appName } from '../constants/Constants';
import { fetchMapThemeUrl, fetchRapidSOS, fetchSplitScreen, fetchUncertainity } from '../redux/actions/headerActions';
import { getApiKey, getUserName, configAuthType, isFeatureAllowed, getLoginToken, isSecondaryMapAvailable } from '../utilities/Utils';
import { signOut } from "./login/LoginUtils";

import srLogo from "../assets/sr_logo.png";
import "../styles/header.scss";
import icRapidOff from '../assets/icon_rapid_off.png';
import icRapidOn from '../assets/icon_rapid_on.png';
import splitScreenOff from '../assets/icon_split_off.png';
import splitScreenOn from '../assets/icon_split_on.png';
import icUncOff from '../assets/icon_unc_off.png';
import icUncOn from '../assets/icon_unc_on.png';


function Header(props) {
  const { mapContainer, mapRef } = useContext(MapContext);
  const { authClient } = useContext(AuthContext);
  const isLoggedIn = getLoginToken();
  const location = useLocation();
  const activepath = location.pathname;

  const [showMapLayers, toggleMapLayer] = useState();
  const [showTraffic, toggleTrafficLayer] = useState(false);
  const [showWeather, toggleWeatherLayer] = useState(false);
  const [showMapTheme, toggleMapTheme] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [initialMap, setInitialMap] = useState(true);
  const [showRapidSOS, setRapidSOS] = useState(true);
  const [showSplitScreen, setSplitScreen] = useState(true);
  const [uncertainity, setUncertainity] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (mapContainer && mapRef.current) {
      // Map is ready, you can use mapContainer and mapRef safely here
      console.log("::: Header Map is ready :::: ")
      // setIsMapReady(true);
    }
  }, [mapContainer, mapRef]);

  useEffect(() => {
    if (props.mapUrl && mapContainer) {
      console.log("Header ::::::::::: use effect calling update style ::::::::: ")
      mapContainer.updateMapStyle(`${props.mapUrl}?api_key=${getApiKey()}`)
    }
  }, [props.mapUrl])

  useEffect(() => {
    props.fetchRapidSOS(showRapidSOS);
  }, [showRapidSOS]);

  useEffect(() => {
    if(isSecondaryMapAvailable())
      props.fetchSplitScreen(showSplitScreen)
  }, [showSplitScreen]);

  useEffect(() => {
    props.fetchUncertainity(uncertainity)
  }, [uncertainity]);
 
  const handleSignOut = async () => {
    if (configAuthType() === AUTH_TYPES.OKTA) {
      await authClient.signOut();
      console.log('--- signout ---');
      sessionStorage.clear();
    } else {
      signOut();
    }
  }

  const handleMapLayers = () => {
    toggleMapLayer(!showMapLayers)
  }

  const handleTraffic = () => {
    mapContainer.showTrafficLayers(!showTraffic)
    toggleTrafficLayer(!showTraffic)
  }

  const handleWeather = () => {
    mapContainer.showWeatherLayers(!showWeather)
    toggleWeatherLayer(!showWeather)
  }

  const handleStyleChange = (theme) => {
    console.log("Header ::::::::::: handleStyleChange ::::::::: ")
    setSelectedTheme(theme.Name);
    props.fetchMapThemeUrl(theme.Url);
  }

  const navigateToHomePage = () => {
    navigate(ROUTE_ENDPOINTS.CALLS)
  }

  const handleRapidSOS = () => {
    setRapidSOS(!showRapidSOS);
  }

  const handleSplitScreen = () => {
    setSplitScreen(!showSplitScreen);
  }

  const handleUncertainty = () => {
    setUncertainity(!uncertainity);
  }

  return (
    <>
      <div className="header">
        <div className="psap-header header-bar">
          <div className="d-flex align-items-center">
            <div className="menu-grid">
              <OtherApps />
              <div className='hand-cursor' onClick={navigateToHomePage}>
                {/* <img src={srLogo} /> */}
                <span className="logo-text">{appName}</span>
              </div>
            </div>
            <GroupName />
          </div>
          {isLoggedIn ? (
            <div className="float-right user-name d-flex align-items-center">

              {EXCLUDE_MAP.includes(activepath) ? <> </> : <>
                {isFeatureAllowed(featureLabels.call) ? <>
                  {isSecondaryMapAvailable() && props.isMap ? <div className={`hand-cursor nav-list ${showSplitScreen ? 'active' : ''}`} onClick={handleSplitScreen}>
                    <img src={showSplitScreen ? splitScreenOn : splitScreenOff} className='nav-icon' />
                    <span className={showSplitScreen ? "active" : "inactive"}>Split Screen</span>
                  </div> : ''}
                  <div className={`hand-cursor nav-list ${showRapidSOS ? 'active' : ''}`} onClick={handleRapidSOS}>
                    <img src={showRapidSOS ? icRapidOn : icRapidOff} className='nav-icon' />
                    <span className={showRapidSOS ? "active" : "inactive"}>RapidSOS</span>
                  </div>
                </> : ''}
                {props.isMap ? (<MapStyles
                  mapThemes={props.mapThemes}
                  handleStyleChange={handleStyleChange}
                />) : ''}
                {isFeatureAllowed(featureLabels.call) ? <>
                  <div className={`hand-cursor nav-list ${uncertainity ? 'active' : ''}`} onClick={handleUncertainty}>
                    <img src={uncertainity ? icUncOn : icUncOff} className='nav-icon' />
                    <span className={uncertainity ? "active" : "inactive"}>Uncertainty</span>
                  </div>
                </> : ''}
                {props.isMap ? (<MapLayers />) : ''}
              </>
              }
              
              <UserIcon handleSignOut={handleSignOut} />
            </div>
          ) : ''}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    mapThemes: state.mapInfo ? state.mapInfo.mapThemes : null,
    mapUrl: state.mapInfo ? state.mapInfo.mapUrl : null,
    splitScreen: state.mapInfo ? state.mapInfo.isSplitScreen : false,
    isMap: state.settings.isMap,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMapThemeUrl: (url) => dispatch(fetchMapThemeUrl(url)),
    fetchSplitScreen: (val) => dispatch(fetchSplitScreen(val)),
    fetchRapidSOS: (val) => dispatch(fetchRapidSOS(val)),
    fetchUncertainity: (val) => dispatch(fetchUncertainity(val))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);