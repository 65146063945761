import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { MapInfoReducer, settingsInfoReducer } from "./reducers/headerReducer";
import { CallsReducer, socketDataReducer, transcriptStatusReducer } from "./reducers/callsReducer";
import { CriminalDataReducer, IdentityReducer } from "./reducers/whoosterReducer";
import { notificationsReducer } from "./reducers/notificationReducer";
import { SolacomReducer } from "./reducers/solacomReducer";


const rootReducer = combineReducers({
    mapInfo: MapInfoReducer,
    callsInfo: CallsReducer,
    personIdentityInfo : IdentityReducer,
    criminalInfo: CriminalDataReducer,
    notifications: notificationsReducer,
    solacomData: SolacomReducer,
    settings: settingsInfoReducer,
    socketData: socketDataReducer,
    transcriptStatus: transcriptStatusReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;