import {
    FETCH_SOLACOM_CALL_EVENTS
} from '../actions/actionTypes';

const initialState = {
}

export const SolacomReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SOLACOM_CALL_EVENTS:
            return { queuedCalls: action.queuedCalls, unplottedCalls: action.unplottedCalls, answeredCalls: action.answeredCalls}
        default:
            return state;
    }
}