import axios from 'axios';
import {urls}  from '../urls';
import {  callConfigs, featureLabels } from '../constants/Constants';
import { getApiKey, getBaseUrl, getStationIdFromLocalStorage } from '../utilities/Utils';
import { STORAGE_KEYS } from '../constants/SessionConstants';

const simulatedLiveFeedUrl = `${urls.enableSimulationData}` === 'true'?`${urls.simulated911FeedUrl}`:'';
const simulatedLiveFeedApiKey = `${urls.enableSimulationData}` === 'true'? `${urls.simulated911FeedApiKey}` !== 'undefined'? `${urls.simulated911FeedApiKey}` :  getApiKey():'';
const psapProxyUrl = `${getBaseUrl()}/geo/maps/v2/psap`;
const criminalPath = '/person/v1.0/criminal/search';
const identityPath = '/person/v1.0/identity/search';

export const simulationCallApi = () => {
    var gisId = callConfigs.defaultGisId
    if (featureLabels && featureLabels != null && featureLabels.gis_id != null && featureLabels.gis_id != "") {
        gisId = featureLabels.gis_id
    }
    return axios.get(simulatedLiveFeedUrl + "?api_key=" + simulatedLiveFeedApiKey + "&gis_id=" + gisId)
}

export const notificationCallApi = () => {
    return axios.get(`${getBaseUrl()}/sa/${sessionStorage.getItem(STORAGE_KEYS.GROUP_NAME)}/calls/notification?api_key=${getApiKey()}&pos_id=${getStationIdFromLocalStorage()}`)
}

export const criminalSearchApi = (firstName, lastName, dob) => {
    const apiUrl = psapProxyUrl+criminalPath;
    const data = {
        "dob": dob,
        "firstName": firstName,
        "lastName": lastName
    };
    const config = {
        headers: {
            "Content-Type": "application/json",
            "api_key": getApiKey(),
        },
    };

    return axios.post(apiUrl, data, config);
}

export const identitySearchApi = (mdn) => {
    const apiUrl = psapProxyUrl+identityPath;
    const data = {
        "mdn": mdn
    };
    const config = {
        headers: {
            "Content-Type": "application/json",
            "api_key": getApiKey(),
        },
    };

    return axios.post(apiUrl, data, config);
}