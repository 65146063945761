import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Tooltip from 'react-bootstrap/Tooltip';
import { MdOutlineCall } from "react-icons/md";
import "sa-common/sidemenu/sidemenu.scss";
import { ROUTE_ENDPOINTS } from '../constants/RouteConstants'
import '../styles/sidemenu.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { isFeatureAllowed } from '../utilities/Utils';
import {toolTipLabels, featureLabels} from "../constants/Constants"

function SideMenu(props) {
// const SideMenu = () => {

    const location = useLocation();
    // Extract the pathname from the location object
    const currentPath = location.pathname;

    const activeRouteColor = ({ isActive }) => {
        return { color: isActive ? "#122c44" : "#D3D3D3" };
        // return isActive;
    }
    const unReadMessageColor = ({ isActive }) => {
        return { color: isActive ? "#ea3c3c" : "#ea3c3c" };
        // return isActive;
    }

    const TooltipWrapper = ({ children, content }) => {
        return (
            <OverlayTrigger placement="right" overlay={(props) => renderTooltip(props, content)} delay={{show: 200, hide: 0}}>
                {children}
            </OverlayTrigger>
        )
    }
    const renderTooltip = (props, content) => (<Tooltip className="menu-tooltip" {...props}>{content}</Tooltip>)

    return (
        <>
            <div className="sideMenu">
                {isFeatureAllowed(featureLabels.call) ?
                <div>
                    <TooltipWrapper content={toolTipLabels.calls}>
                        <NavLink to={ROUTE_ENDPOINTS.CALLS} style={activeRouteColor} className="sideIcon">
                            <MdOutlineCall />
                        </NavLink>
                    </TooltipWrapper>
                </div>
                : '' }
            </div>
        </>
    );
};

  
  export default SideMenu;
