import * as React from "react";
import "../styles/footer.scss";

export default function Footer() {
  return (
    <footer id='footer' className='footer footer-fix'>
      <div className="navbar-header position-relative container">
        <div className="d-flex">
            <div>
              @ 2024 Comtech Telecommunications Corp. All Rights Reserved.
                </div>
            <div className="footer-links">
              <span className="link">
                <a
                  href="https://comtech.com/privacy-policies"
                  target="_blank"
                >
                  Privacy Policy
                    </a>
              </span>
              <span className="link">
                <a
                  href="https://comtech.com/terms-of-use"
                  target="_blank"
                >
                  Terms of Use
                    </a>
              </span>
              <span className="last-link">
                <a
                  href="https://www.comtechlocation.com/contact-us"
                  target="_blank"
                >
                  Contact Us
                    </a>
              </span>
            </div>
        </div>
      </div>
    </footer>
  );
}
