import React, { useContext, useEffect, useState, useRef } from 'react';
import { STORAGE_KEYS } from "../../../constants/SessionConstants";
import Dropdown from "react-bootstrap/Dropdown";
import { BsArrowRightCircleFill, BsGrid3X3GapFill } from 'react-icons/bs';
import NetworkContext from 'sa-common/context/NetworkContext';

function OtherApps(props) {
    const { isOnline } = useContext(NetworkContext);
    const dropdownRef = useRef(null);
    const [isDropdownOpen, setDropdownOpen] = React.useState(false);

    const [userApps, setUserApps] = useState([]);

    useEffect(() => {
        const userAppsFrmSession = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.USER_APPLICATIONS));
        if (userAppsFrmSession && userApps && userApps.length == 0) {
            setUserApps(userAppsFrmSession);
        }
    }, [])

    const handleIconClick = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleItemClick = (url, active) => {
        if (active === false) {
            window.open(url, '_blank');
        }
        setDropdownOpen(false);
    }


    return (
        <>
            <div className='gird-fill' ref={dropdownRef}>
                <Dropdown show={isDropdownOpen} onToggle={(isOpen) => setDropdownOpen(isOpen)}>
                    <Dropdown.Toggle drop='left' as="div" className="profile-img" onClick={handleIconClick}>
                        <BsGrid3X3GapFill />
                    </Dropdown.Toggle>
                    {userApps && userApps.length && isDropdownOpen ? (
                        <Dropdown.Menu className="grid-dropdown">
                            {userApps.map((item, index) => (
                                <Dropdown.Item key={index} href={!item.isCurrentApp ? item.url : '#'} target='_blank' onClick={() => handleItemClick(item.url, item.active)} className="dropdown-item-with-border">
                                    <div className="flexRow">
                                        <div className="text-icon">
                                            {item.isCurrentApp === true ? <BsArrowRightCircleFill /> : ''}
                                        </div>
                                        <div className="selection-text">
                                            {item.label}
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    ) : ''}
                </Dropdown>
            </div>
        </>
    )
}

export default OtherApps;