import React, { useState, useEffect } from 'react';
import Alert from "react-bootstrap/Alert";
import { Auth } from "@aws-amplify/auth";
import { primaryRegion, secondaryRegion } from "../../awsexport-client";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {configureAmplify} from './LoginUtils'
import { storeRegionInfo} from "../../utilities/LoginUtils";

const CognitoLogin = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertType, setAlertType] = useState('');
  const [formState, setFormState] = useState('')
  const [showpwd, setShowPwd] = useState(false);
  const [pwdText, setPwdText] = useState('password');

  useEffect(() => {
    configureAmplify()
  }, []);

  const toggleIcon = (val) => {
    if (val === 'show') {
      setShowPwd(true);
      setPwdText('text');
    } else {
      setShowPwd(false);
      setPwdText('password');
    }
  };

  const doSignIn = (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (username !== '' && password !== '') {
      setLoader(true);
      setAlertMsg('');

      Auth.signIn(username.toLowerCase(), password)
        .then((user) => {
          setLoader(false);
          storeRegionInfo(primaryRegion, secondaryRegion);
          onLoginSuccess(user)
        })
        .catch((err) => {
          setLoader(false);
          switch (err.code) {
            case 'UserLambdaValidationException':
              let msg;
              if (err.message.includes('PreAuthentication failed with error')) {
                msg = err.message.split(':')[1];
              } else {
                msg = err.message;
              }
              setAlertMsg(msg);
              setAlertType('danger');
              break;

            case 'UserNotConfirmedException':
              setAlertMsg(err.message);
              setAlertType('danger');
              setSubmitted(false);
              setFormState('confirmSignUp');
              break;

            case 'UserNotFoundException':
            case 'NotAuthorizedException':
            case 'InvalidParameterException':
            default:
              setAlertMsg('Incorrect username or password.');
              setAlertType('danger');
              break;
          }
          clearSessionStorage();
        });
    }
  };


  const clearSessionStorage = () => {
    for (let key in sessionStorage) {
      sessionStorage.removeItem(sessionStorage.key(key));
    }
  };

  return (
    <div>
      <Alert show={alertMsg} key="signInAlert" variant={alertType}>
        {alertMsg}
      </Alert>
      <>
        <form>
          <>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value.toLowerCase())}
                disabled={loader}
                className={`form-control ${submitted && !username ? 'is-invalid' : ''}`}
              />
              {submitted && !username && <div className="invalid-feedback">Username is required</div>}
            </div>

            <div className="form-group position-relative">
              <label htmlFor="password">Password</label>
              <input
                type={pwdText}
                disabled={loader}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                autoComplete="current-password"
                className={`form-control ${submitted && !password ? 'is-invalid' : ''}`}
              />
              {showpwd ? (
                <FaEyeSlash className="position-absolute show-pwd cursor-txt" onClick={() => toggleIcon('hide')}>
                  hide
                </FaEyeSlash>
              ) : (
                <FaEye className="position-absolute show-pwd cursor-txt" onClick={() => toggleIcon('show')}>
                  show
                </FaEye>
              )}
              {submitted && !password && <div className="invalid-feedback">Password is required</div>}
            </div>
            <div className="form-group">
              <button
                disabled={loader}
                className="comtech-btn comtech-btn-primary btn-lg"
                onClick={doSignIn}
                type="submit"
              >
                <span>Sign In</span>
              </button>
              {loader && (
                <div className="p-10 mx-3 spinner-border align-middle text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              )}
            </div>
          </>
        </form>
      </>
    </div>
  );
};
export default CognitoLogin;