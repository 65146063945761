import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";
import Header from "../presentation/Header";
import Footer from "../presentation/Footer";
import LoginPage from "../presentation/LoginPage";
import OktaLoginPage from "../presentation/OktaLoginPage";
import AppCommon from "../presentation/AppCommon";
import { ROUTE_ENDPOINTS } from "../constants/RouteConstants";
import { configAuthType } from '../utilities/Utils';
import { MapProvider } from '../context/MapContext';
import Dashboard from '../presentation/Dashboard';
import { AuthProvider } from "../context/AuthContext";
import { AUTH_TYPES } from '../constants/Constants';


import About from '../presentation/settings/About';
import CallsComponent from '../presentation/views/calls/CallsComponent';
import Settings from '../presentation/settings/Settings';


export default function AppRouter() {
	const authType = configAuthType();
	const queryParameters = new URLSearchParams(window.location.search);
	const issParam = queryParameters.get("iss"); // iss: Okta domain url

	const routes = () => {
		return (
			<Suspense fallback={<div>Loading...</div>}>
				<Routes>
					<Route path={ROUTE_ENDPOINTS.LOGIN} element={authType === AUTH_TYPES.OKTA ? (<OktaLoginPage issParam={issParam} />) : (<LoginPage />)} />
					{authType === AUTH_TYPES.OKTA ? (<Route path="/login-callback" element={<OktaLoginPage isLoginCallback={true} />} />) : ''}
					<Route element={<PrivateRoute />}>
						<Route element={<AppCommon />}>
							<Route element={
								<MapProvider>
									<Header />
									<Dashboard />
								</MapProvider>
							}>
							<Route path={ROUTE_ENDPOINTS.CALLS} element={<CallsComponent/>} />
							<Route path={ROUTE_ENDPOINTS.ABOUT} element={<About />} />
							<Route path={ROUTE_ENDPOINTS.SETTINGS} element={<Settings />} />	
							</Route>
						</Route>
					</Route>
					<Route path="*" element={<Navigate to={ROUTE_ENDPOINTS.LOGIN} />} />
				</Routes>
			</Suspense>
		)
	}

	return (
		<BrowserRouter>
			<AuthProvider>
				{ routes() }
				<Footer />
			</AuthProvider>
		</BrowserRouter>
	);
}