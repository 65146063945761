import React, { useContext, useEffect, useState } from 'react';
import { MapContext } from '../../context/MapContext';
import Map from "sa-common/map/Map";
import { connect } from 'react-redux';

function MapView(props) {
    //console.log("MapView ::::: props ::::::: ", props)
    const { updateMapValues, updateMapLoadStatus, updateSecondMapValues, updateSecondMapLoadStatus, updateLocateMeClick } = useContext(MapContext);
    // Function to set mapContainer and mapRef when the map component is loaded
    const handleMapLoad = (container, ref) => {
        //console.log("MapView :::::: handleMapLoad ::::: ")
        updateMapValues(container, ref);
        props.handleMapLoad(container, ref)
    };

    // Function to set mapContainer and mapRef when the map component is loaded
    const handleSecondMapLoad = (container, ref) => {
        //console.log("MapView :::::: handleSecondMapLoad ::::: ")
        updateSecondMapValues(container, ref);
    };

    const handleMapLoadChange = (status) => {
        //console.log("MapView :::::: handleMapLoadChange ::::: ")
        updateMapLoadStatus(status)
    }

    const handleLocateMeClick = (val) => {
        //console.log("MapView :::::: handleMapLocateMe ::::: ")
        updateLocateMeClick(val);
    }

    return (
        <>
            <Map
                mapStyle={props.mapStyle}
                zoom={props.zoom}
                centerCoordinates={props.centerCoordinates}
                onMapComponentLoaded={handleMapLoad}
                onMapLoadChange={handleMapLoadChange}
                onSecondMapLoaded = {handleSecondMapLoad}
                onLocateMeClick = {handleLocateMeClick}
                searchValue={false}
                secondaryMapRequired={props.secondaryMapRequired}
                secondMapStyle={props.secondMapStyle}
                secondaryMapZoom={props.secondaryMapZoom}
            />
        </>
    )
}


const mapStateToProps = (state) => {
	return {  
        
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
        
	} 
}

export default connect(mapStateToProps, mapDispatchToProps)(MapView);;