import AWS from 'aws-sdk';
import AwsIot from 'aws-iot-device-sdk';
import { oktaRoleConfigData } from "../presentation/login/okta/OktaLoginUtils";
import { getOktaIdTokenFromSession } from "../utilities/Utils";
import { primaryRegionData } from "../oktaexport-client";
import { notificationConfig, callConfigs } from "../constants/Constants";
import { configTimers } from "../constants/ConfigTimers";

let renewAwsTokenTimeout = null;
let deviceClient = null;

export const initNotifications = async (eventHandlers, callbackFn) => {
  const idToken = getOktaIdTokenFromSession();
  let deviceResponse;
  let expiryTime;
  deviceResponse = await oktaInitAwsDevice(idToken, primaryRegionData);
  deviceClient = deviceResponse.deviceClient;
  expiryTime = deviceResponse.expiryTime;
  await manageAWSTokenExpiry(expiryTime, eventHandlers);
  await connectToAwsIot(deviceClient, eventHandlers);
  await callbackFn(deviceClient);
}

export const oktaInitAwsDevice = (idToken, regionData) => {
  return new Promise((resolve, reject) => {
    if (idToken && idToken != null && idToken.length > 0) {
      try {
        const sts = new AWS.STS();
        const oktaRoleObj = oktaRoleConfigData();
        const params = {
          DurationSeconds: oktaRoleObj.sessionDuration,
          RoleArn: oktaRoleObj.roleARN,
          RoleSessionName: oktaRoleObj.roleName,
          WebIdentityToken: idToken,
        };
  
        sts.assumeRoleWithWebIdentity(params, (err, data) => {
          if (err) {
            console.log(err, err.stack); // an error occurred
            reject(err);
          } else {
            console.log('AWS STS Request: SUCCESS');  // successful response
            const keysData = {
              accessKeyId: data.Credentials.AccessKeyId,
              secretKey: data.Credentials.SecretAccessKey,
              sessionToken: data.Credentials.SessionToken,
            }
            const expiryTime = (new Date(data.Credentials.Expiration)).getTime();
            const devClient = createAwsIotDevice(keysData, regionData);
            resolve({ deviceClient: devClient, expiryTime: expiryTime });
          }
        });
      } catch (err) {
        console.log('::::: fetchAWSToken exception :::::');
        reject(err);
        // console.error(err); // an error occurred
      }
    }
  })
}

const createAwsIotDevice = (data, regionData) => {
  let clientID = notificationConfig.notificationClientIdSuffix + new Date().getTime(); //needs to be unique
  return AwsIot.device({
    region: regionData.aws_project_region,
    clientId: clientID,
    host: regionData.aws_notification_host,
    autoResubscribe: true,
    offlineQueueing: false,
    minimumConnectionTimeMs: notificationConfig.notificationReconnectTime || 5000,
    protocol: 'wss',
    accessKeyId: data.accessKeyId,
    secretKey: data.secretKey,
    sessionToken: data.sessionToken,
  });
}

export function connectToAwsIot (device, eventHandlers) {
  device.on('connect', () => {
    console.log("::::: connected :::::");
    eventHandlers.onConnectHandler();
  });
  device.on('message', (topic, payload) => {
    eventHandlers.onMessageHandler(topic, payload);
  });
  device.on('reconnect', () => {
    console.log("::::: reconnect :::::");
    if (eventHandlers.onReconnect) eventHandlers.onReconnectHandler();
  });
  device.on('error', (data) => {
    console.log("::::: error :::::");
    if (eventHandlers.onError) eventHandlers.onErrorHandler();
  });
  device.on('disconnect', () => {
    console.log("::::: disconnect :::::");
    if (eventHandlers.onDisconnect) eventHandlers.onDisconnectHandler();
  });
  device.on('unsubscribe', () => {
    console.log("::::: unsubscribe :::::");
    if (eventHandlers.onUnsubscribe) eventHandlers.onUnsubscribeHandler();
  });
  device.on('offline', () => {
    console.log("::::: offline :::::");
    if (eventHandlers.onOffline) eventHandlers.onOfflineHandler();
  });
  device.on('close', () => {
    console.log("::::: close :::::");
    if (eventHandlers.onClose) eventHandlers.onCloseHandler();
  });
}

const manageAWSTokenExpiry = (tokenExpiryTime, eventHandlers) => {
  let currentDateTime = new Date().getTime(); // in milli seconds
  let nextTimeout = tokenExpiryTime - currentDateTime;
  if (renewAwsTokenTimeout) clearTimeout(renewAwsTokenTimeout);
  renewAwsTokenTimeout = setTimeout(() => {
    console.log('::::: renewing AWS token :::::');
    if (deviceClient) deviceClient.end(true);
    const notifyClient = initNotifications(eventHandlers);
  }, nextTimeout > 0 ? nextTimeout : configTimers.awsNotificationTokenExpiryTime);
}

export const subscribeToCalls = (notifyClient, stationID) => {
  if (stationID) {
    var topic = callConfigs.sim911Topic + stationID;
    deviceClient.subscribe(topic, function (err) {
      if (err === null) {
        clearSessionStorage();
        console.log("::::::::::::: subscribed to " + topic);
      } 
    });
  }
}

export const subscribeToSolacom = (notifyClient) => {
  var topic = callConfigs.sim911Topic + callConfigs.solacomTopic;
  deviceClient.subscribe(topic, function (err) {
    if (err === null) {
      clearSessionStorage();
      console.log("::::::::::::: subscribed to " + topic);
    } 
  });
}

const clearSessionStorage = () => {
  if (sessionStorage.getItem('currentUser') === null) {
    sessionStorage.clear();
  }
}