import React, { useContext, useEffect, useState } from 'react';
import { getGroupProfile } from "../../../utilities/Utils";
import Dropdown from "react-bootstrap/Dropdown";
import { mapStyle, featureLabels, AUTH_TYPES } from '../../../constants/Constants';
import icMapOff from 'sa-common/assets/icon_map_off.png';
import icMapOn from 'sa-common/assets/icon_map_on.png';


function MapStyles(props) {

    const [showMapTheme, toggleMapTheme] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState('');
    const [initialMap, setInitialMap] = useState(true);

    const handleMapTheme = () => {
        toggleMapTheme(!showMapTheme)
    }

    const handleStyleChange = (theme) => {
        setSelectedTheme(theme.Name);
        setInitialMap(false)
        props.handleStyleChange(theme);
      }

    return (
        <>
            <div className='hand-cursor nav-list map-style' onClick={handleMapTheme}>
                <Dropdown className='hand-cursor '>
                    <Dropdown.Toggle drop='left'>
                        <img src={showMapTheme ? icMapOn : icMapOff} className='nav-icon' />
                        Map Style
                    </Dropdown.Toggle>
                    {props.mapThemes && props.mapThemes.length ?
                        <Dropdown.Menu >
                            {props.mapThemes.map((list, ind) => {
                                return (
                                    <Dropdown.Item key={ind} className={(selectedTheme === list.Name || initialMap && ind === 0) ? 'selected' : null} onClick={() => handleStyleChange(list)}>
                                        <img src={mapStyle.images[list.Name] ? mapStyle.images[list.Name] :
                                            list.Name.toLowerCase().includes(mapStyle.themes.dark) ? mapStyle.images[mapStyle.themes.dark] :
                                                list.Name.toLowerCase().includes(mapStyle.themes.day) ? mapStyle.images[mapStyle.themes.day] :
                                                    list.Name.toLowerCase().includes(mapStyle.themes.light) ? mapStyle.images[mapStyle.themes.light] :
                                                        list.Name.toLowerCase().includes(mapStyle.themes.night) ? mapStyle.images[mapStyle.themes.night] :
                                                            mapStyle.images['default']} className='mapstyle-icon' />
                                        {list.Name}
                                    </Dropdown.Item>)
                            })}

                        </Dropdown.Menu>
                        : ''}
                </Dropdown>
            </div>
        </>
    )
}

export default MapStyles;