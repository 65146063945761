import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { MapContext } from './../context/MapContext';
import { EXCLUDE_MAP, ROUTE_ENDPOINTS } from "../constants/RouteConstants";
import { groupLocations, transcriptStatus } from '../constants/Constants';
import SideMenu from "./SideMenu";
import MapView from './map/MapView';
import  NetworkContext from 'sa-common/context/NetworkContext';
import { getMapThemesData } from '../redux/actions/headerActions';
import { getApiKey, getGroupProfile, SolacomGroup, getUserName } from '../utilities/Utils';
import "../styles/dashboard.scss";
import Conversations from 'sa-common/conversations/Conversations';
import { setSocketData, setCallsData, setSolacomCallsData, setTranscriptStatus  } from '../redux/actions/callsActions';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { urls } from '../urls';

const Dashboard = (props) => {
  const { isOnline, updatePingToken, setPingUrl } = useContext(NetworkContext);
  const location = useLocation();
  // Extract the pathname from the location object
  const currentPath = location.pathname;
  const {mapContainer, updateMapValues, mapRef } = useContext(MapContext);
  const [excludeMap, setExcludeMap] = useState(false);
  const [isMapReady, setIsMapReady] = useState(false);
  const [conversationJSON, setConversationJSON] = useState([]);
  const [conversationStatus, setConversationStatus] = useState(null);
  const [mapClassName, setMapClassName] = useState("content-section");
  const [state, setState] = useState({
    secondMapRequired: false,
    secondMapStyle: '',
    mapStyle: '',
  });
  //AWS
  const { sendMessage, lastMessage, readyState } = useWebSocket(urls.awsWebSocketUrl,{retryOnError:true, reconnectAttempts: 50});

  // useEffect for styles fetching
  useEffect(() => {
    var hideMap = EXCLUDE_MAP.includes(currentPath)
    setExcludeMap(hideMap)
    if (!hideMap) {
      fetchMapStyles();
    }
    document.body.className = 'incident-bg';
  }, []);

  useEffect(() => {
    var hideMap = EXCLUDE_MAP.includes(currentPath)
    setExcludeMap(hideMap);
    if(hideMap) {
      window.location.hash = ''
    } else {
      if(mapContainer) {
        mapContainer.resize()
      }
    }
  }, [currentPath])
  
  useEffect(() => {
    if (props.mapUrl) {
      //console.log("Dashboard :::: setMapStyle ::: ")
      var hideMap = EXCLUDE_MAP.includes(currentPath)
      setExcludeMap(hideMap)
      let secondMapUrl = ''
      let showSecondMap = false
      var groupFeatures = getGroupProfile()
      if (groupFeatures && groupFeatures.secondMap) {
        let secondMap = groupFeatures.secondMap;
        if (secondMap != null && secondMap.length) {
          let tempUrl = '';
          const mapPathStyles = props.mapThemes.map(ds => ds.Url.split('default')[1]);
          const selectedSecondaryMap = ''; //this.props.secondMapPath;
          // const selectedSecondaryMap = localStorage.getItem('secondarymapview');
          if (selectedSecondaryMap && selectedSecondaryMap !== '') {
            tempUrl = selectedSecondaryMap;
            // setSecondMapStyle(tempUrl);
          } else {
            let tempSecondMap = Array.isArray(secondMap) ? secondMap[0] : secondMap;
            if (tempSecondMap.charAt(0) === "/") {
              tempUrl = tempSecondMap
            } else {
              tempUrl = '/' + tempSecondMap
            }
            // setSecondMapStyle(tempUrl);
          }

          try {
            if (mapPathStyles.includes(tempUrl)) {
              const matchingTheme = props.mapThemes.find(ds => ds.Url.includes(tempUrl));
              if (matchingTheme) {
                showSecondMap = true
                secondMapUrl = `${matchingTheme.Url}?api_key=${getApiKey()}`
                //console.log(`Dashboard :::: secondMapUrl ::::::: ${secondMapUrl}`)
              }
            } else {
              secondMapUrl = ''
              showSecondMap = false
            }
          } catch (error) {
            // 
            showSecondMap = false
          }
        } else {
          showSecondMap = false
        }
      }
      if (secondMapUrl && secondMapUrl != '') {
        setState(prevState => ({
          ...prevState,
          secondMapRequired: true,
          secondMapStyle: secondMapUrl,
          mapStyle: `${props.mapUrl}?api_key=${getApiKey()}`,
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          mapStyle: `${props.mapUrl}?api_key=${getApiKey()}`,
        }));
      }

    }
  }, [props.mapUrl])

  useEffect(() => {
    if (isOnline) handleOfflineToOnline();
  }, [isOnline]);

  useEffect(() => {
   
  }, [isMapReady])

  useEffect(() => {
        if(props.socketData){
      setConversationJSON(props.socketData)
    }

  }, [props.socketData])

  useEffect(() => {
    if (lastMessage !== null && lastMessage.data && JSON.parse(lastMessage.data).TranscriptEvent && JSON.parse(lastMessage.data).TranscriptEvent.Alternatives && JSON.parse(lastMessage.data).TranscriptEvent.Alternatives.length) {
      if (!JSON.parse(lastMessage.data).TranscriptEvent.IsPartial) {
        // console.log('transactionID:::::::::::::::::', JSON.parse(JSON.parse(lastMessage.data).metadata).transactionId);
        props.setSocketData(
          {
            "conversationId": JSON.parse(lastMessage.data).TranscriptEvent.ResultId,
            "body": {
              "message": JSON.parse(lastMessage.data).TranscriptEvent.Alternatives[0].Transcript,
              "translatedMessage": JSON.parse(lastMessage.data).TranscriptEvent.Alternatives[0].Translation ? JSON.parse(lastMessage.data).TranscriptEvent.Alternatives[0].Translation : '',
              "translatedMessageLang": getLanguageCode(JSON.parse(lastMessage.data).TranscriptEvent.LanguageCode),
            },
            "owner": JSON.parse(lastMessage.data).TranscriptEvent.ChannelId === 'ch_0' ? 'Agent' : 'Customer',
            "author": JSON.parse(lastMessage.data).TranscriptEvent.ChannelId === 'ch_0' ? 'Agent' : 'Customer',
            "authorIid": JSON.parse(lastMessage.data).TranscriptEvent.ChannelId === 'ch_0' ? 'Agent' : 'Customer',
            "date_created": JSON.parse(lastMessage.data).time,
            "date_updated": JSON.parse(lastMessage.data).time,
            "index": 0,
            "transactionId": JSON.parse(JSON.parse(lastMessage.data).metadata).transactionId
          }
        )
      }
    } else if (lastMessage && lastMessage.data && JSON.parse(lastMessage.data)['detail-type'] && JSON.parse(lastMessage.data)['detail-type'] == "Chime VoiceConnector Streaming Status") {
      console.log("StreamingStatus:::::::::::::", JSON.parse(lastMessage.data).detail.streamingStatus);
      setConversationStatus(JSON.parse(lastMessage.data).detail.streamingStatus)
      props.setTranscriptStatus(JSON.parse(lastMessage.data).detail.streamingStatus)
    }
  }, [lastMessage]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      console.log('Websocket :::::::::::::::::::::::::::::::: Connection opened.');
      // reconnect();
    }
    if (readyState === ReadyState.CLOSED) {
      console.log('Websocket :::::::::::::::::::::::::::::::: Connection closed.');
      // reconnect();
    }
  }, [readyState]);

  // TODO - remove the below logic later. For demo we are forcefully changing the call state to end
  useEffect(() => {
    if (SolacomGroup() && conversationStatus && conversationStatus != '' && conversationStatus === transcriptStatus.ended) {
      console.log("conversationStatus Updated :::::::::::::", conversationStatus);
        if(props.callsInfo) {
          const tempData = { ...props.callsInfo, callState: 'ended', eventType: 'EndCall' };
          props.setCallsData(tempData);
          props.setSolacomCallsData(tempData);
        }
    } else if (SolacomGroup() && conversationStatus && conversationStatus != '' && conversationStatus === transcriptStatus.started) {
      if(props.callsInfo) {
        const tempData = { ...props.callsInfo, callState: 'inProgress', eventType: 'Answer' };
        props.setCallsData(tempData);
        props.setSolacomCallsData(tempData);
      } else if(props.endCallsData && props.endCallsData.length > 0) {
        const latestObj = props.endCallsData[0]
        if(latestObj) {
          const tempData = { ...latestObj, callState: 'inProgress', eventType: 'Answer' };
          props.setCallsData(tempData);
          props.setSolacomCallsData(tempData);
        }
      }
    }
  }, [conversationStatus])

  const fetchMapStyles = () => {
    //console.log("Dashboard :::: fetchMapStyles ::: ")
    props.getMapThemesData();
  }

  const getLanguageCode = (code) => {
    switch (code) {
      case 'en-US':
          return "English"
          case 'es-US':
          return "Spanish"
          case 'fr-CA':
          return "French"
          case 'it-IT':
          return "Italian"
          case 'pt-BR':
          return "Portuguese"
          case 'de-DE':
          return "German"
      default:
          return "English";
    }
  }
  
  // Function to set mapContainer and mapRef when the map component is loaded
  const handleMapLoad = (container, ref) => {
    //console.log("Dashboard :::::: handleMapLoad ::::: ")
    // updateMapValues(container, ref);
      setIsMapReady(true)

  };

  const handleOfflineToOnline = () => {
    
  }

  const mapContainerStyle = (!excludeMap && props.isMap) ? 'content-section' : 'content-section-hide';


  return (
    <div>
      <div className="d-flex">
        {props.isToolbar ? 
        <div className="left-side">
          <SideMenu />
        </div>
        : ''}
        <div className="right-section">
          <div >
            <Outlet />
          </div>
          <div>
            {currentPath === ROUTE_ENDPOINTS.CALLS ?  <Conversations isToolbar={props.isToolbar} isMap={props.isMap} messages={conversationJSON} /> : ''}
          </div>
          <div className={mapContainerStyle}>
            <div>
              {state.mapStyle ? (
                <MapView
                  mapStyle={state.mapStyle}
                  zoom={groupLocations.default.zoom} 
                  centerCoordinates={groupLocations.default.center} 
                  handleMapLoad={handleMapLoad}
                  secondaryMapRequired={state.secondMapRequired}
                  secondMapStyle={state.secondMapStyle}
                  secondaryMapZoom={groupLocations.default.zoom}
                />
              ) : (<div>Loading Map...</div>)}
            </div>
          </div>
          {/* {!excludeMap && props.isMap ? <div className={mapClassName}>
            <div>
              {state.mapStyle ? (
                <MapView
                  mapStyle={state.mapStyle}
                  zoom={groupLocations.default.zoom} 
                  centerCoordinates={groupLocations.default.center} 
                  handleMapLoad={handleMapLoad}
                  secondaryMapRequired={state.secondMapRequired}
                  secondMapStyle={state.secondMapStyle}
                  secondaryMapZoom={groupLocations.default.zoom}
                />
              ) : (<div>Loading Map...</div>)}
            </div>
          </div>
            : ""} */}
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
	return {
		mapThemes: state.mapInfo ? state.mapInfo.mapThemes : null,
		mapUrl: state.mapInfo ? state.mapInfo.mapUrl : null,
    isToolbar: state.settings.isToolbar,
    isMap: state.settings.isMap,
    socketData: state.socketData.socketData,
    solacomData: state.solacomData,
    callsInfo: state.callsInfo ? state.callsInfo.data : null,
    endCallsData: state.callsInfo ? state.callsInfo.endCallsData : [],
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		getMapThemesData: () => dispatch(getMapThemesData()),
    setSocketData:(payload) => dispatch(setSocketData(payload)),
    setSolacomCallsData: (data) => dispatch(setSolacomCallsData(data)),
    setCallsData: (data) => dispatch(setCallsData(data)),
    setTranscriptStatus: (data) => dispatch(setTranscriptStatus(data))
	} 
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);