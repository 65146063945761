import React, { useContext, useEffect, useState } from 'react';
import { getGroupProfile, isFeatureAllowed } from "../../../utilities/Utils";
import Dropdown from "react-bootstrap/Dropdown";
import { mapStyle, featureLabels, AUTH_TYPES } from '../../../constants/Constants';
import icTrafficOff from 'sa-common/assets/ml_traffic_off.png';
import icTrafficOn from 'sa-common/assets/ml_traffic_on.png';
import icWeatherOff from 'sa-common/assets/ml_weather_off.png';
import icWeatherOn from 'sa-common/assets/ml_weather_on.png';
import icCustomLayersOn from 'sa-common/assets/icon_custom_on.png';
import icCustomLayersOff from 'sa-common/assets/icon_custom_off.png';
import  NetworkContext  from 'sa-common/context/NetworkContext';
import { MapContext } from '../../../context/MapContext';


function MapLayers(props) {
    const { isOnline } = useContext(NetworkContext);

    const [showMapLayers, toggleMapLayer] = useState();
    const [showTraffic, toggleTrafficLayer] = useState(false);
    const [showWeather, toggleWeatherLayer] = useState(false);
    const { mapContainer } = useContext(MapContext);

    const handleMapLayers = () => {
        toggleMapLayer(!showMapLayers)
    }

    const handleTraffic = () => {
        if (isOnline) {
            mapContainer.showTrafficLayers(!showTraffic)
            toggleTrafficLayer(!showTraffic)
        }
    }

    const handleWeather = () => {
        if (isOnline) {
            mapContainer.showWeatherLayers(!showWeather)
            toggleWeatherLayer(!showWeather)
        }
    }

    return (
        <>
            <div className='hand-cursor nav-list map-style map-layers' onClick={handleMapLayers} onBlur={handleMapLayers}>
                <Dropdown className='hand-cursor d-inline mx-2'>
                    <Dropdown.Toggle drop='left'>
                        <img src={showMapLayers ? icCustomLayersOn : icCustomLayersOff} className='nav-icon' />
                        Map Layers
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                        {isFeatureAllowed(featureLabels.traffic) ?
                            <Dropdown.Item>
                                <div className={`hand-cursor nav-list ${showTraffic ? 'active' : ''}`} onClick={handleTraffic}>
                                    <img src={showTraffic ? icTrafficOn : icTrafficOff} className='nav-icon' />
                                    <span className={showTraffic ? "active" : "inactive"}>Traffic</span>
                                </div>
                            </Dropdown.Item>
                            : ''}
                        {isFeatureAllowed(featureLabels.weather) ?
                            <Dropdown.Item>
                                <div className={`hand-cursor nav-list layer-divider ${showWeather ? 'active' : ''}`} onClick={handleWeather}>
                                    <img src={showWeather ? icWeatherOn : icWeatherOff} className='nav-icon' />
                                    <span className={showWeather ? "active" : "inactive"}>Weather</span>
                                </div>
                            </Dropdown.Item>
                            : ''}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default MapLayers;