import {STORAGE_KEYS} from "./SessionConstants"

export const baseApi = {
  url : sessionStorage.getItem(STORAGE_KEYS.BASE_URL)
}

var sessionConfigs = {
  apiKey: sessionStorage.getItem(STORAGE_KEYS.API_KEY),
  username: sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER),
  groupName: sessionStorage.getItem(STORAGE_KEYS.GROUP_NAME),
  idToken: sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN)
}

export const serverUrls = {
  weatherUrl: `/geo/maps/v3/weather/v1.0`,
  suggestionUrl: '/geo/v3/suggestions',
  poiSearchUrl: '/geo/v3/select',    
  addressSearchUrl: '/geo/v3/search',
  w3wUrl: '/geo/v3/searchW3W',
  routeUrl:'/geo/v3/route',
  reversegeocodeUrl: '/geo/v3/reverseGeocode'
}

export const groupLocations = {
  'default': {
    'center': [-71.0875, 42.299],
    'zoom': 15,
    'secondMapZoom':18
  }
}

export const search = {
  pinZoom: 16,
}

export const cancelErrorMessages = {
  mapUserMsg: 'Map styles request is timing out.  Please try again.',
  searchTimeOut: 'Search request is timing out.  Please try again.',
  reverseGeocodeTimeOutMsg: 'Reverse GeoCode request is timing out.  Please try again.',
}

export const errorMessages = {
  suggestionError: 'Failed to get suggestions.  Please try again.',
  searchEmptyError: 'Please enter Address, POI or Lat lng to search.',
  searchError: 'Failed to get results.  Please try again.',
  noDataFound: 'No data found',
  loginFailedError: "Login failed. Please try again.",
  sessionExpiredMsg: 'Your session has expired. Please sign in again.',
  tokensExpiredMsg: 'Tokens are expired. Please sign in again.',
}

export const splitterConfigs = {
  secondaryPaneSize: '37%',
  dividerPosition: '37%',
  primaryPaneSize: '62.4%'
}

export const callConfigs = {
  meterToFeet: 3.28,
  callstartTime : 0,
  seconds: 0,
  minutes: 0,
  oldNinaId : '',
  counterTime: 1000,
  defaultZoom: 15,
  contourLayer: 'contour',
  uncertyCylinder: 'uncertyCylinder',
  zCylinder : "zCylinder",
  zAxisZoomLevel: 15,
  locateCounter: 0,
  active: 'inProgress',
  waiting: 'queued',
  ended: 'ended',
  release: 'released',
  transfer: 'transferred'
}

export const featureLabels = {
  'person': 'FC_PERSON',
  'z_axis' : 'FC_ZAXIS',
}