import {
  NOTIFICATION_CLIENT,
} from '../actions/actionTypes';

const notificationClientData = { notificationClient: null };
export const notificationsReducer = (state = notificationClientData, action) => {
  switch (action.type) {
    case NOTIFICATION_CLIENT:
      return {
        ...state,
        notificationClient: action.object,
      }
    default:
      return state;
  }
}