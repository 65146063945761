import React, { useEffect, useState } from 'react';
import { BsChevronRight, BsChevronDown } from "react-icons/bs";
import RapidSOSIcon from '../../assets/rapid.svg';
import RapidSOSLogo from '../../assets/RapidSOS-logo.png';
import { isEmpty, isFeatureAllowed } from '../../utils/CommonUtils';
import { callConfigs, featureLabels } from '../../constants/Constants';
import { TbRoute } from "react-icons/tb";
import AgencyIcon from '../../assets/agency.png';

function RapidSOSView(props) {
    const [showDropdown, setDropdown] = useState(false);
    const [showUberList, setUberList] = useState(false);
    const [showSiriusXMList, setSiriusXMList] = useState(false);
    const [showVehicleInfo, setVehicalInfo] = useState(false);
    const [showImpactInfo, setImpactInfo] = useState(false);
    const [showAirBagsInfo, setAirBagsInfo] = useState(false);
    const [showSeatsInfo, setSeatsInfo] = useState(false);
    const [showContactInfo, setContactInfo] = useState(false);
    const [showAgencyInfo, setAgencyInfo] = useState(false);
    const [showRoutesInfo, setRoutesInfo] = useState(false);

    useEffect(() => {
        if (rapidSOSInfoCheck()) {
            setDropdown(true);
        }
    }, [props.rapidSOSInfo]);

    const rapidSOSInfoCheck = () => {
        if (props.rapidSOSInfo && ((props.rapidSOSInfo.geometry &&
            props.rapidSOSInfo.geometry.coordinates &&
            props.rapidSOSInfo.geometry.coordinates.length) ||
            props.rapidSOSInfo.altitude || props.rapidSOSInfo.radius ||
            props.rapidSOSInfo.zuncer)) {
            return true;
        }
        return false;
    }

    const handleRapidLocation = () => {
        setDropdown(showDropdown => !showDropdown);
    }

    const handleUber = () => {
        setUberList(showUberList => !showUberList);
    }

    const handleSiriusXM = () => {
        setSiriusXMList(showSiriusXMList => !showSiriusXMList);
    }

    const handleVehicleInfo = () => {
        setVehicalInfo(showVehicleInfo => !showVehicleInfo);
    }

    const handleImpactInfo = () => {
        setImpactInfo(showImpactInfo => !showImpactInfo);
    }

    const handleAirbags = () => {
        setAirBagsInfo(showAirBagsInfo => !showAirBagsInfo);
    }

    const handleSeats = () => {
        setSeatsInfo(showSeatsInfo => !showSeatsInfo);
    }

    const handleContactInfo = () => {
        setContactInfo(showContactInfo => !showContactInfo);
    }

    const handleAgency = () => {
        setAgencyInfo(showAgencyInfo => !showAgencyInfo);
    }

    const handleRoutes = () => {
        setRoutesInfo(showRoutesInfo => !showRoutesInfo);
    }

    return (
        <>
            {props.showRapidSOS && props.rapidSOSInfo && ((props.rapidSOSInfo.geometry && props.rapidSOSInfo.geometry.coordinates && props.rapidSOSInfo.geometry.coordinates.length) || props.rapidSOSInfo.altitude || props.rapidSOSInfo.radius || props.rapidSOSInfo.zuncer || props.address) ?
                <div className='history-list'>
                    <div className='title'>
                        <div className={`hand-cursor d-flex align-items-center justify-content-between ${showDropdown ? 'active' : ''}`}
                            onClick={handleRapidLocation}>
                            <span><img className='rapisos-logo ml-1' src={RapidSOSIcon} /> RapidSOS Location</span>
                            {showDropdown ?
                                <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                :
                                <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                            }
                        </div>
                    </div>
                    {showDropdown ?
                        <div className='border-bottom pb-3'>
                            <div className='address-details'>
                                {props.address && props.address.location ? <div className='mb-3'>
                                    <div className='label'>Alternate Location:</div>
                                    <div>{props.address.location.house_number} {props.address.location.street}</div>
                                    <div>{props.address.location.city}{isEmpty(props.address.location.city) && isEmpty(props.address.location.state_code || props.address.location.postal) ? ', ' : ''}
                                        {props.address.location.state_code}{isEmpty(props.address.location.state_code && props.address.location.postal) ? ', ' : ''}
                                        {props.address.location.postal}</div>
                                </div> : ''}
                                <div className='d-flex align-items-center justify-content-between'>
                                    {props.rapidSOSInfo && props.rapidSOSInfo.geometry && props.rapidSOSInfo.geometry.coordinates && props.rapidSOSInfo.geometry.coordinates.length ?
                                        <span className='coordinates'>[{parseFloat(props.rapidSOSInfo.geometry.coordinates[props.rapidSOSInfo.geometry.coordinates.length - 1][1]).toFixed(6)}, {parseFloat(props.rapidSOSInfo.geometry.coordinates[props.rapidSOSInfo.geometry.coordinates.length - 1][0]).toFixed(6)}]</span>
                                        : ''}
                                    {props.rapidSOSInfo && props.rapidSOSInfo.radius ?
                                        <span className='radius'> UNC: {props.rapidSOSInfo.radius ? parseFloat(props.rapidSOSInfo.radius * callConfigs.meterToFeet).toFixed() + ' ft' : ""}</span> : ""}
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    {props.rapidSOSInfo && props.rapidSOSInfo.altitude && props.contourLineProperties && isFeatureAllowed(featureLabels.z_axis) ?
                                        <span className='radius'> Height: {parseFloat(props.rapidSOSInfo.altitude * callConfigs.meterToFeet - props.contourLineProperties.feature.properties.topo_elev - (props.contourLineProperties.feature.properties.H ? props.contourLineProperties.feature.properties.H : 0)).toFixed() + ' ft'}</span>
                                        : <span className='radius'> Altitude: {(parseFloat(props.rapidSOSInfo.altitude * callConfigs.meterToFeet).toFixed()) + ' ft'}</span>}
                                    {props.rapidSOSInfo && props.rapidSOSInfo.zuncer ?
                                        <span className='radius'> Zuncer: {props.rapidSOSInfo.zuncer ? parseFloat(props.rapidSOSInfo.zuncer * callConfigs.meterToFeet).toFixed() + ' ft' : ""}</span> : ""}
                                </div>
                            </div>
                        </div>
                        : ''
                    }
                </div>
                : ''}
            {props.callData && props.callData.agents && props.callData.agents.length ?
                <div className='history-list'>
                    <div className='title'>
                        <div className={`hand-cursor d-flex align-items-center justify-content-between ${showAgencyInfo ? 'active' : ''}`}
                            onClick={handleAgency}>
                            <span ><img className='agency-icon' src={AgencyIcon} /> Agency Info</span>
                            {showAgencyInfo ?
                                <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                :
                                <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                            }
                        </div>
                    </div>
                    {showAgencyInfo ?
                        <div className='border-bottom pb-3'>
                            <div className='address-details'><span className="label">Agent(s): </span> {props.callData.agents[0].agent}</div>
                        </div>
                        : ''}
                </div>
                : ''}
            {props.callData && props.callData.routesList && props.callData.routesList.length ?
                <div className='history-list'>
                    <div className='title'>
                        <div className={`hand-cursor d-flex align-items-center justify-content-between ${showRoutesInfo ? 'active' : ''}`}
                            onClick={handleRoutes}>
                            <span><span className='route-icon'><TbRoute/></span> Routing Info</span>
                            {showRoutesInfo ?
                                <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                :
                                <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                            }
                        </div>
                    </div>
                    {showRoutesInfo ?
                        <div className='border-bottom pb-3'>
                            <div className='address-details'>
                                <div>Routing Policies: </div>
                                {props.callData.routesList.map((route, i) => {
                                    return (
                                        <div key={route.rule} className='d-flex justify-content-between'>
                                            <div><span className="label">Route:</span> {route.rule}</div> <div><span className="label">Reason:</span> {route.reason}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        : ''}
                </div>
                : ''}
            {props.showRapidSOS && props.rapidSOSInfo && props.rapidSOSInfo.uber && props.rapidSOSInfo.uber.panic_button ?
                <div className='history-list'>
                    <div className='title'>
                        <div className={`hand-cursor d-flex align-items-center justify-content-between ${showUberList ? 'active' : ''}`}
                            onClick={handleUber}>
                            <span ><img className='rapisos-logo ml-1' src={RapidSOSLogo} /> Uber</span>
                            {showUberList ?
                                <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                :
                                <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                            }
                        </div>
                    </div>
                    {showUberList ?
                        <div className='border-bottom pb-3'>
                            <div className='address-details'>
                                <div><span className='label'>Driver Name:</span> {props.rapidSOSInfo.uber.panic_button.user_info ? props.rapidSOSInfo.uber.panic_button.user_info.user_name : ''}</div>
                                <div><span className='label'>Contact No:</span> {props.rapidSOSInfo.uber.panic_button.user_info ? props.rapidSOSInfo.uber.panic_button.user_info.user_phone : ''}</div>
                                <div><span className='label'>License Plate:</span> {props.rapidSOSInfo.uber.panic_button.trip_info ? props.rapidSOSInfo.uber.panic_button.trip_info.license_plate : ''}</div>
                                <div><span className='label'>License Plate State:</span> {props.rapidSOSInfo.uber.panic_button.trip_info ? props.rapidSOSInfo.uber.panic_button.trip_info.license_plate_state : ''}</div>
                                <div><span className='label'>Vehicle Maker:</span> {props.rapidSOSInfo.uber.panic_button.trip_info ? props.rapidSOSInfo.uber.panic_button.trip_info.vehicle_make : ''}</div>
                                <div><span className='label'>Vehicle Model:</span> {props.rapidSOSInfo.uber.panic_button.trip_info ? props.rapidSOSInfo.uber.panic_button.trip_info.vehicle_model : ''}</div>
                                <div><span className='label'>Vehicle Color:</span> {props.rapidSOSInfo.uber.panic_button.trip_info ? props.rapidSOSInfo.uber.panic_button.trip_info.vehicle_color : ''}</div>
                            </div>
                        </div>
                        : ''
                    }
                </div>
                : ''}
            {props.showRapidSOS && props.rapidSOSInfo && props.rapidSOSInfo.sxm ?
                <div className='history-list'>
                    <div className='title'>
                        <div className={`hand-cursor d-flex align-items-center justify-content-between ${showSiriusXMList ? 'active' : ''}`}
                            onClick={handleSiriusXM}>
                            <span ><img className='rapisos-logo ml-1' src={RapidSOSLogo} /> SiriusXM</span>
                            {showSiriusXMList ?
                                <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                :
                                <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                            }
                        </div>
                        {showSiriusXMList ?
                            <div className='border-top mt-3'>
                                <div className={`hand-cursor d-flex align-items-center justify-content-between rapid-list ${showVehicleInfo ? 'active' : ''}`}
                                    onClick={handleVehicleInfo}>Vehicle Info
                                    {showVehicleInfo ?
                                        <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                        :
                                        <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                    }
                                </div>
                                {props.rapidSOSInfo && props.rapidSOSInfo.sxm && props.rapidSOSInfo.sxm.crash_data && showVehicleInfo ?
                                    <div className='pb-3 ml-3'>
                                        <div className='address-details p-0'>
                                            {props.rapidSOSInfo.sxm.crash_data.vehicle ?
                                                Object.keys(props.rapidSOSInfo.sxm.crash_data.vehicle).map(keyName => {
                                                    return (
                                                        <div key={keyName}><span className={`label ${(keyName === 'vin' || keyName === 'oem') ? 'text-uppercase' : 'text-capitalize'}`}>{keyName.includes('_') ? keyName.replaceAll('_', ' ') : keyName}:</span> {props.rapidSOSInfo.sxm.crash_data.vehicle[keyName]}</div>
                                                    )
                                                })

                                                : ''}
                                        </div>
                                    </div>
                                    : ''
                                }
                                <div className={`hand-cursor d-flex align-items-center justify-content-between rapid-list ${showImpactInfo ? 'active' : ''}`}
                                    onClick={handleImpactInfo}>Impact
                                    {showImpactInfo ?
                                        <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                        :
                                        <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                    }
                                </div>
                                {props.rapidSOSInfo && props.rapidSOSInfo.sxm && props.rapidSOSInfo.sxm.crash_data && showImpactInfo ?
                                    <div className='pb-3 ml-3'>
                                        <div className='address-details p-0'>
                                            {props.rapidSOSInfo.sxm.crash_data.impacts ?
                                                Object.keys(props.rapidSOSInfo.sxm.crash_data.impacts).map(keyName => {
                                                    return (
                                                        <div key={keyName}><span className='label text-capitalize'>{keyName.includes('_') ? keyName.replaceAll('_', ' ') : keyName}:</span> {props.rapidSOSInfo.sxm.crash_data.impacts[keyName]}</div>
                                                    )
                                                })

                                                : ''}
                                        </div>
                                    </div>
                                    : ''
                                }
                                <div className={`hand-cursor d-flex align-items-center justify-content-between rapid-list ${showAirBagsInfo ? 'active' : ''}`}
                                    onClick={handleAirbags}>Airbags
                                    {showAirBagsInfo ?
                                        <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                        :
                                        <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                    }
                                </div>
                                {props.rapidSOSInfo && props.rapidSOSInfo.sxm && props.rapidSOSInfo.sxm.crash_data && showAirBagsInfo ?
                                    <div className='pb-3 ml-3'>
                                        <div className='address-details p-0'>
                                            {props.rapidSOSInfo.sxm.crash_data.airbags.length && props.rapidSOSInfo.sxm.crash_data.airbags.map((list, ind) => {
                                                return (
                                                    <div key={ind} className='child-list'>
                                                        {Object.keys(list).map(keyName => {
                                                            return (
                                                                <div key={keyName}><span className='label text-capitalize'>{keyName.includes('_') ? keyName.replaceAll('_', ' ') : keyName}:</span> {list[keyName]}</div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : ''
                                }
                                <div className={`hand-cursor d-flex align-items-center justify-content-between rapid-list ${showSeatsInfo ? 'active' : ''}`}
                                    onClick={handleSeats}>Seats
                                    {showSeatsInfo ?
                                        <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                        :
                                        <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                    }
                                </div>

                                {props.rapidSOSInfo && props.rapidSOSInfo.sxm && props.rapidSOSInfo.sxm.crash_data && showSeatsInfo ?
                                    <div className='pb-3 ml-3'>
                                        <div className='address-details p-0'>
                                            {props.rapidSOSInfo.sxm.crash_data.seats.length && props.rapidSOSInfo.sxm.crash_data.seats.map((list, ind) => {
                                                return (
                                                    <div key={ind} className='child-list'>
                                                        {Object.keys(list).map(keyName => {
                                                            return (
                                                                <div key={keyName}><span className='label text-capitalize'>{keyName.includes('_') ? keyName.replaceAll('_', ' ') : keyName}:</span> {list[keyName]}</div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : ''
                                }
                                <div className={`hand-cursor d-flex align-items-center justify-content-between rapid-list ${showContactInfo ? 'active' : ''}`}
                                    onClick={handleContactInfo}>Contacts
                                    {showContactInfo ?
                                        <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                        :
                                        <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                    }
                                </div>

                                {props.rapidSOSInfo && props.rapidSOSInfo.sxm && props.rapidSOSInfo.sxm.crash_data && showContactInfo ?
                                    <div className='pb-3 ml-3'>
                                        <div className='address-details p-0'>
                                            {props.rapidSOSInfo.sxm.crash_data.contacts.length && props.rapidSOSInfo.sxm.crash_data.contacts.map((list, ind) => {
                                                return (
                                                    <div key={ind} className='child-list'>
                                                        {Object.keys(list).map(keyName => {
                                                            return (
                                                                <div key={keyName}><span className='label text-capitalize'>{keyName.includes('_') ? keyName.replaceAll('_', ' ') : keyName}:</span> {list[keyName]}</div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : ''
                                }
                            </div>
                            : ''
                        }
                    </div>
                </div>
                : ''}
        </>
    )
}

export default RapidSOSView;