import React from "react";
import icon_t_sidebar_released from '../../assets/solacom/icon_t_sidebar_released.svg';
import icon_m_sidebar_released from '../../assets/solacom/icon_m_sidebar_released.svg';
import icon_l_sidebar_released from '../../assets/solacom/icon_l_sidebar_released.svg';
import icon_v_sidebar_released from '../../assets/solacom/icon_v_sidebar_released.svg';
import icon_te_sidebar_released from '../../assets/solacom/icon_te_sidebar_released.svg';
import icon_p_sidebar_released from '../../assets/solacom/icon_p_sidebar_released.svg';
import icon_o_sidebar_released from '../../assets/solacom/icon_o_sidebar_released.svg';
import sidebar_st_t_tout from '../../assets/solacom/sidebar_st_t_tout.svg';
import sidebar_st_m_tout from '../../assets/solacom/sidebar_st_m_tout.svg';
import sidebar_st_l_tout from '../../assets/solacom/sidebar_st_l_tout.svg';
import sidebar_st_v_tout from '../../assets/solacom/sidebar_st_v_tout.svg';
import sidebar_st_te_tout from '../../assets/solacom/sidebar_st_te_tout.svg';
import sidebar_st_pp_tout from '../../assets/solacom/sidebar_st_pp_tout.svg';
import sidebar_st_o_tout from '../../assets/solacom/sidebar_st_o_tout.svg';
import sidebar_st_t from '../../assets/solacom/sidebar_st_t.svg';
import sidebar_st_m from '../../assets/solacom/sidebar_st_m.svg';
import sidebar_st_l from '../../assets/solacom/sidebar_st_l.svg';
import sidebar_st_v from '../../assets/solacom/sidebar_st_v.svg';
import sidebar_st_te from '../../assets/solacom/sidebar_st_te.svg';
import sidebar_st_pp from '../../assets/solacom/sidebar_st_pp.svg';
import sidebar_st_o from '../../assets/solacom/sidebar_st_o.svg';

const solacomMarkerImages = {
    'SolacomCallIcons':{
        'WPH1': sidebar_st_t,
        'WPH2': sidebar_st_m,
        'RESD': sidebar_st_l,
        'BUSN': sidebar_st_l,
        'VOIP': sidebar_st_v,
        'TELE': sidebar_st_te,
        'CENT': sidebar_st_l,
        'COIN': sidebar_st_pp,
        'WRLS': sidebar_st_o,
        'UNK': sidebar_st_o,
        'default': sidebar_st_o
      },
    'releasedCallIcons':{
        'WPH1': icon_t_sidebar_released,
        'WPH2': icon_m_sidebar_released,
        'RESD': icon_l_sidebar_released,
        'BUSN': icon_l_sidebar_released,
        'VOIP': icon_v_sidebar_released,
        'TELE': icon_te_sidebar_released,
        'CENT': icon_l_sidebar_released,
        'COIN': icon_p_sidebar_released,
        'WRLS': icon_o_sidebar_released,
        'UNK': icon_o_sidebar_released,
        'default': icon_o_sidebar_released
    },
    'transferOut':{
        'WPH1': sidebar_st_t_tout,
        'WPH2': sidebar_st_m_tout,
        'RESD': sidebar_st_l_tout,
        'BUSN': sidebar_st_l_tout,
        'VOIP': sidebar_st_v_tout,
        'TELE': sidebar_st_te_tout,
        'CENT': sidebar_st_l_tout,
        'COIN': sidebar_st_pp_tout,
        'WRLS': sidebar_st_o_tout,
        'UNK': sidebar_st_o_tout,
        'default': sidebar_st_o_tout
      },
}

function SolacomIcons(props) {

    return (
        <>
            {props.callData.eventType === 'Released' ?
                props.callData.eventType === 'Released' && solacomMarkerImages.releasedCallIcons[props.callData.classOfService] ?
                    <img src={solacomMarkerImages.releasedCallIcons[props.callData.classOfService]} className='call-icon' />
                    : <img src={solacomMarkerImages.releasedCallIcons['default']} className='call-icon' />
                :
                props.callData.callStateExt === 'transferred' ?
                    props.callData.callStateExt === 'transferred' && solacomMarkerImages.transferOut[props.callData.classOfService] ?
                        <img src={solacomMarkerImages.transferOut[props.callData.classOfService]} className='call-icon' />
                        : <img src={solacomMarkerImages.transferOut['default']} className='call-icon' />
                    : solacomMarkerImages.SolacomCallIcons[props.callData.classOfService] ?
                        <img src={solacomMarkerImages.SolacomCallIcons[props.callData.classOfService]} className='call-icon' />
                        : <img src={solacomMarkerImages.SolacomCallIcons['default']} className='call-icon' />}
        </>
    )
}

export default SolacomIcons;