/* header actions */
export const FETCH_SPLIT_SCREEN = 'FTECH_SPLIT_SCREEN';
export const FETCH_MAP_THEME_REQUEST = 'FTECH_MAP_THEME_REQUEST';
export const FETCH_MAP_THEME_SUCCESS = 'FTECH_MAP_THEME_SUCCESS';
export const FETCH_MAP_THEME_FAIL = 'FTECH_MAP_THEME_FAIL';
export const FETCH_MAP_THEME_CANCEL = 'FTECH_MAP_THEME_CANCEL';
export const FETCH_MAP_THEME_URL = 'FETCH_MAP_THEME_URL';
export const FETCH_RENDER_THEME = 'FETCH_RENDER_Theme';
export const FETCH_RAPID_SOS = 'FETCH_RAPID_SOS';
export const FETCH_UNCERTAINITY = 'FETCH_UNCERTAINITY';
// export const FETCH_RENDER_THEME = 'FETCH_RENDER_Theme';
export const SET_TOOLBAR = 'SET_TOOLBAR';
export const SET_MAP = 'SET_MAP';

/* calls actions */
export const FETCH_CALL_DATA_SUCCESS = 'FETCH_CALL_DATA_SUCCESS';
export const FETCH_CALL_DATA_FAIL = 'FETCH_CALL_DATA_FAIL';
export const IDENTITY_SEARCH_SUCCESS= 'IDENTITY_SEARCH_SUCCESS';
export const IDENTITY_SEARCH_FAIL = 'IDENTITY_SEARCH_FAIL';
export const CRIMINAL_SEARCH_SUCCESS = 'CRIMINAL_SEARCH_SUCCESS';
export const CRIMINAL_SEARCH_FAIL = 'CRIMINAL_SEARCH_FAIL';
export const REVERSE_GEOCODE_SUCCESS = 'REVERSE_GEOCODE_SUCCESS';
export const FETCH_SOLACOM_CALL_EVENTS = 'FETCH_SOLACOM_CALL_EVENTS';

/* notification actions */
export const NOTIFICATION_CLIENT = 'NOTIFICATION_CLIENT';

export const SET_SOCKET_DATA = 'SET_SOCKET_DATA';
export const TRANSCRIPT_STATUS = 'TRANSCRIPT_STATUS'
