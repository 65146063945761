import { useEffect, useContext, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Alert from "react-bootstrap/Alert";
import Spinner from 'react-bootstrap/Spinner';
import OktaLogin from "../presentation/login/okta/OktaLogin";
import { getLoginToken, setStationIdInLocalStorage, getEndCallTimeFromLocalStorage, setEndCallTimeInLocalStorage, findDefaultRoute } from "../utilities/Utils";
import { storeRegionInfo } from "../utilities/LoginUtils";
import { errorMessages, apiAuthParams, featureLabels, appName } from "../constants/Constants";
import { ROUTE_ENDPOINTS } from "../constants/RouteConstants";
import { STORAGE_KEYS } from "../constants/SessionConstants";
import { primaryRegionData, secondaryRegionData, oktaUrls } from '../oktaexport-client';
import { AuthContext } from '../context/AuthContext';
import { getOktaUserAPIKey, updateGroupProfile, updateUserProfile, getUserApplications } from '../services/OktaServices';
import { oktaSignOut } from "../presentation/login/okta/OktaLoginUtils";
import _ from 'lodash';
import "../styles/auth.scss";
import srLogo from "../assets/sr_logo.png";

function OktaLoginPage(props) {
  const navigate = useNavigate();
  const { authClient, updateauthClient } = useContext(AuthContext);
  const [alertMsg, setAlertMsgContent] = useState(null);
  const [showSignInForm, setShowSignInForm] = useState(true);
  const isLoggedIn = getLoginToken();
  let isLoginCallback = false;
  let apiKey = null;
  let userInfo = null;
  let oktaUserId = null;

  const sessionGroupProfile = sessionStorage.getItem(STORAGE_KEYS.GROUP_PROFILE);
  const [showStationList, setShowStationList] = useState(false);
  const [selectedStation, setStation] = useState(false);
  const [stationList, setStationList] = useState(
    (sessionGroupProfile !== null && sessionGroupProfile !== 'null')
      ? JSON.parse(sessionGroupProfile).stationID
      : []
  );

  useEffect(() => {
    const issParam = props.issParam;
    console.log("iss:: ", issParam);
    isLoginCallback = props.isLoginCallback;
    if (authClient && (issParam || isLoginCallback)) {
      setShowSignInForm(false);
      if (issParam) findExistingSession();
      if (isLoginCallback) handleLoginCallback();
    }
    if (localStorage.getItem('showSignInErrMsg') == 'true') {
      setAlertMsgContent(localStorage.getItem('signInErrMsg'));
      localStorage.removeItem('signInErrMsg');
      localStorage.removeItem('showSignInErrMsg');
    }
  }, [])

  const findExistingSession = () => {
    authClient.token.getWithRedirect({
      responseType: ['token', 'id_token'],
    })
      .catch((err) => {
        // console.log('check session error:', err);
        setShowSignInForm(true);
      });
  }

  const handleLoginCallback = () => {
    authClient.token.parseFromUrl()
      .then((res) => {
        if (res && res.tokens) handleLoginSuccess(res.tokens)
        else setShowSignInForm(true);
      })
      .catch((err) => {
        // console.log('login callback error:', err);
        setShowSignInForm(true);
      });
  }

  const handleLoginSuccess = (tokens) => {
    setShowSignInForm(false);
    storeRegionInfo(primaryRegionData, secondaryRegionData);
    setTokenAndUserInfo(tokens);
  }

  const handleLoginFailure = () => {
    sessionStorage.clear();
    localStorage.setItem('showSignInErrMsg', true);
    localStorage.setItem('signInErrMsg', errorMessages.loginFailedError);
    navigate(ROUTE_ENDPOINTS.LOGIN)
  }

  const setTokenAndUserInfo = async (tokens) => {
    try {
      authClient.tokenManager.add("idToken", tokens.idToken);
      authClient.tokenManager.add("accessToken", tokens.accessToken);
      oktaUserId = tokens.accessToken.claims.uid;

      authClient.token.getUserInfo(tokens.accessToken, tokens.idToken)
        .then(async (userinfo) => {
          console.log('userinfo:', userinfo);
          let userApiKey = userinfo.api_key || null;
          let groupUpdated = userinfo.updated;

          if (!userApiKey) {
            const modifiedGroupNames = modifyGroupNames(userinfo.groups);
            const userGroup = userGroupName(modifiedGroupNames);
            await getAPIKey(userinfo.email, [userGroup]);
            userinfo.api_key = userApiKey = apiKey;
          }

          if (groupUpdated === true) {
            const groupToUpdate = userGroupName(userinfo.groups);
            console.log('groupToUpdate: ', groupToUpdate);
            await updateGroupProfileData(groupToUpdate);
          }

          if (userApiKey) handleUserInfoResponse(userinfo)
          else handleLoginFailure();
        })
        .catch((err) => {
          console.log('fetch userinfo - failed');
          handleLoginFailure();
        });
    } catch (exception) {
      console.log('::::::::: getWithoutPrompt Exception :::::::::', exception.message);
      if (exception.message && exception.message.includes('User is not assigned to the client application')) {
        handleLoginFailure(errorMessages.noAccessToApplication);
      }
    }
  }

  const handleUserInfoResponse = async (userinfo) => {
    const modifiedGroups = modifyGroupNames(userinfo.groups);
    userinfo.groups = modifiedGroups;
    userInfo = userinfo;
    const groupProfile = {
      name: userinfo.groups[0],
      gis_id: userinfo.gis_id,
      stationID: userinfo.stationID || [],
      map: {
        center: userinfo.center,
        zoom: userinfo.zoom,
      },
      endCallTimer: userinfo.endCallTimer || 0,
      features: userinfo.features || [],
      situcont: userinfo.situcont || [],
      secondMap: userinfo.secondMap,
      avl_vendor: [userinfo.avl_vendor],
      call_vendor: userInfo.call_vendor,
      peers: userinfo.peers || [],
    }

    sessionStorage.setItem(STORAGE_KEYS.GROUP_PROFILE, JSON.stringify(groupProfile));
    sessionStorage.setItem(STORAGE_KEYS.SHOW_NOTIFICATIONS, true);
    if (groupProfile != null) {
      await getUserApps();
      storeUserInfo(userinfo)
    } else {
      // TODO - Show toast saying groupfropile not found to this user
    }
  }

  const storeUserInfo = (userInfo) => {
    console.log('storeUserInfo : ', userInfo);
    const currentUser = userInfo.email;
    sessionStorage.setItem(STORAGE_KEYS.CURRENT_USER, currentUser);
    sessionStorage.setItem(STORAGE_KEYS.USER, currentUser.split('@')[0]);
    sessionStorage.setItem(STORAGE_KEYS.API_KEY, userInfo.api_key);
    sessionStorage.setItem(STORAGE_KEYS.USER_INFO, JSON.stringify(userInfo));
    const groupFeatures = sessionStorage.getItem(STORAGE_KEYS.GROUP_PROFILE)
    setStationList(groupFeatures ? JSON.parse(groupFeatures).stationID : []);
    var endCall = getEndCallTimeFromLocalStorage();
    if (endCall === null || endCall === '') {
      setEndCallTimeInLocalStorage(userInfo.email, JSON.parse(groupFeatures).endCallTimer);
    }
    let groups = userInfo.groups;
    if (groups) {
      const groupName = userGroupName(groups);
      sessionStorage.setItem(STORAGE_KEYS.GROUP_NAME, groupName);
    }
    navigateToNextScreen(userInfo)
  }

  const navigateToNextScreen = (userInfo) => {
    const groupFeatures = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.GROUP_PROFILE))
    if (groupFeatures.features.includes(featureLabels.call)) {
      if (groupFeatures.stationID && groupFeatures.stationID.length > 0 && Array.isArray(groupFeatures.stationID)) {
        if (localStorage.getItem(STORAGE_KEYS.STATION_ID_PREF) && _.find([...JSON.parse(localStorage.getItem(STORAGE_KEYS.STATION_ID_PREF))], ["userName", userInfo.email])) {
          navigate(findDefaultRoute());
        } else {
          setShowStationList(true)
        }
      } else {
        // TODO - Show toast saying stationID not found in groupfropile
      }
    }
  }

  const getAPIKey = async (username, groups) => {
    const tokenStorage = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
    const oktaAccessToken = tokenStorage.accessToken.accessToken;
    const oktaApplicationId = tokenStorage.idToken.clientId;
    await getOktaUserAPIKey(oktaApplicationId, oktaUserId, username, groups, oktaAccessToken)
      .then((response) => {
        console.log('api key response:', response.data);
        if (response && response.data && response.data.key)
          apiKey = response.data.key;
      })
      .catch((error) => {
        console.log('Generate api key - failed');
        handleLoginFailure();
        window.location.href = "/";
      });
  }

  const updateGroupProfileData = async (groupName) => {
    const tokenStorage = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
    const oktaAccessToken = tokenStorage.accessToken.accessToken;
    const oktaApplicationId = tokenStorage.idToken.clientId;
    await updateGroupProfile(oktaApplicationId, groupName, oktaAccessToken)
      .then((response) => {
        if (response.status == 200) console.log('Update group response:', response.data)
        else handleGroupUpdateFailure();
      })
      .catch((error) => {
        console.log(`Update ${groupName} group profile - failed`);
        handleGroupUpdateFailure();
      });
  }

  const modifyGroupNames = (groups) => groups.map(group => group.replace(/SR-/i, ''));

  const userGroupName = (groups) => {
    const excludeGroups = ['gis-manager', 'SR-gis-manager'];
    const filterGroups = groups.filter(group => !excludeGroups.includes(group));
    return filterGroups[0];
  }

  const handleGroupUpdateFailure = () => {
    sessionStorage.setItem('showGroupUpdateError', true);
  }

  const handleUserUpdateFailure = () => {
    sessionStorage.setItem('showUserUpdateError', true);
  }

  const denyAccessToApp = async () => {
    await oktaSignOut(authClient);
    localStorage.setItem('signInErrMsg', errorMessages.noAccessToApplication);
    localStorage.setItem('showSignInErrMsg', true);
  }


  const handleStation = (e) => {
    setStation(e.target.value)
  };

  const submitStation = (e) => {
    e.preventDefault();
    let currentUser = sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER);
    setStationIdInLocalStorage(currentUser, selectedStation);
    navigate(findDefaultRoute());
  }; 

  const getUserApps = async () => {
    const tokenStorage = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
    const oktaAccessToken = tokenStorage.accessToken.accessToken;
    const oktaAppId = oktaUrls.CLIENT_ID;
    await getUserApplications(oktaUserId, oktaAccessToken)
    .then((response) => {
      if (response.status == 200) {
        if (response.data && response.data.length) {
          let appsData = response.data;
          appsData.map(app => {
            app.isCurrentApp = (app.id === oktaAppId);
            return app;
          });
          sessionStorage.setItem(STORAGE_KEYS.USER_APPLICATIONS, JSON.stringify(appsData));
        } else {
          handleGetUserAppsFailure();
        }
      } else {
        handleGetUserAppsFailure();
      }
    })
    .catch((error) => {
      console.log(`Get apps assigned to user - failed`);
      handleGetUserAppsFailure();
    });
  }

  const handleGetUserAppsFailure = () => {
    const defaultAppData = [
      {
        id: oktaUrls.CLIENT_ID,
        label: oktaUrls.APP_NAME,
        url: '',
        isCurrentApp: true,
      }
    ]
    sessionStorage.setItem(STORAGE_KEYS.USER_APPLICATIONS, JSON.stringify(defaultAppData));
  }

  const loginComp = () => {
    if (showSignInForm === false) {
      return (
        <div className="loaderDiv">
          <Spinner animation="border" variant="primary">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      )
    }

    return (
      <>
        <div className="header">
          <div className="psap-header header-bar">
            <div className="navbar-header container">
              <div className="d-flex align-items-center">
                {/* <img src={srLogo} /> */}
                <span className="logo-text">{appName}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="main container">
        <div className="sign-in">
          <div className="row">
            <div className="col-md-8 col-sm-12 mt-10">
              {alertMsg ? (
                <Alert key="signInAlert" variant="danger">
                  {alertMsg}
                </Alert>
              ) : ''}
            </div>
            <div className="col-md-6 col-sm-12 mt-10">
              {showSignInForm ? (
                <OktaLogin
                  onLoginSuccess={handleLoginSuccess}
                  onLoginError={handleLoginFailure}
                />) : (<>
                  {showStationList ?
                    <div className="choose-station">
                      <div className="form-group position-relative">
                        <label className="mb-1">Choose MCW Position</label>
                        <select
                          className="form-control form-select"
                          onChange={handleStation}
                        >
                          <option hidden>Position ID</option>
                          {Array.isArray(stationList) &&
                            stationList.length
                            ? stationList.map((stationId) => {
                              return (
                                <option value={stationId} key={stationId}>
                                  {stationId}
                                </option>
                              );
                            })
                            : ""}
                        </select>
                      </div>
                      <div className="form-group">
                        <button
                          disabled={selectedStation ? false : true}
                          className="comtech-btn comtech-btn-primary btn-lg"
                          onClick={submitStation}
                          type="submit"
                        >
                          <span>Select</span>
                        </button>
                      </div>
                    </div> : (<div />)
                  }
                </>)}
            </div>
            <div className="col-md-6 hidden-sm-down"></div>
          </div>
          </div>
        </div>
      </>
    )
  }

  const stationsList = () => {
    return (
      <>
        <div className="header">
          <div className="psap-header header-bar">
            <div className="navbar-header container">
              <div className="d-flex align-items-center">
                <img src={srLogo} />
              </div>
            </div>
          </div>
        </div>
        <div className="main container">
        <div className="sign-in">
          <div className="row">
            <div className="col-md-6 col-sm-12 mt-10">
                {showStationList ?
                  <div className="choose-station">
                    <div className="form-group position-relative">
                      <label className="mb-1">Choose MCW Position</label>
                      <select
                        className="form-control form-select"
                        onChange={handleStation}
                      >
                        <option hidden>Position ID</option>
                        {Array.isArray(stationList) &&
                          stationList.length
                          ? stationList.map((stationId) => {
                            return (
                              <option value={stationId} key={stationId}>
                                {stationId}
                              </option>
                            );
                          })
                          : ""}
                      </select>
                    </div>
                    <div className="form-group">
                      <button
                        disabled={selectedStation ? false : true}
                        className="comtech-btn comtech-btn-primary btn-lg"
                        onClick={submitStation}
                        type="submit"
                      >
                        <span>Select</span>
                      </button>
                    </div>
                  </div> : (<div />)
                }
            </div>
            <div className="col-md-6 hidden-sm-down"></div>
          </div>
          </div>
        </div>
      </>
    )
  }

  const homeComp = () => {
    return (<Navigate to={{ pathname: ROUTE_ENDPOINTS.CALLS }} />)
  }

  return showStationList ? stationsList() : (isLoggedIn ? homeComp() : loginComp());
}

export default OktaLoginPage;
