import * as turfHelper from '@turf/helpers';
import booleanIntersects from '@turf/boolean-intersects';
import {STORAGE_KEYS} from '../constants/SessionConstants'

var turfPoint = require("turf-point");

export const  prepareClustersGeojson = (device) => {
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: device.geometry && device.geometry.coordinates && device.geometry.coordinates.length ? swipeCoordinates(device) : []
    },
    properties: getPropertiesObj(device)
  }
}
const swipeCoordinates = (obj) => {
  return [
    obj.geometry.coordinates[obj.geometry.coordinates.length - 1][1],
    obj.geometry.coordinates[obj.geometry.coordinates.length - 1][0]
  ]
}
const getPropertiesObj = (obj) => {
  return {
    trackId: obj.id,
    deviceType: obj.deviceType,
    vehicleType: obj.type,
    address: obj.address,
    ignition: obj.ignition,
    status: obj.status,
    label: obj.label,
    odometer: obj.odometer,
    recorded_time: obj.recorded_time,
    runtime_minutes: obj.runtime_minutes,
    speed: obj.speed,
    speed_limit: obj.speed_limit,
    verified_time: obj.verified_time,
    heading: obj.heading,
    speed_label: obj.speed_label,
    opacity: obj.opacity,
    agency: obj.agency,
  }
}

export const createDonutChart = (props, colors) => {
  const offsets = [];
  const counts = [
      props.activeStatus,
      props.availableStatus,
      props.unavailableStatus,
      props.lowStatus
  ];
  let total = 0;
  for (const count of counts) {
      offsets.push(total);
      total += count;
  }
  const fontSize =
      total >= 50 ? 12 : total >= 20 ? 11 : total >= 10 ? 11 : 10;
  const r =
      total >= 50 ? 32 : total >= 20 ? 24 : total >= 10 ? 18 : 14;
  const r0 = Math.round(r * 0.6);
  const w = r * 2;

  let html = `<div>
  <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle"; font: ${fontSize}px sans-serif;>`;

  for (let i = 0; i < counts.length; i++) {
      html += donutSegment(
          offsets[i] / total,
          (offsets[i] + counts[i]) / total,
          r,
          r0,
          colors[i]
      );
  }
  html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
  <text dominant-baseline="central" transform="translate(${r}, ${r})">
      ${total.toLocaleString()}
  </text>
  </svg>
  </div>`;

  var el = document.createElement('div');
  el.innerHTML = html;
  return el.firstChild;
}

const donutSegment = (start, end, r, r0, color) =>{
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0),
      y0 = Math.sin(a0);
  const x1 = Math.cos(a1),
      y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  // draw an SVG path
  return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
      } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
      } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
      }" fill="${color}" />`;
}

export const calculateUpdateMarkerBBbox = (list, map) => {
  let isInside = false;
  if (map && list && list.geometry.coordinates && list.geometry.coordinates.length) {
      // Get the map's current bounds
      const bounds = map.getBounds();
      // Convert the bounds to a Turf.js polygon feature
      const mapPolygonObj = turfHelper.polygon([[
          [bounds.getWest(), bounds.getSouth()],
          [bounds.getEast(), bounds.getSouth()],
          [bounds.getEast(), bounds.getNorth()],
          [bounds.getWest(), bounds.getNorth()],
          [bounds.getWest(), bounds.getSouth()]
      ]]);
      
      var point = turfPoint([list.geometry.coordinates[list.geometry.coordinates.length - 1][1], list.geometry.coordinates[list.geometry.coordinates.length - 1][0]]);
      isInside =  booleanIntersects(mapPolygonObj, point);
      // if (isInside) {
      //     consoleLog(':::: marker update within the bounding box');
      // } else {
      //     consoleLog(':::: marker update is not within the bounding box');
      // }
  }
  return isInside;
}

export const calculateHistoryMarkerBBbox = (list, map) => {
  let isInside = false;
  if (map && list && list.geometry.coordinates && list.geometry.coordinates.length) {
      // Get the map's current bounds
      const bounds = map.getBounds();
      // Convert the bounds to a Turf.js polygon feature
      const mapPolygonObj = turfHelper.polygon([[
          [bounds.getWest(), bounds.getSouth()],
          [bounds.getEast(), bounds.getSouth()],
          [bounds.getEast(), bounds.getNorth()],
          [bounds.getWest(), bounds.getNorth()],
          [bounds.getWest(), bounds.getSouth()]
      ]]);
      
      var point = turfPoint(list.geometry.coordinates);
      isInside =  booleanIntersects(mapPolygonObj, point);
      // if (isInside) {
      //     consoleLog(':::: history marker update within the bounding box');
      // } else {
      //     consoleLog(':::: history marker update is not within the bounding box');
      // }
  }
  return isInside;
}

export const  UpdatePrepareClustersGeojson = (device) => {
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: device && device.coordinates && device.coordinates.length ? swipeUpdateCoordinates(device) : []
    },
    properties: getPropertiesObj(device)
  }
} 

const swipeUpdateCoordinates = (obj) => {
  return [
    obj.coordinates[1],
    obj.coordinates[0]
  ]
}
export function isEmpty(str) {
  if (!str || str.trim().length === 0) {
    return false;
  }
  return true;
}
export function isFeatureAllowed(feature) {
  const groupProfile = getGroupProfile();
  if (!feature || !groupProfile || !groupProfile.features) {
    return false;
  }
  return groupProfile.features.includes(feature);
}
export function getGroupProfile() {
  return JSON.parse(sessionStorage.getItem(STORAGE_KEYS.GROUP_PROFILE));
}
   export const createGeoJSONCircle = (center, radiusInKm, points) => {
    if (!points) points = 64;
    const coords = {
        latitude: center[1],
        longitude: center[0]
    };
    const km = radiusInKm;
    const ret = [];
    const distanceX =
        km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    const distanceY = km / 110.574;
    let theta, x, y;
    for (var i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);
        ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);
    return {
        type: "geojson",
        data: {
            type: "FeatureCollection",
            features: [
                {
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [ret]
                    }
                }
            ]
        }
    };
}