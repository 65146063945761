import {FETCH_CALL_DATA_SUCCESS, FETCH_CALL_DATA_FAIL, SET_SOCKET_DATA, TRANSCRIPT_STATUS}  from '../actions/actionTypes';

const initialState = {
    error: '',
    isLoading: false,
}

export const CallsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CALL_DATA_SUCCESS:
            return { isLoading: action.isLoading, data: action.data, endCallsData: action.endCallsData, error: '' }
        case FETCH_CALL_DATA_FAIL:
        default:
            return state;
    }
}

const socketDataInitialState = { socketData: [] }
export const socketDataReducer = (state = socketDataInitialState, action) => {
    switch (action.type) {
        case SET_SOCKET_DATA:
            return { 
                ...state,
                socketData: state.socketData && state.socketData.findIndex((item)=> item.transactionId === action.data.transactionId) >= 0 ? [...state.socketData, action.data] : [action.data] 
            }
        default:
            return state;
    }
}

const transcriptStatus = '';
export const transcriptStatusReducer = (state = transcriptStatus, action) => {
    switch (action.type) {
        case TRANSCRIPT_STATUS:
            return {
                ...state,
                transcriptStatus: action.data
            }
        default:
            return state;
    }
}