import { FETCH_CALL_DATA_SUCCESS, FETCH_CALL_DATA_FAIL, REVERSE_GEOCODE_SUCCESS, FETCH_SOLACOM_CALL_EVENTS, SET_SOCKET_DATA, TRANSCRIPT_STATUS } from './actionTypes';
import { callConfigs, cancelErrorMessages, featureLabels } from '../../constants/Constants';
import { simulationCallApi, notificationCallApi } from '../../services/CallsServices';
import { STORAGE_KEYS } from '../../constants/SessionConstants';
import { getStationIdFromLocalStorage, isFeatureAllowed, SolacomGroup, swapCoordinates } from '../../utilities/Utils';
import { getIdentities } from './whoosterActions';
import SearchServices from 'sa-common/services/SearchServices';
import { toast } from "react-toastify";
import { configTimers } from '../../constants/ConfigTimers';

const customToastId = "custom-toast-id";
const SearchService = new SearchServices();
var newResponse = {};
var unplottedCalls = [], queuedCalls = [], answeredCalls = [];
var oldCallResponse = null;
var endCallsArray = [];

export const setCallsData = (payload) => {
    let callsResponse = payload;
    if(payload && payload.rapidSOS && payload.rapidSOS.geometry && payload.rapidSOS.geometry.coordinates && payload.rapidSOS.geometry.coordinates.length){
        let coords = payload.rapidSOS.geometry.coordinates;
        SearchService.getReverseGeocode(`${coords[coords.length - 1][1]},${coords[coords.length - 1][0]}`)
            .then(res => {
                if (res && res.data && res.data.places && res.data.places.length) {
                    callsResponse.rapisSOSAddress = res.data.places[0];
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
    if (!SolacomGroup()) {
        if (callsResponse && callsResponse.rapidSOS && callsResponse.rapidSOS.geometry && callsResponse.rapidSOS.geometry.coordinates.length) {
            callsResponse.rapidSOS.geometry.coordinates = swapCoordinates(callsResponse.rapidSOS.geometry.coordinates);
        }
        if (callsResponse && callsResponse.geometry && callsResponse.geometry.coordinates) {
            callsResponse.geometry.coordinates = swapCoordinates(callsResponse.geometry.coordinates);
        } else {
            callsResponse = "";
        }
    }

    var endCallTimer = 10
    if (payload != null && endCallTimer && endCallTimer != null && endCallTimer != '') {
        if(SolacomGroup()){
            if(callsResponse.eventType === 'EndCall'){
                callsResponse.disconnectTime = new Date().getTime();
                callsResponse.callState = callConfigs.ended;
                endCallsArray.unshift(callsResponse);
                callsResponse = '';
                endCallsArray = [...new Map(endCallsArray.map(item => [item['nenaIncidentId'], item])).values()];
            }
        }
        else{ if (didCallChanged(callsResponse, oldCallResponse)) {
            oldCallResponse.disconnectTime = new Date().getTime();
            oldCallResponse.callState = callConfigs.ended;
            endCallsArray.unshift(oldCallResponse)
        }
        endCallsArray = [...new Map(endCallsArray.map(item => [item['nenaIncidentId'], item])).values()];
        }
       const endList = filterEndCalls()
    } else if (payload === null) {
        console.log(":::::: payload is null ::::: ")
        endCallsArray = []
    }
    if (SolacomGroup()) {
        oldCallResponse = callsResponse
    }
    else {
        if (oldCallResponse && (oldCallResponse.nenaIncidentId === callsResponse.nenaIncidentId && callsResponse.callState === STORAGE_KEYS.END_STATUS)) {
            callsResponse = null
        } else {
            oldCallResponse = callsResponse
        }
    }
    return {
        type: FETCH_CALL_DATA_SUCCESS,
        data: callsResponse,
        endCallsData: endCallsArray,
        // invokeCameras: true,
        isLoading: false
    }
}

export function fetchCallsData(data) {
    return {
        type: FETCH_CALL_DATA_SUCCESS,
        data: data,
        endCallsData: endCallsArray,
        invokeCameras: true,
        isLoading: false
    }
}

export function fetchCallsDataFail(error) {
    return {
        type: FETCH_CALL_DATA_FAIL,
        data: error,
        isLoading: false
    }
}

export function fetchReverseGeocode(data) {
    newResponse.rapisSOSAddress = data;
    return {
        type: REVERSE_GEOCODE_SUCCESS,
        data: data,
        endCallsData: endCallsArray,
    }
}

function didCallChanged(newCallData, oldCallData) {
    // if old call data or new data is not available then consider it as first call.
    if(!oldCallData || oldCallData === null || !newCallData || newCallData === "") {
        return false
    } 
    if ((oldCallData.nenaIncidentId !== newCallData.nenaIncidentId && newCallData.callState !== callConfigs.ended) 
        || (oldCallData.nenaIncidentId === newCallData.nenaIncidentId && newCallData.callState === callConfigs.ended)) {
        return true
    }
}

export const getNotificationCallsData = () => {
    return function (dispatch) {
        notificationCallApi()
            .then(response => {
                if (response && response.data && response.status === 200) {
                    let callsResponse = (response.data);
                    callsResponse.cityRadius = response.data.radius;
                    callsResponse.radius = response.data.radius >= 0 ? response.data.radius : 0;
                    if (callsResponse.rapidSOS) {
                        callsResponse.rapidSOS.radius = callsResponse.rapidSOS.radius >= 0 ? callsResponse.rapidSOS.radius : 0
                    }
                    callsResponse.callState = "inProgress";
                    if (callsResponse.rapidSOS && callsResponse.rapidSOS.geometry && callsResponse.rapidSOS.geometry.coordinates &&
                        callsResponse.rapidSOS.geometry.coordinates.length) {
                        dispatch(reverseGeocode(callsResponse))
                    }
                    if (isFeatureAllowed(featureLabels.person) && callsResponse.ani && callsResponse.ani.length > 0) {
                        dispatch(getIdentities(callsResponse.ani));
                    }
                }
            })
            .catch(error => {
                console.log("error ::::: ", error)
                dispatch(fetchCallsDataFail(error.response.message));
            })
    }
}

export const getSimulationCallsData = () => {
    return function (dispatch) {
        simulationCallApi()
            .then(response => {
                if (response && response.data && response.status === 200) {
                    newResponse = {};
                    let geometry = {
                        "type": "LineString",
                        "coordinates": [[]]
                    };
                    newResponse.type = response.data.type;
                    newResponse.connectTime = new Date();
                    newResponse.address = response.data.properties.address;
                    newResponse.nenaIncidentId = '';
                    newResponse.ani = response.data.properties.MDN;
                    newResponse.callState = STORAGE_KEYS.ACTIVE_STATUS;
                    newResponse.active = true;
                    geometry.coordinates[0] = response.data.geometry.coordinates;
                    newResponse.geometry = geometry;
                    newResponse.radius = response.data.properties.Radius >= 0 ? response.data.properties.Radius : 0;
                    newResponse.cityRadius = response.data.properties.Radius;
                    newResponse.serviceProvider = response.data.properties.provider;
                    newResponse.classOfService = 'WPH2';
                    newResponse.rapidSOS = response.data.rapidSOS;
                    if (newResponse.rapidSOS && newResponse.rapidSOS.geometry && newResponse.rapidSOS.geometry.coordinates &&
                        newResponse.rapidSOS.geometry.coordinates.length) {
                        dispatch(reverseGeocode(newResponse))
                    }
                    if (response.data.rapidSOS) {
                        newResponse.rapidSOS.radius = newResponse.rapidSOS.radius >= 0 ? newResponse.rapidSOS.radius : 0;
                    }
                    newResponse.stationId = null;
                    if (isFeatureAllowed(featureLabels.person) && newResponse.ani && newResponse.ani.length > 0) {
                        dispatch(getIdentities(newResponse.ani));
                    }
                }
            })
            .catch(error => {
                dispatch(fetchCallsDataFail(error.response.message));
            })
    }
}

export const reverseGeocode = (newResponse) => {
    return function (dispatch) {
        let coords = newResponse.rapidSOS.geometry.coordinates;
        SearchService.getReverseGeocode(`${coords[coords.length - 1][1]},${coords[coords.length - 1][0]}`)
            .then(res => {
                if (res && res.data && res.data.places && res.data.places.length) {
                    dispatch(fetchReverseGeocode(res.data.places[0]))
                }
            })
            .catch(error => {
                if (error.code == configTimers.cancelStatusCode) {
                    toast.error(cancelErrorMessages.reverseGeocodeTimeOutMsg, { toastId: customToastId });
                } else {
                    toast.error(error.message, { toastId: customToastId });
                }
                newResponse.rapisSOSAddress = null;
            })
        dispatch(fetchCallsData(newResponse));
    }
}
export const  filterEndCalls = () => {
    var tempArray = [];    
    if (endCallsArray && endCallsArray.length) {
        endCallsArray.map(callData => {
            var difference = new Date().getTime() - callData.disconnectTime;
            var diffInMin = Math.round(difference / 1000);
            // if (diffInMin < getEndCallTimeFromLocalStorage()) {
            //     tempArray.push(callData);
            // }
            tempArray.push(callData);
        })
    }
    endCallsArray = tempArray;
    return endCallsArray
}


export const setSolacomCallsData = (payload) => {
    if (payload.eventType === 'StartCall') {
        unplottedCalls.push(payload);
    }
    if (payload.eventType === 'Position' || payload.eventType === 'Route') {
        queuedCalls.push(payload);
        if (queuedCalls.length) {
            queuedCalls.map(queued => {
                if (payload.eventType === 'Route') {
                    queuedCalls = [...new Map(queuedCalls.map(item => [item['nenaIncidentId'], item])).values()];
                }

                unplottedCalls = unplottedCalls.filter(el => {
                    return el.nenaIncidentId !== queued.nenaIncidentId;
                })
                if (payload.eventType === 'Position' && payload.geometry && payload.geometry.coordinates && payload.geometry.coordinates.length > 1) {
                    if (queued.nenaIncidentId === payload.nenaIncidentId) {
                        queued.geometry.coordinates = payload.geometry.coordinates;
                    }
                }
            })
        }
    }
    if (payload.eventType === 'Answer' || payload.eventType === 'TransferCall' || payload.eventType === 'Released' || (payload.eventType === 'Position' && payload.geometry && payload.geometry.coordinates && payload.geometry.coordinates.length > 1)) {
        queuedCalls = queuedCalls.filter(el => {
            return payload.nenaIncidentId !== el.nenaIncidentId;
        })
        if (payload.agents && payload.agents.length && getStationIdFromLocalStorage() !== payload.agents[0].agent) {
            answeredCalls.push(payload);
            if (answeredCalls.length) {
                if (payload.eventType === 'Answer') {
                    answeredCalls = [...new Map(answeredCalls.map(item => [item['nenaIncidentId'], item])).values()];
                }
                if (payload.eventType === 'TransferCall') {
                    answeredCalls = [...new Map(answeredCalls.map(item => [item['nenaIncidentId'], item])).values()];
                }
                if (payload.eventType === 'Released') {
                    answeredCalls = [...new Map(answeredCalls.map(item => [item['nenaIncidentId'], item])).values()];
                }
                answeredCalls.map(answer => {
                    if (payload.eventType === 'Position' && answer.nenaIncidentId === payload.nenaIncidentId) {
                        if (payload.geometry && payload.geometry.coordinates && payload.geometry.coordinates.length > 1) {
                            answer.geometry.coordinates = payload.geometry.coordinates;
                        }
                    }
                })
                answeredCalls = [...new Map(answeredCalls.map(item => [item['nenaIncidentId'], item])).values()];
            }
        }
    }
    if (payload.eventType === 'EndCall') {
        answeredCalls = answeredCalls.filter(el => {
            return payload.nenaIncidentId !== el.nenaIncidentId;
        })
    }
    return {
        type: FETCH_SOLACOM_CALL_EVENTS,
        queuedCalls: queuedCalls,
        unplottedCalls: unplottedCalls,
        answeredCalls: answeredCalls
    }
}

export function setSocketData(data) {
    return {
        type: SET_SOCKET_DATA,
        data: data,
    }
}

export function setTranscriptStatus(data) {
    return {
        type: TRANSCRIPT_STATUS,
        data: data,
    }
}