import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import './styles/map.scss';
import '../../node_modules/mapbox-gl/dist/mapbox-gl.css';
import './styles/geoservices-ctrl.css'
import './styles/doppler-control.css';
import comtechLogo from './assets/comtech-logo.svg'
import WeatherControl, { addRasterLayer, removeGifLayers, clearFrames } from './weather/WeatherControl';
import WeatherServices from '../services/WeatherServices';
import axios from 'axios';
import { configTimers } from '../constants/Timers'
import { serverUrls, groupLocations, splitterConfigs, callConfigs } from '../constants/Constants'
import {STORAGE_KEYS} from "../constants/SessionConstants"
import SearchAdd from '../search/SearchAdd'
import '../map/styles/markerPopup.scss'
import POI_Off from './assets/icon_poi_off.png';
import POI_On from './assets/icon_poi_on.png';
import { createGeoJSONCircle } from '../utils/CommonUtils';

const Map = (props) => {
    const mapContainer = useRef();
    const map = useRef(null);
    const secondMap = useRef(null);
    const secondMapContainer = useRef();
    const splitter = useRef(null)
    const splitterMap = useRef(null)

    let customControl;
    let cancelToken;
    let weatherService;
    const [search, setSearch] = useState('ADDRESS');
    const [showMap, setShowMap] = useState(false);
    const [state, setState] = useState({
        secondaryPaneSize: splitterConfigs.secondaryPaneSize, // Set initial values if needed
        dividerPosition: splitterConfigs.dividerPosition,
        primaryPaneSize: splitterConfigs.primaryPaneSize,
    });
    const [isSplitScreen, setSplitScreenEnable] = useState(props.secondaryMapRequired);
    const [isResizing, setIsResizing] = useState(false);
    const isResizingRef = useRef(isResizing);

    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: props.mapStyle,
            zoom: props.zoom ? props.zoom : groupLocations.default.zoom,
            hash: true,
            center: props.centerCoordinates ? props.centerCoordinates : groupLocations.default.center
        });
        map.current.addControl(new mapboxgl.ScaleControl({ unit: 'imperial' }), 'bottom-right');
        toggleShowCompass(true)
        toggleZoomButtons(true)
        map.current.once('styledata', onMaploaded);
        if (props.secondaryMapRequired) {
            secondMap.current = createSecondaryMap(props.secondMapStyle, props.centerCoordinates, props.secondaryMapZoom);
            secondMap.current.once('styledata', onSecondaryMapLoaded);
        }
        return () => {
            if (map.current) {
                map.current.remove();
            }
        };
    }, []);

    const createSecondaryMap = (style, centerCoordinates, secondaryMapZoom) => {
        let tempMap = new mapboxgl.Map({
            container: secondMapContainer.current,
            style: style,
            bearing: 0,
            pitch: 45,
            center: centerCoordinates ? centerCoordinates : groupLocations.default.center,
            zoom: secondaryMapZoom ? secondaryMapZoom : groupLocations.default.zoom
        });
        tempMap.addControl(new mapboxgl.ScaleControl({ unit: 'imperial' }), 'bottom-right');
        let options = { showCompass: true, showZoom: false };
        tempMap.addControl(new mapboxgl.NavigationControl(options), 'top-right');
        options = { showCompass: false, showZoom: true };
        tempMap.addControl(new mapboxgl.NavigationControl(options), 'bottom-right');
        return tempMap;
    }

    // Method to toggle zoom buttons
    const toggleZoomButtons = (showZoom) => {
        let options = { showCompass: false, showZoom: showZoom };
        map.current.addControl(new mapboxgl.NavigationControl(options), 'bottom-right');
    };

    // Method to toggle Compass buttons
    const toggleShowCompass = (enable) => {
        let options = { showCompass: enable, showZoom: false };
        map.current.addControl(new mapboxgl.NavigationControl(options), 'top-right');
    };

    // Method to toggle Compass buttons
    const setMapCenter = (newCenter) => {
        map.current.setCenter(newCenter);
    };

    const handleStyleSelection = (styleUrl) => {
        props.onMapLoadChange(false);
        map.current.once('style.load', onMaploaded);
        setTimeout(() => loadStyle(styleUrl), 100);
    }

    const loadStyle = (styleUrl) => {
        map.current.setStyle(styleUrl, { diff: false });
    }

    const onSecondaryMapLoaded = (e) => {
        handleLatLngPopup(secondMap.current, 'satellite-map-latlng');
        props.onSecondMapLoaded(secondMapContainer.current, secondMap.current)
    }

    const onMaploaded = (e) => {
        let layerNames = map.current.getStyle().layers;
        // Pass the map object to the parent component
        props.onMapComponentLoaded(mapContainer.current, map.current);
        props.onMapLoadChange(true);
        // Add the custom methods to the mapContainer instance to make it accessible from outside the component
        if (mapContainer.current) {
            Object.assign(mapContainer.current, {
                toggleZoomButtons: toggleZoomButtons,
                toggleShowCompass: toggleShowCompass,
                setMapCenter: setMapCenter,
                showWeatherLayers: showWeatherLayers,
                showTrafficLayers: showTrafficLayers,
                updateMapStyle: handleStyleSelection,
                getMapCenter: getMapCenter,
                createLngLatObj: createLngLatObj,
                drawMarker: drawMarker,
                drawIconMarker: drawIconMarker,
                calculateBoundsFromGeoJson: calculateBoundsFromGeoJson,
                createLngLatObjFrom: createLngLatObjFrom,
                jumpTo: jumpTo,
                enableSplitScreen: enableSplitScreen,
                drawDotedLine: drawDotedLine,
                removeLayer: removeLayer,
                addMarkerOnMap: addMarkerOnMap,
                mapProject: mapProject,
                handleLayerVisibility: handleLayerVisibility,
                addMarkerOnSecondMap: addMarkerOnSecondMap,
                addBreadCrumb: addBreadCrumb,
                addCircle: addCircle,
                mapProject: mapProject,
                getFeatures: getFeatures,
                getMapStyles: getMapStyles,
                getMapLayers: getMapLayers,
                removeMapSource: removeMapSource,
                uncertainityCylinder: uncertainityCylinder,
                zAxisCylinder: zAxisCylinder,
                getSetData: getSetData,
                drawPopup: drawPopup,
                setBounds: setBounds,
                resize: resize
            });
            setShowMap(true)
        }
        if (!document.getElementById('doppler-control'))
            customControl = new WeatherControl();

        weatherService = new WeatherServices();
        handleLatLngPopup(map.current, 'primary-map-latlng');
        removeGifLayers();
    }

    const handleLatLngPopup = (map, id) => {
        map.on('mousemove', (e) => {
            document.getElementById(id).style.display = 'block';
            document.getElementById(id).innerHTML = parseFloat(e.lngLat.lat).toFixed(6) + ', ' + parseFloat(e.lngLat.lng).toFixed(6);
        });
        map.on('mouseout', () => {
            document.getElementById(id).style.display = 'none';
        });
        map.on('touchmove', (e) => {
            document.getElementById(id).style.display = 'block';
            document.getElementById(id).innerHTML = parseFloat(e.lngLat.lat).toFixed(6) + ', ' + parseFloat(e.lngLat.lng).toFixed(6);
        });
        map.on('touchend', () => {
            document.getElementById(id).style.display = 'none';
        });
    }

    let weatherRetriesCount = 0
    const showWeatherLayers = (weather) => {
        if (weather) {
            //Check if there are any previous pending requests
            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel("Operation canceled due to new request.")
            }
            cancelToken = axios.CancelToken.source();
            let url = sessionStorage.getItem(STORAGE_KEYS.BASE_URL) + serverUrls.weatherUrl + `/doppler/1/0/0/radarFcst?ts=${Math.round(new Date().getTime() / 1000)}&api_key=` + sessionStorage.getItem(STORAGE_KEYS.API_KEY);
            weatherService.getForeCastTilePath(url, cancelToken).then(
                (response) => {
                    if (response.status == 200) {
                        (!document.getElementById("doppler-control")) ? map.current.addControl(customControl) : null;
                        addRasterLayer(Math.round(new Date().getTime() / 1000), 1, 0);
                    }
                }, error => {
                    if (error.code == configTimers.cancelStatusCode) {
                        weatherRetriesCount = weatherRetriesCount + 1;
                        if (weatherRetriesCount > 2) {
                            map.current.removeControl(customControl);
                            removeGifLayers();
                            weatherRetriesCount = 0
                        } else {
                            showRadarLayers(weather)
                        }
                    } else {
                        // handleError(error, errorMessages.suggestionError);
                    }
                }
            );
        } else {
            map.current.removeControl(customControl);
            removeGifLayers();
        }
    };

    const showTrafficLayers = (traffic) => {
        if (map.current && map.current != null) {
            var layers = map.current.getStyle().layers;
            if (layers) {
                for (var ind in layers) {
                    if (layers[ind].source == 'traffic') {
                        map.current.setLayoutProperty(layers[ind].id, 'visibility', traffic ? 'visible' : 'none')
                    }
                }
            }
        }
        showTrafficLayersOnSecondMap(traffic)
    }

    const showTrafficLayersOnSecondMap = (traffic) => {
        if (secondMap.current && secondMap.current != null && props.secondaryMapRequired) {
            var layers = secondMap.current.getStyle().layers;
            if (layers) {
                for (var ind in layers) {
                    if (layers[ind].source == 'traffic') {
                        secondMap.current.setLayoutProperty(layers[ind].id, 'visibility', traffic ? 'visible' : 'none')
                    }
                }
            }
        }
    }
   const getMapCenter = () => {
        return map.current ? map.current.getCenter(): '';
    }

    const getMapStyles = () => {
        return map.current.getStyle().layers;
    }
   const createLngLatObj = (location) => {
    let lngLat = new mapboxgl.LngLat(location.coordinates[0].longitude, location.coordinates[0].latitude);
        return lngLat;
    }
    const createLngLatObjFrom = (longitude, latitude) => {
        let lngLat = new mapboxgl.LngLat(longitude, latitude);
        return lngLat;
    }
    const drawMarker = (options, lngLat, container) => {
        if (mapContainer.current) {
            // return new mapboxgl.Marker(options).setLngLat(lngLat).addTo(map.current);
            const marker = new mapboxgl.Marker(options).setLngLat(lngLat);
            if (container === mapContainer.current) {
                marker.addTo(map.current);
            } else if (container === secondMapContainer.current && props.secondaryMapRequired) {
                marker.addTo(secondMap.current);
            } else {
                marker.addTo(map.current);
            }
            return marker;
        }
    }

    const drawIconMarker = (options, lng, lat) => {
        let lngLat = new mapboxgl.LngLat(lng, lat);
        let marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(map.current);
        return marker;
    }
    const jumpTo = (lngLat, zoomLevel, container) => {
        if (!map.current) {
            return null;
        }
        if (!container || container === mapContainer.current) {
            map.current.jumpTo({
                center: lngLat,
                zoom: zoomLevel ? zoomLevel : groupLocations.default.zoom
            });
        } else if (container === secondMapContainer.current && props.secondaryMapRequired) {
            secondMap.current.jumpTo({
                center: lngLat,
                zoom: zoomLevel ? zoomLevel : groupLocations.default.secondMapZoom
            });
        }
    }
    const mapProject = (lng, lat) => {
        let lnglat = map.current.project(new mapboxgl.LngLat(lng, lat));
        return lnglat;
    }
    const calculateBoundsFromGeoJson = (geojsonData) => {
        return geojsonData.features.reduce((bounds, feature) => {
            bounds.extend(feature.geometry.coordinates);
            return bounds;
        }, new mapboxgl.LngLatBounds());
    }

    const drawDotedLine = (layerId, coordinatesArray, lineColor) => {
        if (map.current.getLayer(layerId)) {
            map.current.removeLayer(layerId);
            map.current.removeSource(layerId);
        }
        map.current.addLayer({
          "id": layerId,
          "type": "line",
          "source": {
            "type": "geojson",
            "data": {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "LineString",
                'coordinates': coordinatesArray
              }
            }
          },
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
          "paint": {
            "line-color":lineColor,
            "line-width": {
              'stops': [
                [2, 4],
                [24, 4],
              ],
            },
            "line-dasharray": [0, 1.5]
          }
        });
        if (props.secondaryMapRequired) {
            drawDotedLineOnSecondMap(layerId, coordinatesArray, lineColor)
        }
    }
    const drawDotedLineOnSecondMap = (layerId, coordinatesArray, lineColor) => {
        if (secondMap.current.getLayer(layerId)) {
            secondMap.current.removeLayer(layerId);
            secondMap.current.removeSource(layerId);
        }
        secondMap.current.addLayer({
          "id": layerId,
          "type": "line",
          "source": {
            "type": "geojson",
            "data": {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "LineString",
                'coordinates': coordinatesArray
              }
            }
          },
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
          "paint": {
            "line-color": lineColor,
            "line-width": {
              'stops': [
                [2, 4],
                [24, 4],
              ],
            },
            "line-dasharray": [0, 1.5]
          }
        });
    }

    const removeLayer = (layerId) => {
        if (map.current && map.current.getLayer(layerId)) {
            map.current.removeLayer(layerId);
            map.current.removeSource(layerId);
        } 
        if (secondMap.current && secondMap.current.getLayer(layerId)) {
            secondMap.current.removeLayer(layerId);
            secondMap.current.removeSource(layerId);
        }
    }
    const handleLayerVisibility = (container, layerId, show) => {
        if (!show) {
            if (container === mapContainer.current) {
            if (map.current && map.current.getLayer(layerId)) {
                map.current.setLayoutProperty(layerId, 'visibility', 'none');
            }
            } else if (container === secondMapContainer.current && props.secondaryMapRequired) {
            if (props.secondaryMapRequired && secondMap.current && secondMap.current.getLayer(layerId)) {
                secondMap.current.setLayoutProperty(layerId, 'visibility', 'none');
            }
        }
        } else {
            if (container === mapContainer.current) {
            if (map.current && map.current.getLayer(layerId)) {
                map.current.setLayoutProperty(layerId, 'visibility', 'visible');
            }
        }
        else if (container === secondMapContainer.current && props.secondaryMapRequired) {
            if (props.secondaryMapRequired && secondMap.current && secondMap.current.getLayer(layerId)) {
                secondMap.current.setLayoutProperty(layerId, 'visibility', 'visible');
            }
        }
        }
    }
    const addMarkerOnMap = (marker) => {
        if (map.current) {
            marker.addTo(map.current);
        } 
    }
    const addMarkerOnSecondMap = (marker) => {
        if (props.secondaryMapRequired && secondMap.current) {
            marker.addTo(secondMap.current);
        }
    }
    const addBreadCrumb = (container, coordinates, circleColor, circleId, lineId, polylineId) => {
        if (container === mapContainer.current) {
            drawBreadCrumb(map.current, coordinates, circleColor, circleId, lineId, polylineId)
        } else if (container === secondMapContainer.current && props.secondaryMapRequired) {
            drawBreadCrumb(secondMap.current, coordinates, circleColor, circleId, lineId, polylineId)
        }
    }
    /* add breadcrumb */
    const  drawBreadCrumb = (mapRef, coordinates, circleColor, circleId, lineId, polylineId) => {
        try {
            if (mapRef.getLayer(lineId)) {
                mapRef.removeLayer(lineId);
                mapRef.removeSource(lineId);
            }
            mapRef.addSource(lineId, {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': coordinates
                    }
                }
            });
            mapRef.addLayer({
                'id': lineId,
                "type": "circle",
                "source": lineId,
                "paint": {
                    "circle-radius": 5,
                    "circle-color": circleColor ? circleColor : '#888'
                }
            });
            if (mapRef.getLayer(polylineId)) {
                mapRef.removeLayer(polylineId);
                mapRef.removeSource(polylineId);
            }
            mapRef.addSource(polylineId, {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': coordinates
                    }
                }
            });
            mapRef.addLayer({
                'id': polylineId,
                "type": "line",
                "source": polylineId,
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': circleColor ? circleColor : '#888',
                    'line-width': 2
                }
            });
            if (mapRef.getLayer(circleId)) {
                mapRef.removeLayer(circleId);
                mapRef.removeSource(circleId);
            }
            mapRef.addSource(circleId, {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [
                        {
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: coordinates[0]
                            }
                        }
                    ]
                }
            });
            mapRef.addLayer({
                "id": circleId,
                "type": "circle",
                "source": circleId,
                "paint": {
                    "circle-radius": 8,
                    "circle-color": circleColor ? circleColor : '#888'
                }
            });
        } catch (error) {
            console.log("::::::::: error Add Line ::::::::::: ")
        }
    }
    const addCircle = (container, coordinatesArray, radius, circleId, circleBorder) => {
        if (container === mapContainer.current) {
            drawUncertainity(map.current, coordinatesArray, radius, circleId, circleBorder)
        } else if (container === secondMapContainer.current && props.secondaryMapRequired) {
            drawUncertainity(secondMap.current, coordinatesArray, radius, circleId, circleBorder)
        } else {
            drawUncertainity(map.current, coordinatesArray, radius, circleId, circleBorder)
        }
    }
    const drawUncertainity = (mapRef, coordinatesArray, radius, circleId,circleBorder) => {
        if (mapRef && mapRef.getStyle() && mapRef.getStyle().layers && mapRef.getStyle().layers.length && coordinatesArray && coordinatesArray.length) {
            let polygonPoints = createGeoJSONCircle(
                coordinatesArray,
                radius
            );
            if (!mapRef.getLayer(circleBorder)) {
                mapRef.addSource(circleBorder, polygonPoints);
                mapRef.addLayer({
                    id: circleBorder,
                    type: "line",
                    source: circleBorder,
                    layout: {},
                    paint: {
                        'line-color': '#5bb0d8',
                        'line-width': 1
                    }
                });
            } else {
                mapRef.getSource(circleBorder).setData(polygonPoints.data);
            }
            if (!mapRef.getSource(circleId)) {
                mapRef.addSource(circleId, polygonPoints);
                mapRef.addLayer({
                    id: circleId,
                    type: "fill",
                    source: circleId,
                    layout: {},
                    paint: {
                        "fill-color": "#9fcbf9",
                        "fill-opacity": 0.5,
                        'fill-outline-color': '#5bb0d8',
                    }
                });
            } else {
                mapRef.getSource(circleId).setData(polygonPoints.data);
            }
        }
    }
    const getFeatures = (latlng) => {
        return map.current.queryRenderedFeatures(latlng);
    }
    const getMapLayers = (layer) => {
        return map.current.getLayer(layer)
    }
    const removeMapSource = (layer) => {
        return map.current.removeSource(layer)
    }
    const uncertainityCylinder = (layer) => {
        if (map.current.getLayer(layer)) {
            map.current.removeLayer(layer);
            map.current.removeSource(layer);
        }
        if (map.current.getSource(layer) == null) {
            map.current.addSource(layer, {
                type: "geojson",
                data: {
                    features: [],
                    type: "FeatureCollection",
                },
            });
            map.current.addLayer({
                id: layer,
                type: "fill-extrusion",
                source: layer,
                minzoom: callConfigs.zAxisZoomLevel,
                paint: {
                    // Get the `fill-extrusion-color` from the source `color` property.
                    "fill-extrusion-color": ["get", "color"],
                    // Get `fill-extrusion-height` from the source `height` property.
                    "fill-extrusion-height": ["get", "height"],
                    // Get `fill-extrusion-base` from the source `base_height` property.
                    "fill-extrusion-base": ["get", "base_height"],
                    // Make extrusions slightly opaque to see through indoor walls.
                    "fill-extrusion-opacity": 0.5,
                },
            });
        }
    }
    const zAxisCylinder = (layer) => {
        if (map.current.getLayer(layer)) {
            map.current.removeLayer(layer);
            map.current.removeSource(layer);
        }
        if (map.current.getSource(layer) == null) {
            map.current.addSource(layer, {
                type: "geojson",
                data: {
                    features: [],
                    type: "FeatureCollection",
                },
            });
            map.current.addLayer({
                id: layer,
                type: "fill-extrusion",
                source: layer,
                minzoom: callConfigs.zAxisZoomLevel,
                paint: {
                    // Get the `fill-extrusion-color` from the source `color` property.
                    "fill-extrusion-color": ["get", "color"],
                    // Get `fill-extrusion-height` from the source `height` property.
                    "fill-extrusion-height": ["get", "height"],
                    // Get `fill-extrusion-base` from the source `base_height` property.
                    "fill-extrusion-base": ["get", "base_height"],
                    // Make extrusions slightly opaque to see through indoor walls.
                    "fill-extrusion-opacity": 0.5,
                },
            });
        }
    }
    const getSetData = (layer, data) => {
        map.current.getSource(layer).setData(data);
    }
    const handleSplitterMouseDown = () => {
        setIsResizing(true)
    }
    useEffect(() => {
        isResizingRef.current = isResizing;
    }, [isResizing]);
    const enableSplitScreen = (bool) => {
        setSplitScreenEnable(bool)
    }
    const handleMouseUp = () => {
        setIsResizing(prevIsResizing => (prevIsResizing ? false : prevIsResizing));
    }
    const handleMouseMove = (e) => {
        if (isResizingRef.current) {
            const containerRect = splitterMap.current.getBoundingClientRect();
            const splitterRect = splitter.current.getBoundingClientRect();
            getSecondaryPaneSize(containerRect, splitterRect, {
                left: e.clientX,
                top: e.clientY
            }, true);
        }
    }
    useEffect(() => {
        if (map.current && secondMap.current) {
            map.current.resize();
            secondMap.current.resize();
        }

        if (map.current) {
            map.current.resize();
        }
    }, [state.secondaryPaneSize, state.dividerPosition, state.primaryPaneSize, isSplitScreen, props.onMapLoadChange]);
    
    const getSecondaryPaneSize = (containerRect, splitterRect, clientPosition, offsetMouse) => {
        let totalSize;
        let splitterSize;
        let offset;
        totalSize = containerRect.width;
        splitterSize = splitterRect.width;
        offset = clientPosition.left - containerRect.left;
        if (offsetMouse) {
            offset -= splitterSize / 2;
        }
        if (offset < 0) {
            offset = 0;
        } else if (offset > totalSize - splitterSize) {
            offset = totalSize - splitterSize;
        }
        let secondaryPaneSize;
        secondaryPaneSize = totalSize - splitterSize - offset;
        let primaryPaneSize = totalSize - splitterSize - secondaryPaneSize;
        secondaryPaneSize = (secondaryPaneSize * 100) / totalSize;
        primaryPaneSize = (primaryPaneSize * 100) / totalSize;
        splitterSize = (splitterSize * 100) / totalSize;
        totalSize = 100;
        if (primaryPaneSize < 0) {
            secondaryPaneSize = Math.max(secondaryPaneSize - (0 - primaryPaneSize), 0);
        } else if (secondaryPaneSize < 0) {
            secondaryPaneSize = Math.min(totalSize - splitterSize - 0, 0);
        }
        let dividerPosition = secondaryPaneSize + '%';
        secondaryPaneSize = secondaryPaneSize + '%';
        primaryPaneSize = primaryPaneSize + '%';
        setState({ secondaryPaneSize, dividerPosition, primaryPaneSize });
        // this.setState({ secondaryPaneSize: secondaryPaneSize, dividerPosition: dividerPosition, primaryPaneSize: primaryPaneSize }, () => {
        //     map.current.resize();
        //     secondMap.current.resize()
        // });
        return secondaryPaneSize;
    }
    const activeMarkerClickEvent = () => {
        callConfigs.locateCounter = callConfigs.locateCounter + 1;
        props.onLocateMeClick(callConfigs.locateCounter);
    }
    const drawPopup = (options, lngLat, content, container) => {
        var popup = new mapboxgl.Popup(options)
            .setLngLat(lngLat)
            .setDOMContent(content);
            if (!container || container === mapContainer.current) {
                popup.addTo(map.current);
            }
            else if (container === secondMapContainer.current && props.secondaryMapRequired) {
                popup.addTo(secondMap.current);
            }
            return popup
    }
    const setBounds = (bounds, container) => {
        if (!map.current) {
            return null;
        }
        var bbox = new mapboxgl.LngLatBounds();
        if (!container || container === mapContainer.current) {
            if (bounds.length === 1) {
                map.current.jumpTo({
                    center: bounds[0],
                    zoom: 15
                });
            } else if (bounds.length > 1) {
                bounds.forEach((coordinate) => {
                    bbox.extend(coordinate);
                });
                map.current.fitBounds(bbox, {
                    padding: { top: 120, bottom: 75, left: 50, right: 75 }
                });
            }
        } else if (container === secondMapContainer.current && props.secondaryMapRequired) {
            if (bounds.length === 1) {
                secondMap.current.jumpTo({
                    center: bounds[0],
                    zoom: 18
                });
            } else if (bounds.length > 1) {
                bounds.forEach((coordinate) => {
                    bbox.extend(coordinate);
                });
                secondMap.current.fitBounds(bbox, {
                    padding: { top: 120, bottom: 75, left: 50, right: 75 }
                });
            }
        }
    }

    const resize = () => {
        if (map.current && secondMap.current) {
            map.current.resize();
            secondMap.current.resize();
        }

        if (map.current) {
            map.current.resize();
        }
    }
    return <>
        <div className='map-container' ref={splitterMap}>
            <div ref={mapContainer} className='psap-map' style={{ width: isSplitScreen ? state.primaryPaneSize : '100%', position: 'absolute' }}>
                {props.searchValue && (
        <div className='search-container'> 
            <SearchAdd 
                mapCenter={getMapCenter}
                map={map.current} 
                showMap={showMap} 
            />              
            <div className="searchLayout">
            <button className="ssbtn" style={{ background: search === 'ADDRESS' ? '#4b4b4b' : '#d1d1d1' }}>{search === 'ADDRESS' ? <img src={POI_On} className='poi-icon' /> : <img src={POI_Off} className='poi-icon' />}</button>           
            </div>
        </div> 
    )}
                <div title="Locate" className='bounds-icon call-bounds-icon hand-cursor' onClick={() => { activeMarkerClickEvent() }} ></div>
            <div className="comtech-logo">
                <a href="https://www.comtechlocation.com/location-products/location-studio/geolocation-solutions" target='_blank' ><img src={comtechLogo} /></a>
            </div>
            <pre id="primary-map-latlng" className="latlng-popup"></pre>
            </div>
        </div>
        {props.secondaryMapRequired ? <>
            <div className="map-divider" onMouseDown={handleSplitterMouseDown}
                onTouchStart={handleSplitterMouseDown}
                ref={splitter}
                style={{ right: state.dividerPosition, display: isSplitScreen ? '' : 'none' }}
            >
                <div className="map-divider-image" />
            </div>
            <div ref={secondMapContainer} className="psap-sattelite-map" style={{ width: isSplitScreen ? state.secondaryPaneSize : 'auto', position: isSplitScreen ? 'absolute' : 'relative', display: isSplitScreen ? 'block' : 'none' }}>
                <div className="comtech-logo">
                    <a href="https://www.comtechlocation.com/location-products/location-studio/geolocation-solutions" target='_blank' ><img src={comtechLogo} /></a>
                </div>
                <pre id="satellite-map-latlng" className="latlng-popup"></pre>
            </div>
        </>
            : ''}
    </>;
};

export default Map;