import { Auth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { STORAGE_KEYS } from "../../constants/SessionConstants"
import { configTimers } from "../../constants/ConfigTimers"
import { primaryRegion, secondaryRegion } from "../../awsexport-client";

Auth.configure({ storage: sessionStorage });
export const configureAmplify = () => {
    var currentRegion = localStorage.getItem(STORAGE_KEYS.CURRENT_REGION);
    if ((currentRegion != null || currentRegion) && currentRegion === secondaryRegion.aws_project_region) {
        Amplify.configure(secondaryRegion);
    } else {
        Amplify.configure(primaryRegion);
    }
}

export const signOut = () => {
    Auth.signOut()
        .then(() => {
            for (let key in sessionStorage) {
                sessionStorage.removeItem(sessionStorage.key(key));
            }
            window.location.href = "/";
        })
        .catch(e => {
            console.log(e);
        });
}

let sessionExpiryCount = 0;
export const refreshSessionToken = () => {
    return new Promise((resolve, reject) => {
        let session = Auth.currentSession()
            .then((user) => {
                let date = new Date().getTime();
                console.log('Session Expiry Time: ' + ((user.accessToken.payload.exp * 1000) - date));
                let nextTimeout = (user.accessToken.payload.exp * 1000) - date;
                resolve(nextTimeout); // Resolve the Promise with nextTimeout
            })
            .catch(exception => {
                console.log('exception' + exception); 
                setTimeout(() => {
                    sessionExpiryCount = sessionExpiryCount + 1;
                    refreshSessionToken()
                        .then(nextTimeout => {
                            resolve(nextTimeout);
                        })
                        .catch(reject);
                    if (sessionExpiryCount === 4) {
                        console.log('logout'); // Use console.log, not consoleLog
                        sessionExpiryCount = 0;
                        signOut();
                    }
                }, configTimers.sessionTokenRetry);
            });
    });
}