export const oktaUrls = {
	APP_NAME: 'SmartNG',
	OKTA_BASE_URL: 'https://state911.oktapreview.com',
	CLIENT_ID: '0oa3yxany5HznH11m1d7',
	ISSUER: '/oauth2/aus41ie4y4Tm5DTzk1d7',
	ROLE_ARN: 'arn:aws:iam::161719076781:role/okta-mapeditor-clt-role',
	ROLE_NAME: 'okta-mapeditor-clt-role',
	passwordResetUrl: '/enduser/settings',
};

export const primaryRegionData = {
	"baseUrl":'https://api.dev.smartresponse.net',
	"aws_project_region": "us-west-2",
	"aws_notification_host": "a13mxjnpgsczwk-ats.iot.us-west-2.amazonaws.com",
	"aws_notification_region": "us-west-2",
	"createUserAndAPIKeyUrl": 'https://rpkj0g7rpi.execute-api.us-west-2.amazonaws.com/generateAndUpdateKongKeyInOkta-State911-Preview',
};

export const secondaryRegionData  = {
	"baseUrl":'https://api.dev2.smartresponse.net',
	"aws_project_region": "us-east-1",
	"aws_notification_host": "a13mxjnpgsczwk-ats.iot.us-east-1.amazonaws.com",
	"aws_notification_region": "us-east-1",
	"createUserAndAPIKeyUrl": '',
};