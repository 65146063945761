export const STORAGE_KEYS = {
    GROUP_PROFILE: 'groupProfile',
    SHOW_NOTIFICATIONS: 'showNotifications',
    CURRENT_USER: 'currentUser',
    CURRENT_REGION: 'currentRegion',
    USER: 'user',
    API_KEY: 'api_key',
    ID_TOKEN: 'idToken',
    ACCESS_TOKEN: 'accessToken',
    GROUP_NAME: 'groupName',
    BASE_URL: 'base',
    NSPL_BASE_URL: 'nsplBaseUrl',
    END_CALL_TIME: 'endCallTime',
    OKTA_TOKENS: 'oktaTokens',
    WAITING_STATUS: 'queued',
}