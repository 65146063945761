import { authConfigs, urls} from '../urls';
import { STORAGE_KEYS } from "./SessionConstants";
import imgMassGisBaseMap from '../assets/MassGIS-BaseMap.png';
import imgMassGisEmergency from '../assets/MassGIS-Emergency.png';
import imgMassGisPsap from '../assets/MassGIS-PSAP.png';
import imgMassGisSatellite from '../assets/MassGIS-Satellite-Hybrid.png';
import imgMassGisPd from '../assets/MassGIS-pd.png';
import imgMassGisFd from '../assets/MassGIS-fd.png';
import imgUsDark from '../assets/US-Dark.png';
import imgUsDay from '../assets/US-Day.png';
import imgUsLight from '../assets/US-Light.png';
import imgUsNight from '../assets/US-Night.png';
import imgUsSatellite from '../assets/US-Satellite.png';
import imgMapStyle from '../assets/mapstyle_default.png';
import icWph2Active from '../assets/wph2_active.svg';
import icLandlineActive from '../assets/landline_active.svg';
import icWph1Active from '../assets/wph1_active.svg';
import icOthersActive from '../assets/others_active.svg';
import icPayPhoneActive from '../assets/payphone_active.svg';
import icTelematicsActive from '../assets/telematics_active.svg';
import icVoipActive from '../assets/voip_active.svg';
import icRapidActive from '../assets/rapid_active.svg';


import icWph1Selected from '../assets/wph1_selected.svg';
import icWph2Selected from '../assets/wph2_selected.svg';
import icLandlineSelected from '../assets/landline_selected.svg';
import icOthersSelected from '../assets/others_selected.svg';
import icPayPhoneSelected from '../assets/payphone_selected.svg';
import icTelematicsSelected from '../assets/telematics_selected.svg';
import icVoipSelected from '../assets/voip_selected.svg';

import icVoipCompleted from '../assets/voip_completed.svg';
import icTelematicsCompleted from '../assets/telematics_completed.svg';
import icPayPhoneCompleted from '../assets/payphone_completed.svg';
import icOthersCompleted from '../assets/others_completed.svg';
import icWph1Completed from '../assets/wph1_completed.svg';
import icLandlineCompleted from '../assets/landline_completed.svg';
import icWph2Completed from '../assets/wph2_completed.svg';

import icon_t_queued_waiting from '../assets/solacom/icon_t_queued_waiting.svg';
import icon_m_queued_waiting from '../assets/solacom/icon_m_queued_waiting.svg';
import icon_l_queued_waiting from '../assets/solacom/icon_l_queued_waiting.svg';
import icon_v_queued_waiting from '../assets/solacom/icon_v_queued_waiting.svg';
import icon_te_queued_waiting from '../assets/solacom/icon_te_queued_waiting.svg';
import icon_p_queued_waiting from '../assets/solacom/icon_p_queued_waiting.svg';
import icon_o_queued_waiting from '../assets/solacom/icon_o_queued_waiting.svg';
import icon_t_queued_ringing from '../assets/solacom/icon_t_queued_ringing.gif';
import icon_m_queued_ringing from '../assets/solacom/icon_m_queued_ringing.gif';
import icon_l_queued_ringing from '../assets/solacom/icon_l_queued_ringing.gif';
import icon_v_queued_ringing from '../assets/solacom/icon_v_queued_ringing.gif';
import icon_te_queued_ringing from '../assets/solacom/icon_te_queued_ringing.gif';
import icon_p_queued_ringing from '../assets/solacom/icon_p_queued_ringing.gif';
import icon_o_queued_ringing from '../assets/solacom/icon_o_queued_ringing.gif';
import icon_t_transfer_t_out from '../assets/solacom/icon_t_transfer_t_out.svg';
import icon_m_transfer_t_out from '../assets/solacom/icon_m_transfer_t_out.svg';
import icon_l_transfer_t_out from '../assets/solacom/icon_l_transfer_t_out.svg';
import icon_v_transfer_t_out from '../assets/solacom/icon_v_transfer_t_out.svg';
import icon_te_transfer_t_out from '../assets/solacom/icon_te_transfer_t_out.svg';
import icon_p_transfer_t_out from '../assets/solacom/icon_p_transfer_t_out.svg';
import icon_o_transfer_t_out from '../assets/solacom/icon_o_transfer_t_out.svg';
import icon_t_inprogress from '../assets/solacom/icon_t_inprogress.svg';
import icon_m_inprogress from '../assets/solacom/icon_m_inprogress.svg';
import icon_l_inprogress from '../assets/solacom/icon_l_inprogress.svg';
import icon_v_inprogress from '../assets/solacom/icon_v_inprogress.svg';
import icon_te_inprogress from '../assets/solacom/icon_te_inprogress.svg';
import icon_p_inprogress from '../assets/solacom/icon_p_inprogress.svg';
import icon_o_inprogress from '../assets/solacom/icon_o_inprogress.svg';
import icon_t_selected from '../assets/solacom/icon_t_selected.svg';
import icon_m_selected from '../assets/solacom/icon_m_selected.svg';
import icon_l_selected from '../assets/solacom/icon_l_selected.svg';
import icon_v_selected from '../assets/solacom/icon_v_selected.svg';
import icon_te_selected from '../assets/solacom/icon_te_selected.svg';
import icon_p_selected from '../assets/solacom/icon_p_selected.svg';
import icon_o_selected from '../assets/solacom/icon_o_selected.svg';
import icon_t_ended from '../assets/solacom/icon_t_ended.svg';
import icon_m_ended from '../assets/solacom/icon_m_ended.svg';
import icon_l_ended from '../assets/solacom/icon_l_ended.svg';
import icon_v_ended from '../assets/solacom/icon_v_ended.svg';
import icon_te_ended from '../assets/solacom/icon_te_ended.svg';
import icon_p_ended from '../assets/solacom/icon_p_ended.svg';
import icon_o_ended from '../assets/solacom/icon_o_ended.svg';
import icRapidSelected from '../assets/rapid_selected.svg';
import icRapidWaiting from '../assets/rapid_waiting.svg';
import icRapidCompleted from '../assets/rapid_completed.svg';
import icon_t_released from '../assets/solacom/icon_t_released.svg';
import icon_m_released from '../assets/solacom/icon_m_released.svg';
import icon_l_released from '../assets/solacom/icon_l_released.svg';
import icon_v_released from '../assets/solacom/icon_v_released.svg';
import icon_te_released from '../assets/solacom/icon_te_released.svg';
import icon_p_released from '../assets/solacom/icon_p_released.svg';
import icon_o_released from '../assets/solacom/icon_o_released.svg';
import icon_t_multi_ringing from '../assets/solacom/icon_t_multi_ringing.gif';
import icon_m_multi_ringing from '../assets/solacom/icon_m_multi_ringing.gif';
import icon_l_multi_ringing from '../assets/solacom/icon_l_multi_ringing.gif';
import icon_v_multi_ringing from '../assets/solacom/icon_v_multi_ringing.gif';
import icon_te_multi_ringing from '../assets/solacom/icon_te_multi_ringing.gif';
import icon_p_multi_ringing from '../assets/solacom/icon_p_multi_ringing.gif';
import icon_o_multi_ringing from '../assets/solacom/icon_o_multi_ringing.gif';


export const appName = 'SmartNG';
export const notificationBody = 'Notification from SmartNG'

export const apiAuthParams = {
  okta: 'oauth',
  cognito: 'cauth',
}

export const serverUrls = {
  stylesUrl: `/geo/maps/v2/mb/styles/`,
}

export const groupLocations = {
  'default' : {
    'center' : [-71.0875, 42.299],
    'zoom': 11.45,
    'secondMapZoom':18
  }
}

export const authConfig = authConfigs;

export const notifyTypeVals = {
  AWS_IOT: "aws-iot",
  WEB_SOCKET: "web-socket",
}

export const appConfigs = {
  notificationsType: urls.notificationsType,
}

export const featureLabels = {
  'map' : 'FC_MAP',
  'traffic' :'FC_TRAFFIC',
  'weather' : 'FC_WEATHER',
  'search': 'FC_SEARCH',
  'person': 'FC_PERSON',
  'call' : 'FC_CALL',
  'incident' : 'FC_INCIDENT',
  'analytics' : 'FC_ANALYTICS',
  'routing' : 'FC_ROUTING',
  'sitCount' : 'FC_SITUCONT',
  'camera': 'CAMERA',
  'aed' : 'AED',
  'z_axis' : 'FC_ZAXIS',
  'tracking' : 'FC_AVL',
  'video': 'FC_VIDEO',
  'indoorMap': 'FC_MAP_INDOOR',
  'device_tracking': 'FC_TRACK',
  'device_analytics': 'FC_ANALYTICS_TRACK',
  'tracking_factory': 'FC_TRACK_LAB_FACTORY',
}

export const cancelErrorMessages = {
  mapUserMsg: 'Map styles request is timing out.  Please try again.',
  polygonDatasetTimeOutMsg: 'Geometry request is timing out.  Please try again.',
  routeTimeOutMsg: 'Route request is timing out.  Please try again.',
  searchTimeOut: 'Search request is timing out.  Please try again.',
  reverseGeocodeTimeOutMsg: 'Reverse GeoCode request is timing out.  Please try again.',
  incidentTimeOutMsg: 'All Calls request is timing out.  Please try again.',
  AnalyticsTimeOutMsg: 'Upstream server is timing out.  Please try again.',
  weatherTimeOutMsg: 'Weather request is timing out.  Please try again.',
  addressPointTimeOutMsg: 'Address Point request is timing out.  Please try again.',
  discrepencyReportTimeOutMsg: 'Discrepency Report request is timing out.  Please try again.',
  statusReportTimeOutMsg: 'Region check request is timing out.  Please try again.'
}

export const errorMessages = {
  suggestionError: 'Failed to get suggestions.  Please try again.',
  searchEmptyError: 'Please enter Address, POI or Lat lng to search.',
  searchError: 'Failed to get results.  Please try again.',
  nofireStations: 'No Fire Stations or Police Stations are available on Map.',
  noActiveCalls: 'No Active Call is available.',
  iDTokenExpireMessage: 'Current login session expired. Please sign out and sign in again',
  invalidW3WError: 'Please enter a valid 3 word address',
  networkError: 'No Network Connection',
  regionDownMsg:'Current service is unavailable. Please sign out and sign in to another available service.',
  sameRegionSelectedMsg:'Current service is unavailable. Please select another available service',
  browserNotifPermMsg: 'Browser Notifications popup permissions are blocked',
  noDeviceHistory: 'History data not found',
  loginFailedError: "Login failed. Please try again.",
  sessionExpiredMsg: 'Your session has expired. Please sign in again.',
  tokensExpiredMsg: 'Tokens are expired. Please sign in again.',
}

export const successMessages = {
  polygonUploadMsg: 'Geometry is uploaded.',
  polygonDeletedMsg: 'Geometry is deleted.'
}

export const AUTH_TYPES = {
  OKTA: 'okta',
  COGNITO: 'cognito',
}

export const mapStyle = {
  'images': {
    'MassGIS-BaseMap': imgMassGisBaseMap,
    'MassGIS-Emergency': imgMassGisEmergency,
    'MassGIS-PSAP': imgMassGisPsap,
    'MassGIS-Satellite-Hybrid': imgMassGisSatellite,
    'MassGIS-Satellite': imgMassGisSatellite,
    'MassGIS-Fire': imgMassGisFd,
    'MassGIS-Police': imgMassGisPd,
    'US-Traffic-Satellite': imgUsSatellite,
    'US-Satellite': imgUsSatellite,
    'dark': imgUsDark,
    'day': imgUsDay,
    'light': imgUsLight,
    'night': imgUsNight,
    'default': imgMapStyle
  },
  'themes': {
    'dark' : 'dark',
    'day' : 'day',
    'light' : 'light',
    'night' : 'night'
  }
}

export const toolTipLabels = {
  'calls' : 'Calls',
  'all_calls': 'All Calls',
}

export const callConfigs = {
  defaultGisId : 'MassGIS',
  meterToFeet: 3.28,
  enableSimulation : `${urls.enableSimulationData}` === 'true'? true :  false,
  isSimulationEnabled : sessionStorage.getItem(STORAGE_KEYS.isSimulationEnabled) === 'true'? true :  false,
  callstartTime : 0,
  seconds: 0,
  minutes: 0,
  counterTime: 1000,
  defaultZoom: 15,
  satelliteMapPitch: 45,
  satelliteMapZoom: 18,
  contourLineDistance: 0.12,
  contourLayer: 'contour',
  uncertyCylinder: 'uncertyCylinder',
  zCylinder : "zCylinder",
  zAxisZoomLevel: 15,
  sim911Topic: 'call911/',
  solacomTopic: 'solacom',
  motorolaTopic: 'motorola',
  active: 'inProgress',
  waiting: 'queued',
  ended: 'ended',
  release: 'released',
  transfer: 'transferred'
}

export const markerImages = {
  'activeMarkers':{
      'WPH1': icWph1Active,
      'WPH2': icWph2Active,
      'RESD': icLandlineActive,
      'BUSN': icLandlineActive,
      'VOIP': icVoipActive,
      'TELE': icTelematicsActive,
      'CENT': icLandlineActive,
      'COIN': icPayPhoneActive,
      'WRLS': icOthersActive,
      'UNK': icOthersActive,
      'RAPIDSOS': icRapidActive,
      'default': icOthersActive
  },
  'selectMarkers': {
    'WPH1': icWph1Selected,
    'WPH2': icWph2Selected,
    'RESD': icLandlineSelected,
    'BUSN': icLandlineSelected,
    'VOIP': icVoipSelected,
    'TELE': icTelematicsSelected,
    'CENT': icLandlineSelected,
    'COIN': icPayPhoneSelected,
    'WRLS': icOthersSelected,
    'UNK': icOthersSelected,
    'default': icOthersSelected
  },
  'endedMarkers':{
    'WPH1': icWph1Completed,
    'WPH2': icWph2Completed,
    'RESD': icLandlineCompleted,
    'BUSN': icLandlineCompleted,
    'VOIP': icVoipCompleted,
    'TELE': icTelematicsCompleted,
    'CENT': icLandlineCompleted,
    'COIN': icPayPhoneCompleted,
    'WRLS': icOthersCompleted,
    'UNK': icOthersCompleted,
    'default': icOthersCompleted
  }
}

export const solacomMarkerImages = {
  'waiting': {
      'WPH1': icon_t_queued_waiting,
      'WPH2': icon_m_queued_waiting,
      'RESD': icon_l_queued_waiting,
      'BUSN': icon_l_queued_waiting,
      'VOIP': icon_v_queued_waiting,
      'TELE': icon_te_queued_waiting,
      'CENT': icon_l_queued_waiting,
      'COIN': icon_p_queued_waiting,
      'WRLS': icon_o_queued_waiting,
      'UNK': icon_o_queued_waiting,
      'default': icon_o_queued_waiting
  },
  'ringing': {
      'WPH1': icon_t_queued_ringing,
      'WPH2': icon_m_queued_ringing,
      'RESD': icon_l_queued_ringing,
      'BUSN': icon_l_queued_ringing,
      'VOIP': icon_v_queued_ringing,
      'TELE': icon_te_queued_ringing,
      'CENT': icon_l_queued_ringing,
      'COIN': icon_p_queued_ringing,
      'WRLS': icon_o_queued_ringing,
      'UNK': icon_o_queued_ringing,
      'default': icon_o_queued_ringing
  },
  'transferred': {
      'WPH1': icon_t_transfer_t_out,
      'WPH2': icon_m_transfer_t_out,
      'RESD': icon_l_transfer_t_out,
      'BUSN': icon_l_transfer_t_out,
      'VOIP': icon_v_transfer_t_out,
      'TELE': icon_te_transfer_t_out,
      'CENT': icon_l_transfer_t_out,
      'COIN': icon_p_transfer_t_out,
      'WRLS': icon_o_transfer_t_out,
      'UNK': icon_o_transfer_t_out,
      'default': icon_o_transfer_t_out
  },
  'activeMarkers': {
      'WPH1': icon_t_inprogress,
      'WPH2': icon_m_inprogress,
      'RESD': icon_l_inprogress,
      'BUSN': icon_l_inprogress,
      'VOIP': icon_v_inprogress,
      'TELE': icon_te_inprogress,
      'CENT': icon_l_inprogress,
      'COIN': icon_p_inprogress,
      'WRLS': icon_o_inprogress,
      'UNK': icon_o_inprogress,
      'RAPIDSOS': icRapidActive,
      'default': icon_o_inprogress
  },
  'selectMarkers': {
      'WPH1': icon_t_selected,
      'WPH2': icon_m_selected,
      'RESD': icon_l_selected,
      'BUSN': icon_l_selected,
      'VOIP': icon_v_selected,
      'TELE': icon_te_selected,
      'CENT': icon_l_selected,
      'COIN': icon_p_selected,
      'WRLS': icon_o_selected,
      'UNK': icon_o_selected,
      'default': icon_o_selected
  },
  'endedMarkers': {
      'WPH1': icon_t_ended,
      'WPH2': icon_m_ended,
      'RESD': icon_l_ended,
      'BUSN': icon_l_ended,
      'VOIP': icon_v_ended,
      'TELE': icon_te_ended,
      'CENT': icon_l_ended,
      'COIN': icon_p_ended,
      'WRLS': icon_o_ended,
      'UNK': icon_o_ended,
      'default': icon_o_ended
  },
  'rapidSOSMarkers': {
      'active': icRapidActive,
      'selected': icRapidSelected,
      'waiting': icRapidWaiting,
      'ended': icRapidCompleted
  },
  'released': {
      'WPH1': icon_t_released,
      'WPH2': icon_m_released,
      'RESD': icon_l_released,
      'BUSN': icon_l_released,
      'VOIP': icon_v_released,
      'TELE': icon_te_released,
      'CENT': icon_l_released,
      'COIN': icon_p_released,
      'WRLS': icon_o_released,
      'UNK': icon_o_released,
      'default': icon_o_released
  },
  'multiple': {
      'WPH1': icon_t_multi_ringing,
      'WPH2': icon_m_multi_ringing,
      'RESD': icon_l_multi_ringing,
      'BUSN': icon_l_multi_ringing,
      'VOIP': icon_v_multi_ringing,
      'TELE': icon_te_multi_ringing,
      'CENT': icon_l_multi_ringing,
      'COIN': icon_p_multi_ringing,
      'WRLS': icon_o_multi_ringing,
      'UNK': icon_o_multi_ringing,
      'default': icon_o_multi_ringing
  }
}
export const notificationConfig = {
  notificationPolicy: 'IotNotificationPolicy',
  notificationClientIdSuffix: 'webapp:',
  notificationReconnectTime: 5000,
}
export const transcriptStatus = {
  started: 'STARTED',
  ended: 'ENDED',
}