import CallsRoute from "../../../routes/CallsRoute";
import SolacomRoute from "../../../routes/SolacomRoute";
import { SolacomGroup } from "../../../utilities/Utils";

function CallsComponent(props) {
    return(
        <>{SolacomGroup() ? <SolacomRoute /> : <CallsRoute /> }</>
    )
}

export default CallsComponent;