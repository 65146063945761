import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/styles/index.scss';
import App from './App';
import { IconContext } from "react-icons";
import { Provider } from 'react-redux';
import Store from '../src/redux/store';
import reportWebVitals from './reportWebVitals';
import { NetworkProvider } from "sa-common/context/NetworkContext";
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { configTimers } from './constants/ConfigTimers';


const nonceValue = 'bXlzZWNyZXRub25jZTIx';
const cache = createCache({
  key: "nonce-key",
  nonce: nonceValue,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <IconContext.Provider value={{ className: 'react-icons' }}>
    <Provider store={Store}>
      <CacheProvider value={cache}>
        <NetworkProvider pingInterval={configTimers.pingInterval}>
          <App />
        </NetworkProvider>
      </CacheProvider>
    </Provider>
  </IconContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
