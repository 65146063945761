import React from 'react';
import { useNavigate } from "react-router-dom";
import { getUserName } from "../../../utilities/Utils";
import Dropdown from "react-bootstrap/Dropdown";
import { ROUTE_ENDPOINTS } from "../../../constants/RouteConstants";

function UserIcon(props) {
    const navigate = useNavigate();
    const handleSignOut = () => {
        props.handleSignOut();
    }

    const navigateToAccounts = () => navigate(ROUTE_ENDPOINTS.ACCOUNT);
    const navigateToAbout = () => navigate(ROUTE_ENDPOINTS.ABOUT);
    const navigateToDocs = () => navigate(ROUTE_ENDPOINTS.DOCUMENTATION);
    const navigateToSettings = () => navigate(ROUTE_ENDPOINTS.SETTINGS);


    return (
        <>
            <Dropdown className='hand-cursor nav-list'>
                <Dropdown.Toggle drop='left' as="div" className="profile-img d-flex align-items-center">
                    {getUserName()}
                    <span className='user-name-icon'>{getUserName().charAt(0)}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu >
                    {/* <Dropdown.Item onClick={navigateToAccounts}>
                        Account
                    </Dropdown.Item>
                    <Dropdown.Item onClick={navigateToDocs}>
                        Documentation
                    </Dropdown.Item>
                    <Dropdown.Item onClick={navigateToAbout}>About</Dropdown.Item> */}
                    <Dropdown.Item onClick={navigateToSettings}>Settings</Dropdown.Item>
                    <Dropdown.Item onClick={handleSignOut}>
                        Sign Out
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default UserIcon;