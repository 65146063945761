import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import '../styles/calls.scss';
import { MapContext } from '../context/MapContext';
import SolacomComponent from 'sa-common/calls/SolacomComponent';
import { connect } from 'react-redux';
import { setSimulationEnabled, getStationIdFromLocalStorage, isFeatureAllowed, findDefaultRoute, isSecondaryMapAvailable } from '../utilities/Utils';
import { callConfigs, markerImages, featureLabels, groupLocations, solacomMarkerImages, transcriptStatus } from '../constants/Constants';
import turfBuffer from '@turf/buffer';
import turfBbox from '@turf/bbox';
import turfNearestPointOnLine from "@turf/nearest-point-on-line";
import { createRoot } from 'react-dom/client';
import { createGeoJSONCircle } from 'sa-common/utils/CommonUtils';

var turfPoint = require("turf-point");
var markers = [];
var rapidSOSMarkers = [];
var markersOnSM = [];
var rapidSOSMarkersOnSM = [];
var cylinderLayers = [];
var rapidSOSDottedLineLayers = [];
var unCertaintyLayers = [];
let dottedLineId = 'dotCircles';
let unCertaintyCircleId = 'liveCircle';
let unCertaintyCircleBorder = 'liveBorder';
var breadcrumbLayers = [];
var rapidSOSBreadcrumbLayers = [];
var rapidSOSCircleLayers = [], renderContour = false;
var solacomBounds = [],satSolacomBounds = [], solacomPopup, solacomOtherAnsweredBounds = [], satSolacomOtherAnsweredBounds = [];
var endCallMarkers = [];
var satEndCallMarkers = [];

function SolcaomRoute(props) {
    const prevMapUrlRef = useRef(null);
    const navigate = useNavigate();
    const { mapContainer, mapRef, mapLoadStatus, secondMapContainer, locateMeClick } = useContext(MapContext);
    const [contourLineProperties, setContourLineProperties] = useState(0);
    const [selectedEndCallId, setSelectedEndCallId] = useState(null);

    useEffect(() => {
        setSimulationEnabled();
    }, [])

    useEffect(() => {
        if (mapContainer && mapRef.current) {
            if (props.solacomData || props.callsInfo) {
                drawCallMarker()
            } else {
                mapContainer.jumpTo(groupLocations.default.center, groupLocations.default.zoom, mapContainer)
                if (isSecondaryMapAvailable())
                    mapContainer.jumpTo(groupLocations.default.center, groupLocations.default.zoom, secondMapContainer)
            }
        }
    }, [mapContainer, mapRef]);

    useEffect(() => {
        if (props.mapUrl && mapLoadStatus) {
          if (!prevMapUrlRef.current) {
            prevMapUrlRef.current = props.mapUrl; // Update reference
          } else if (props.mapUrl !== prevMapUrlRef.current) {
            prevMapUrlRef.current = props.mapUrl; // Update reference
            drawCallMarker()
          }
        }
      }, [mapLoadStatus])
    
      useEffect(() => {
        if(locateMeClick)
        locateMeClickEvent();
      }, [locateMeClick]);
    
      useEffect(() => {
        if (props.solacomData && mapContainer && mapRef.current) {
          drawCallMarker()
        }
      }, [props.solacomData, props.transcriptStatus])

      useEffect(() => {
        if (mapContainer && mapRef.current) {
          if(props.callsInfo){
            drawCallMarker()
          }else{
            removeAllLayers();
            drawCallMarker();
          }
        }
      }, [props.callsInfo])
    
      useEffect(() => {
        if (mapContainer && mapRef.current) {
          mapContainer.enableSplitScreen(props.isSplitScreen)
        }
      }, [props.isSplitScreen])
    
      useEffect(() => {
        if (mapContainer && mapRef.current && !isRapidSOSDataAvailable(props.callsInfo)) {
          hideRapidSOSLayers()
        } else if(rapidSOSMarkers && rapidSOSMarkers.length){
          showRapidSOSLayers()
        }
      }, [props.isRapiSOSEnable])
    
      useEffect(() => {
        if (mapContainer && mapRef.current && props.uncertainity) {
          showUncertainityLayers()
        } else if((rapidSOSCircleLayers && rapidSOSCircleLayers.length )|| (unCertaintyLayers && unCertaintyLayers.length)){
          hideUncertainityLayers()
        }
      }, [props.uncertainity])

      useEffect(() => {
        if (props.endCallsData && props.endCallsData.length > 0 && mapContainer && mapRef.current && props.transcriptStatus && props.transcriptStatus != '' && props.transcriptStatus == transcriptStatus.ended) {
          drawEndCallMarkers()
        } else {
          removeEndCallMarkers()
        }
      }, [props.endCallsData]) 

    const drawCallMarker = () => {
        if ((props.solacomData || props.callsInfo) && mapContainer && mapRef.current && props.transcriptStatus && props.transcriptStatus != '' && props.transcriptStatus == transcriptStatus.started) {
            solacomBounds = [];
            satSolacomBounds = [];
            solacomOtherAnsweredBounds = [];
            satSolacomOtherAnsweredBounds = [];
            removeAllLayers();
            let queuedCalls = props.solacomData.queuedCalls;
            let answeredCalls = props.solacomData.answeredCalls;
            if (props.callsInfo && props.callsInfo.geometry && props.callsInfo.geometry.coordinates && props.callsInfo.geometry.coordinates.length) {
                drawMarker(props.callsInfo, props.callsInfo.classOfService, 0, callConfigs.defaultZoom, mapContainer, 'activeCall');
                if (isSecondaryMapAvailable())
                    drawMarker(props.callsInfo, props.callsInfo.classOfService, callConfigs.satelliteMapPitch, callConfigs.satelliteMapZoom, secondMapContainer, 'activeCall');
            }
            if (queuedCalls && queuedCalls.length) {
                queuedCalls.map((call, ind) => {
                    createQueuedMarkers(call, call.classOfService, mapContainer, 'queued' + ind);
                    if (isSecondaryMapAvailable())
                        createQueuedMarkers(call, call.classOfService, secondMapContainer, 'queued' + ind);
                })
            }
            if (answeredCalls && answeredCalls.length) {
                answeredCalls.map((call, ind) => {
                    createOtherAnsweredMarkers(call, call.classOfService, mapContainer, 'queued' + ind);
                    if (isSecondaryMapAvailable())
                        createOtherAnsweredMarkers(call, call.classOfService, secondMapContainer, 'queued' + ind);
                })
            }
            if (solacomBounds.length) {
                mapContainer.setBounds(solacomBounds, mapContainer);
                if (isSecondaryMapAvailable())
                    mapContainer.setBounds(solacomBounds, secondMapContainer);
            }
            else {
                mapContainer.jumpTo(groupLocations.default.center, groupLocations.default.zoom, mapContainer);
                if (isSecondaryMapAvailable())
                    mapContainer.jumpTo(groupLocations.default.center, groupLocations.default.zoom, secondMapContainer);
            }
        }
    }

    const createQueuedMarkers = (obj, classOfService, container, type) => {
        if (mapContainer && container && obj.geometry && obj.geometry.coordinates && obj.callState !== callConfigs.ended) {
            var coorLength = obj.geometry.coordinates.length;
            if (coorLength && obj.geometry.coordinates[coorLength - 1].length) {
                let el = document.createElement('div');
                let options = { element: el, anchor: 'bottom' };
                let lngLat = mapContainer.createLngLatObjFrom(obj.geometry.coordinates[obj.geometry.coordinates.length - 1][0], obj.geometry.coordinates[obj.geometry.coordinates.length - 1][1])
                let marker = mapContainer.drawMarker(options, lngLat, container);
                if (obj.eventType === 'Position' || obj.eventType === 'Route') {
                    el.className = 'incident-marker overlay-index solacom-marker';
                    if (obj.eventType === 'Position') {
                        if (solacomMarkerImages.waiting[classOfService]) {
                            el.style.backgroundImage = `url(${solacomMarkerImages.waiting[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${solacomMarkerImages.waiting['default']})`;
                        }
                    }
                    else if (obj.eventType === 'Route') {
                        if (obj.agents.length > 1) {
                            if (solacomMarkerImages.multiple[classOfService]) {
                                el.style.backgroundImage = `url(${solacomMarkerImages.multiple[classOfService]})`;
                            }
                            else {
                                el.style.backgroundImage = `url(${solacomMarkerImages.multiple['default']})`;
                            }
                        }
                        else {
                            if (solacomMarkerImages.ringing[classOfService]) {
                                el.style.backgroundImage = `url(${solacomMarkerImages.ringing[classOfService]})`;
                            }
                            else {
                                el.style.backgroundImage = `url(${solacomMarkerImages.ringing['default']})`;
                            }
                        }
                    }
                    el.addEventListener('mouseenter', solacomHoverPopup.bind(this, lngLat, obj, container));
                    el.addEventListener('mouseleave', removeSolacomHoverPopup.bind(this, container));
                }
                addBreadCrumb(obj.geometry.coordinates, '#888', container, type);
                if (container === mapContainer) {
                    solacomBounds.push(lngLat);  
                    markers.push(marker);
                } else if (container === secondMapContainer) {
                    satSolacomBounds.push(lngLat);  
                    markersOnSM.push(marker);
                }
            }
        }
    }

    const createOtherAnsweredMarkers = (obj, classOfService, container, type) => {
        if (mapContainer && container && obj.geometry && obj.geometry.coordinates && obj.callState !== callConfigs.ended) {
            var coorLength = obj.geometry.coordinates.length;
            if (coorLength && obj.geometry.coordinates[coorLength - 1].length) {
                let el = document.createElement('div');
                let options = { element: el, anchor: 'bottom' };
                let lngLat = mapContainer.createLngLatObjFrom(obj.geometry.coordinates[obj.geometry.coordinates.length - 1][0], obj.geometry.coordinates[obj.geometry.coordinates.length - 1][1])
                let marker = mapContainer.drawMarker(options, lngLat, container);
                if((obj.callStateExt === callConfigs.transfer && obj.callState === callConfigs.active) || (obj.callStateExt === '' && obj.callState === callConfigs.active) || obj.callState === callConfigs.release){ 
                    el.className = 'incident-marker overlay-index other-agent-markers';                 
                    if (obj.callStateExt === '' && obj.callState === callConfigs.active) {
                        if (solacomMarkerImages.activeMarkers[classOfService]) {
                            el.style.backgroundImage = `url(${solacomMarkerImages.activeMarkers[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${solacomMarkerImages.activeMarkers['default']})`;
                        }
                    }
                    if (obj.callStateExt === callConfigs.transfer && obj.callState === callConfigs.active) {  
                        if (solacomMarkerImages.transferred[classOfService]) {
                            el.style.backgroundImage = `url(${solacomMarkerImages.transferred[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${solacomMarkerImages.transferred['default']})`;
                        }
                    }
                    if (obj.callState === callConfigs.release) {
                        if (solacomMarkerImages.released[classOfService]) {
                            el.style.backgroundImage = `url(${solacomMarkerImages.released[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${solacomMarkerImages.released['default']})`;
                        }
                    }
                    if (obj.eventType !== callConfigs.release) {
                        if (container === mapContainer) {
                            solacomOtherAnsweredBounds.push(lngLat);
                        } else if (container === secondMapContainer) {
                            satSolacomOtherAnsweredBounds.push(lngLat);  
                        }
                    }
                    el.addEventListener('mouseenter', solacomOtherHoverPopup.bind(this, lngLat, obj, container));
                    el.addEventListener('mouseleave', removeSolacomHoverPopup.bind(this, container));
                }
                addBreadCrumb(obj.geometry.coordinates, '#888', container, type);
                if (container === mapContainer) {
                    markers.push(marker);
                } else if (container === secondMapContainer) {
                    markersOnSM.push(marker);
                }
            }
        }
    }

    const drawMarker = (obj, classOfService, pitch, zoomLevel, container, type) => {
        if (mapContainer && container && obj.geometry && obj.geometry.coordinates && obj.callState !== callConfigs.ended) {
            var coorLength = obj.geometry.coordinates.length;
            if (coorLength && obj.geometry.coordinates[coorLength - 1].length) {
                let el = document.createElement('div');
                el.addEventListener('click', activeMarkerClickEvent.bind(this, container, pitch, zoomLevel));
                let options = { element: el, anchor: 'bottom' };
                let lngLat = mapContainer.createLngLatObjFrom(obj.geometry.coordinates[obj.geometry.coordinates.length - 1][0], obj.geometry.coordinates[obj.geometry.coordinates.length - 1][1])
                let marker = mapContainer.drawMarker(options, lngLat, container);              
                el.className = 'incident-marker overlay-index solacom-marker';
                if (obj.callStateExt === '' && obj.callState === callConfigs.active) {
                    if (solacomMarkerImages.activeMarkers[classOfService]) {
                        el.style.backgroundImage = `url(${solacomMarkerImages.activeMarkers[classOfService]})`;
                    }
                    else {
                        el.style.backgroundImage = `url(${solacomMarkerImages.activeMarkers['default']})`;
                    }
                }
                if (obj.callStateExt === callConfigs.transfer && obj.callState === callConfigs.active) {  
                    if (solacomMarkerImages.transferred[classOfService]) {
                        el.style.backgroundImage = `url(${solacomMarkerImages.transferred[classOfService]})`;
                    }
                    else {
                        el.style.backgroundImage = `url(${solacomMarkerImages.transferred['default']})`;
                    }
                }
                if (obj.callState === callConfigs.release) {
                    if (solacomMarkerImages.released[classOfService]) {
                        el.style.backgroundImage = `url(${solacomMarkerImages.released[classOfService]})`;
                    }
                    else {
                        el.style.backgroundImage = `url(${solacomMarkerImages.released['default']})`;
                    }
                }
                el.addEventListener('mouseenter', solacomHoverPopup.bind(this, lngLat, obj, container));
                el.addEventListener('mouseleave', removeSolacomHoverPopup.bind(this, container));
                addBreadCrumb(obj.geometry.coordinates, '#888', container, type);
                if (props.uncertainity && obj.radius) {
                    let layers = [];
                    layers.push(unCertaintyCircleId, unCertaintyCircleBorder);
                    unCertaintyLayers = layers;
                    mapContainer.addCircle(container, [Number(obj.geometry.coordinates[obj.geometry.coordinates.length - 1][0]),
                    Number(obj.geometry.coordinates[obj.geometry.coordinates.length - 1][1])], (obj.radius / 1000), unCertaintyCircleId, unCertaintyCircleBorder);
                }
                createRapidSOSMarker(obj, container);
                addDotedLine(obj, container)
                if (container === mapContainer) {
                    solacomBounds.push(lngLat);  
                    markers.push(marker);
                } else if (container === secondMapContainer) {
                    satSolacomBounds.push(lngLat);  
                    markersOnSM.push(marker);
                }
            }
        }
    }

    const addBreadCrumb = (coordinates, circleColor, container, type) => {
        if (coordinates.length > 1) {
            let layers = breadcrumbLayers;
            let circleId = 'initCircle'+type;
            let lineId = 'circles'+type;
            let polylineId = 'polyline'+type;
            layers.push(lineId, circleId, polylineId);
            breadcrumbLayers = layers;
            mapContainer.addBreadCrumb(container, coordinates, circleColor, circleId, lineId, polylineId)
        }
    }

    const addRapidSOSBreadCrumb = (coordinates, circleColor, container) => {
        let layers = rapidSOSBreadcrumbLayers;
        let circleId = 'sosinitCircle';
        let lineId = 'soscircles';
        let polylineId = 'sospolyline';
        layers.push(lineId, circleId, polylineId);
        rapidSOSBreadcrumbLayers = layers;
        mapContainer.addBreadCrumb(container, coordinates, circleColor, circleId, lineId, polylineId)
    }

    const createRapidSOSMarker = (obj, container) => {
        try {
            if (isRapidSOSDataAvailable(obj)) {
                let sosElement = document.createElement('div');
                sosElement.className = 'incident-marker incident-marker-sos call-icon-overlay';
                sosElement.style.backgroundImage = `url(${markerImages.activeMarkers['RAPIDSOS']})`;
                let sosOptions = { element: sosElement, anchor: 'bottom' };
                let rapidSOSlngLat = mapContainer.createLngLatObjFrom(obj.rapidSOS.geometry.coordinates[obj.rapidSOS.geometry.coordinates.length - 1][0], obj.rapidSOS.geometry.coordinates[obj.rapidSOS.geometry.coordinates.length - 1][1]);
                let rapidSOSMarker = mapContainer.drawMarker(sosOptions, rapidSOSlngLat, container)
                addRapidSOSBreadCrumb(obj.rapidSOS.geometry.coordinates, '#ea0e0e', container);
                renderContour = false;
                if (container === mapContainer) {
                    rapidSOSMarkers.push(rapidSOSMarker);
                    mapRef.current.once('idle', () => {
                        if (!renderContour) {
                            drawCylinder();
                        }
                        mapRef.current.on('zoom', () => {
                            if (!renderContour) {
                                drawCylinder();
                            }
                        });
                    })
                } else if (container === secondMapContainer) {
                    rapidSOSMarkersOnSM.push(rapidSOSMarker);
                    addRapidSOSCircle(secondMapContainer, obj.rapidSOS.geometry, obj.rapidSOS.radius / 1000);
                }
            }
        } catch (error) {
            console.log("::::::::: error createRapidSOSMarker ::::::::::: ")
        }
    }

    const isRapidSOSDataAvailable = (obj) => {
        if (props.isRapiSOSEnable && obj && obj.hasOwnProperty('rapidSOS') && obj.rapidSOS && obj.rapidSOS.geometry && obj.rapidSOS.geometry.coordinates && obj.rapidSOS.geometry.coordinates.length) {
            return true;
        } else {
            return false;
        }
    }

    const activeMarkerClickEvent = (map, pitch, zoomLevel) => {
        if (mapContainer && props.callsInfo && props.callsInfo.geometry && props.callsInfo.geometry.coordinates) {
            const lngLat = mapContainer.createLngLatObjFrom(props.callsInfo.geometry.coordinates[props.callsInfo.geometry.coordinates.length - 1][0], props.callsInfo.geometry.coordinates[props.callsInfo.geometry.coordinates.length - 1][1])
            mapContainer.jumpTo(lngLat, callConfigs.defaultZoom, mapContainer)
            if (isSecondaryMapAvailable())
                mapContainer.jumpTo(lngLat, callConfigs.satelliteMapZoom, secondMapContainer)
        } else {
            mapContainer.jumpTo(groupLocations.default.center, callConfigs.defaultZoom, mapContainer)
            if (isSecondaryMapAvailable())
                mapContainer.jumpTo(groupLocations.default.center, callConfigs.satelliteMapZoom, secondMapContainer)
        }
    }

    const locateMeClickEvent = () => {
        if(solacomOtherAnsweredBounds.length && solacomBounds.length){
            solacomBounds.push(...solacomOtherAnsweredBounds);
        }
        if(solacomBounds.length){
            mapContainer.setBounds(solacomBounds, mapContainer);
            if (isSecondaryMapAvailable())
                mapContainer.setBounds(solacomBounds, secondMapContainer);
        }
        else{
            mapContainer.jumpTo(groupLocations.default.center, groupLocations.default.zoom, mapContainer);
            if (isSecondaryMapAvailable())
                mapContainer.jumpTo(groupLocations.default.center, groupLocations.default.zoom, secondMapContainer)
        }
    }

    const addDotedLine = (obj, map) => {
        try {
            if (map && isRapidSOSDataAvailable(obj) && obj.geometry && obj.geometry.coordinates && obj.geometry.coordinates.length) {
                let layers = rapidSOSDottedLineLayers;
                layers.push(dottedLineId);
                rapidSOSDottedLineLayers = layers;
                mapContainer.drawDotedLine(dottedLineId, [obj.rapidSOS.geometry.coordinates[obj.rapidSOS.geometry.coordinates.length - 1], obj.geometry.coordinates[obj.geometry.coordinates.length - 1]], "#3d81c3")
            }
        } catch (error) {
            console.log("::::::::: error addDotedLine ::::::::::: ")
        }
    }

    const hideRapidSOSLayers = () => {
        rapidSOSMarkers.forEach(marker => {
            marker.remove();
        });
        rapidSOSMarkersOnSM.forEach(marker => {
            marker.remove();
        });
        rapidSOSDottedLineLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, false));
        rapidSOSBreadcrumbLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, false));
        if (isSecondaryMapAvailable()) {
            rapidSOSDottedLineLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, false));
            rapidSOSBreadcrumbLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, false));
        }
        cylinderLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, false));
        hideRapidSOSUncertainityLayers(mapContainer);
        if (isSecondaryMapAvailable())
            hideRapidSOSUncertainityLayers(secondMapContainer);
    }

    const showRapidSOSLayers = () => {
        rapidSOSMarkers.forEach(marker => {
            mapContainer.addMarkerOnMap(marker);
        });
        rapidSOSMarkersOnSM.forEach(marker => {
            mapContainer.addMarkerOnSecondMap(marker);
        });
        rapidSOSDottedLineLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, true));
        rapidSOSBreadcrumbLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, true));
        if (isSecondaryMapAvailable()) {
            rapidSOSDottedLineLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, true));
            rapidSOSBreadcrumbLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, true));
        }
        cylinderLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, true));
        showUncertainityLayers();
    }

    const showUncertainityLayers = () => {
        if (mapContainer && props.uncertainity) {
            rapidSOSCircleLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, true));
            unCertaintyLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, true));
            if (isSecondaryMapAvailable()) {
                rapidSOSCircleLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, true));
                unCertaintyLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, true));
            }
        }
    }

    const hideUncertainityLayers = () => {
        rapidSOSCircleLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, false));
        unCertaintyLayers.forEach(layer => mapContainer.handleLayerVisibility(mapContainer, layer, false));
        if (isSecondaryMapAvailable()) {
            rapidSOSCircleLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, false));
            unCertaintyLayers.forEach(layer => mapContainer.handleLayerVisibility(secondMapContainer, layer, false));
        }
    }

    const hideRapidSOSUncertainityLayers = (container) => {
        for (var i = 0; i < rapidSOSCircleLayers.length; i++) {
            mapContainer.handleLayerVisibility(container, rapidSOSCircleLayers[i], false);
        }
    }

    const removeAllLayers = () => {
        removeSolacomHoverPopup();
        // Remove all markers from both maps
        markers = removeAll(markers, marker => marker.remove());
        markersOnSM = removeAll(markersOnSM, marker => marker.remove());
        rapidSOSMarkers = removeAll(rapidSOSMarkers, marker => marker.remove());
        rapidSOSMarkersOnSM = removeAll(rapidSOSMarkersOnSM, marker => marker.remove());

        // Remove all layers
        rapidSOSDottedLineLayers = removeAll(rapidSOSDottedLineLayers, layer => mapContainer.removeLayer(layer));
        rapidSOSCircleLayers = removeAll(rapidSOSCircleLayers, layer => mapContainer.removeLayer(layer));
        unCertaintyLayers = removeAll(unCertaintyLayers, layer => mapContainer.removeLayer(layer));
        cylinderLayers = removeAll(cylinderLayers, layer => mapContainer.removeLayer(layer));
        rapidSOSBreadcrumbLayers = removeAll(rapidSOSBreadcrumbLayers, layer => mapContainer.removeLayer(layer));
        breadcrumbLayers = removeAll(breadcrumbLayers, layer => mapContainer.removeLayer(layer));
    }

    // Function to remove all items from a collection and then reset the collection
    const removeAll = (collection, removeMethod) => {
        collection.forEach(item => removeMethod(item));
        return [];
    };

    const addRapidSOSCircle = (container, geometry, radius) => {
        try {
            if (mapContainer && geometry.coordinates && geometry.coordinates.length) {
                var allLayers = mapContainer.getMapStyles();
                var cameraLayersId;
                // for (var i = 0; i < allLayers.length; i++) {
                //   if (allLayers[i].id === "geofence-camera" || allLayers[i].id === "camera" ||
                //     allLayers[i].id === "selected_camera" || allLayers[i].id === "dotCircles" || allLayers[i].id === "sosinitCircle" || allLayers[i].id === "soscircles"
                //     || allLayers[i].id === "sospolyline") {
                //     cameraLayersId = allLayers[i].id;
                //     break;
                //   }
                // }
                let layers = [];
                let circleId = 'sosliveCircle';
                let circleBorder = 'sosliveBorder';
                layers.push(circleId, circleBorder);
                rapidSOSCircleLayers = layers;
                mapContainer.addCircle(container, [Number(geometry.coordinates[geometry.coordinates.length - 1][0]), Number(geometry.coordinates[geometry.coordinates.length - 1][1])], radius, circleId, circleBorder);

            }
        } catch (error) {
            console.log("::::::::: error addRapidSOSCircle ::::::::::: ")
        }
    }

    const prepareProperties = (baseHeight, height, color) => {
        return {
            name: "3D Cylynder",
            height: height,
            base_height: baseHeight,
            color: color,
        };
    }

    const getNearestContourLine = (coords) => {
        var point = turfPoint(coords);
        var buffered = turfBuffer(point, callConfigs.contourLineDistance, {
            units: "miles",
        });
        var coordinate1, coordinate2, closest, lineCoords = [];
        if (buffered) {
            var bbox = turfBbox(buffered);
            coordinate1 = mapContainer.mapProject(bbox[0], bbox[1]);
            coordinate2 = mapContainer.mapProject(bbox[2], bbox[3]);
            var features = mapContainer.getFeatures([coordinate1, coordinate2]);
            if (features) {
                features.forEach((feature, index) => {
                    if (
                        feature &&
                        feature.sourceLayer &&
                        feature.sourceLayer.toLowerCase() === callConfigs.contourLayer
                    ) {
                        var snapped = turfNearestPointOnLine(feature, point, {
                            units: "miles",
                        });
                        if (!closest) {
                            closest = {};
                            closest.calcData = snapped;
                            closest.feature = feature;
                        } else if (
                            closest.calcData.properties.dist > snapped.properties.dist
                        ) {
                            closest.calcData = snapped;
                            closest.feature = feature;
                        }
                    }
                });
            }
        }
        return closest;
    }

    const drawCylinder = () => {
        var rapidSOS = props.callsInfo.rapidSOS;
        try {
            let uncertyCylinder = callConfigs.uncertyCylinder;
            let zCylinder = callConfigs.zCylinder;
            if (mapContainer && rapidSOS && rapidSOS.geometry) {
                var allLayers = mapContainer.getMapStyles();
                var geometry = rapidSOS.geometry;
                var contourLayerExist = false;
                for (var i = 0; i < allLayers.length; i++) {
                    if (allLayers[i].id.toLowerCase() == callConfigs.contourLayer) {
                        contourLayerExist = true;
                        break;
                    }
                }
                if (contourLayerExist) {
                    if (rapidSOS.altitude === null || rapidSOS.altitude === undefined || rapidSOS.altitude === 0) {
                        if (isRapidSOSDataAvailable(props.callsInfo) && props.uncertainity) {
                            addRapidSOSCircle(mapContainer, geometry, rapidSOS.radius / 1000);
                        }
                    } else {
                        cylinderLayers.push(uncertyCylinder, zCylinder);
                        mapContainer.uncertainityCylinder(uncertyCylinder);
                        mapContainer.zAxisCylinder(zCylinder);
                        let uncertyData = {
                            features: [],
                            type: "FeatureCollection",
                        };

                        let zData = {
                            features: [],
                            type: "FeatureCollection",
                        };

                        let coordinates =
                            geometry.coordinates[geometry.coordinates.length - 1];
                        let closestContourLine = getNearestContourLine(coordinates);

                        let circleCoordinates = createGeoJSONCircle(
                            coordinates,
                            rapidSOS.radius / 1000
                        );
                        let feature = circleCoordinates.data.features[0];
                        let uncertyFeature = JSON.parse(JSON.stringify(feature));
                        let zFeature = JSON.parse(JSON.stringify(feature));
                        if (closestContourLine && rapidSOS.altitude) {
                            hideRapidSOSUncertainityLayers(mapContainer);
                            if (isFeatureAllowed(featureLabels.z_axis)) {
                                console.log("closestContourLine");
                                setContourLineProperties(closestContourLine);
                                uncertyFeature.properties = prepareProperties(
                                    parseFloat(rapidSOS.altitude * callConfigs.meterToFeet) -
                                    parseFloat(closestContourLine.feature.properties.topo_elev) -
                                    parseFloat(closestContourLine.feature.properties.H ? closestContourLine.feature.properties.H : 0),
                                    parseFloat(rapidSOS.altitude * callConfigs.meterToFeet) -
                                    parseFloat(closestContourLine.feature.properties.topo_elev) -
                                    parseFloat(closestContourLine.feature.properties.H ? closestContourLine.feature.properties.H : 0) +
                                    parseFloat((rapidSOS.zuncer ? rapidSOS.zuncer : 0) * callConfigs.meterToFeet),
                                    "orange"
                                );
                                zFeature.properties = prepareProperties(
                                    parseFloat(rapidSOS.altitude * callConfigs.meterToFeet) -
                                    parseFloat(closestContourLine.feature.properties.topo_elev) -
                                    parseFloat(closestContourLine.feature.properties.H ? closestContourLine.feature.properties.H : 0) -
                                    parseFloat((rapidSOS.zuncer ? rapidSOS.zuncer : 0) * callConfigs.meterToFeet),
                                    parseFloat(rapidSOS.altitude * callConfigs.meterToFeet) -
                                    parseFloat(closestContourLine.feature.properties.topo_elev) -
                                    parseFloat(closestContourLine.feature.properties.H ? closestContourLine.feature.properties.H : 0),
                                    "grey"
                                );

                                uncertyData.features.push(uncertyFeature);
                                zData.features.push(zFeature);
                                mapContainer.getSetData(uncertyCylinder, uncertyData);
                                mapContainer.getSetData(zCylinder, zData);
                            }
                            else {
                                setContourLineProperties(0);
                                if (isRapidSOSDataAvailable(props.callsInfo) && props.uncertainity) {
                                    addRapidSOSCircle(mapContainer, geometry, rapidSOS.radius / 1000);
                                }
                            }
                            renderContour = true;
                        } else {
                            renderContour = false;
                            setContourLineProperties(0);
                            if (isRapidSOSDataAvailable(props.callsInfo) && props.uncertainity) {
                                addRapidSOSCircle(mapContainer, geometry, rapidSOS.radius / 1000);
                            }
                        }
                    }
                } else {
                    setContourLineProperties(0);
                    if (isRapidSOSDataAvailable(props.callsInfo) && props.uncertainity) {
                        addRapidSOSCircle(mapContainer, geometry, rapidSOS.radius / 1000);
                    }
                }
            }

        } catch (error) {
            console.log("::::::::: error drawcylinder ::::::::::: ")
        }
    }

    const solacomHoverPopup = (lngLat, data, map) => {
        var content = createStLayerContent(data);
        var offsetHeight;
        if (data.eventType === 'Route' || data.eventType === 'TransferCall') {
            offsetHeight = [0, -69];
        }
        else {
            offsetHeight = [0, -50];
        }
        let options = { offset: offsetHeight, className: "massgis-poi solacom-popup" };
        solacomPopup = mapContainer.drawPopup(options, lngLat, content, map);
    }

    const createStLayerContent = (data) => {
        const placeholder = document.createElement('div');
        const jsx = <div className='poi-container'>
            {
                <div>
                    <div><span className="street-label">Ani: </span>{data.ani}</div>
                    <div><span className="street-label">Caller Name: </span>{data.callerName}</div>
                    {data.agents && data.agents.length ?
                        <div className='d-flex'>
                            <div className="street-label">Agent(s): </div>
                            {data.agents && data.agents.length ?
                                data.agents.map((obj, i) => {
                                    return (
                                        <span key={obj.agent} className='pl-1'>{i + 1 !== data.agents.length ? obj.agent + `, ` : obj.agent}</span>
                                    )
                                }) : ''}
                        </div>
                        : ''}
                    {data.routesList && data.routesList.length ?
                        <div className='d-flex'>
                            <div className="street-label">Routings: </div>
                            {data.routesList.map((route, i) => {
                                return (
                                    <span key={route.rule} className='pl-1'>{i + 1 !== data.routesList.length ? route.rule + `, ` : route.rule}</span>
                                )
                            })}
                        </div>
                        : ''}
                    {data.transfer && data.transfer.target ?
                        <div className='d-flex'>
                            <div className="street-label">Transfer Target: </div>
                            <span className='pl-1'>{data.transfer.target}</span>
                        </div>
                        : ''}
                </div>
            }
        </div>
        var root = createRoot(placeholder);
        root.render(jsx);
        return placeholder
    }

    const removeSolacomHoverPopup = () => {
        if (solacomPopup) {
            solacomPopup.remove();
        }
    }

    const solacomOtherHoverPopup = (lngLat, data, map) => {
        var content = createStLayerContent(data);
        var offsetHeight;
        if (data.eventType === 'TransferCall') {
            offsetHeight = [0, -43];
        }
        else {
            offsetHeight = [0, -30];
        }
        let options = { offset: offsetHeight, className: "massgis-poi solacom-popup" };
        if (map === mapContainer) {
            solacomPopup = mapContainer.drawPopup(options, lngLat, content, map);
        } else if (map === secondMapContainer) {
            solacomPopup = mapContainer.drawPopup(options, lngLat, content, secondMapContainer);
        }
    }

    if (!isFeatureAllowed(featureLabels.call)) navigate(findDefaultRoute());

    const handleEndCallClick = (callData) => {
        // removeEndCallMarkers();
        let selectedNenaID = (selectedEndCallId !== callData.nenaIncidentId) ? callData.nenaIncidentId : ''
        setSelectedEndCallId(selectedNenaID);
        drawEndCallMarkers(selectedNenaID)
      };
      const drawEndCallMarkers = (id) => {
        if (props.endCallsData && props.endCallsData.length > 0 && mapContainer && mapRef.current) {
          removeEndCallMarkers()
          createEndCallsMarkers(0, callConfigs.defaultZoom, mapContainer, id)
          if (isSecondaryMapAvailable())
            createEndCallsMarkers(callConfigs.satelliteMapPitch, callConfigs.satelliteMapZoom, secondMapContainer, id)
        }
      }
      const createEndCallsMarkers = (pitch, zoomLevel, container, id) => {
        props.endCallsData.forEach((place, ind) => {
          if (place && place.geometry && place.geometry.coordinates && place.geometry.coordinates.length) {
            if (mapContainer && mapRef.current) {
              let el = document.createElement('div');
              el.addEventListener('click', handleEndCallClick.bind(this, place));
              el.className = 'incident-marker solacom-marker';
              if (id === place.nenaIncidentId) {
                el.className = 'incident-marker active-icon-overlay solacom-marker';
                if (solacomMarkerImages.selectMarkers[place.classOfService]) {
                  el.style.backgroundImage = `url(${solacomMarkerImages.selectMarkers[place.classOfService]})`;
                }
                else {
                  el.style.backgroundImage = `url(${solacomMarkerImages.selectMarkers['default']})`;
                }
                let lngLat = mapContainer.createLngLatObjFrom(place.geometry.coordinates[place.geometry.coordinates.length - 1][0], place.geometry.coordinates[place.geometry.coordinates.length - 1][1])
                mapContainer.jumpTo(lngLat, zoomLevel, container)
              }else {
                if (solacomMarkerImages.activeMarkers[place.classOfService]) {
                  if (place.callState === callConfigs.ended) {
                    el.style.backgroundImage = `url(${solacomMarkerImages.endedMarkers[place.classOfService]})`;
                  }
                } else {
                  if (place.callState === callConfigs.ended) {
                    el.style.backgroundImage = `url(${solacomMarkerImages.endedMarkers['default']})`;
                  }
                }
                if (id === '' && props.callsInfo && (props.callsInfo.callState === callConfigs.active || props.callsInfo.callState === callConfigs.release)) {
                    let lngLat = props.callsInfo.geometry.coordinates[props.callsInfo.geometry.coordinates.length - 1]
                    mapContainer.jumpTo(lngLat, zoomLevel, container)
                  }    
              }
              let options = { element: el, anchor: 'bottom' };
              const lngLat = mapContainer.createLngLatObjFrom(place.geometry.coordinates[place.geometry.coordinates.length - 1][0], place.geometry.coordinates[place.geometry.coordinates.length - 1][1])
              const marker = mapContainer.drawMarker(options, lngLat, container);
              el.addEventListener('mouseenter', solacomHoverPopup.bind(this, lngLat, place, container));
              el.addEventListener('mouseleave', removeSolacomHoverPopup.bind(this));
              if (container === mapContainer) {
                endCallMarkers.push(marker);
              } else if (container === secondMapContainer) {
                satEndCallMarkers.push(marker);
              }
            }
          }
        });
      }
      const removeEndCallMarkers = () => {
        endCallMarkers = removeAll(endCallMarkers, marker => marker.remove());
        satEndCallMarkers = removeAll(satEndCallMarkers, marker => marker.remove());
      }

    return (
        <>
            {(mapContainer && mapRef.current) ?
                <SolacomComponent transcriptStatus={props.transcriptStatus} stationId={getStationIdFromLocalStorage()} callData={(props.transcriptStatus && props.transcriptStatus != '' && props.transcriptStatus == transcriptStatus.started) ? props.callsInfo : null} solacomData={(props.transcriptStatus && props.transcriptStatus != '' && props.transcriptStatus == transcriptStatus.started)? props.solacomData : {}} showRapidSOS={props.isRapiSOSEnable}
                identity={props.personIdentityInfo} criminalData={props.criminalInfo} contourLineProperties={contourLineProperties} endCallsData={(props.transcriptStatus && props.transcriptStatus != '' && props.transcriptStatus == transcriptStatus.ended ) ? props.endCallsData : null} 
                handleEndCallClick={handleEndCallClick} selectedEndCallId={selectedEndCallId} />
                : ''}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        mapUrl: state.mapInfo ? state.mapInfo.mapUrl : null,
        callsInfo: state.callsInfo ? state.callsInfo.data : null,
        endCallsData: state.callsInfo ? state.callsInfo.endCallsData : [],
        isSplitScreen: state.mapInfo ? state.mapInfo.isSplitScreen : false,
        isRapiSOSEnable: state.mapInfo ? state.mapInfo.isRapiSOSEnable : false,
        uncertainity: state.mapInfo ? state.mapInfo.isUncertainity : true,
        solacomData: state.solacomData,
        socketData: state.socketData.socketData,
        transcriptStatus: state.transcriptStatus.transcriptStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SolcaomRoute);