import { createContext, useState, useEffect } from 'react';
import { oktaAuthClientObj } from '../presentation/login/okta/OktaLoginUtils';
import { configAuthType } from '../utilities/Utils';

console.log('configAuthType: ', configAuthType());

const authClientObj = oktaAuthClientObj(configAuthType());

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authClient, updateAuthClient] = useState(authClientObj);

  return (
    <AuthContext.Provider value={{ authClient, updateAuthClient }}>
      {children}
    </AuthContext.Provider>
  );
};
