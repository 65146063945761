import React, { useEffect, useState } from 'react';
import { isEmpty, isFeatureAllowed } from '../utils/CommonUtils';
import moment from "moment";
import { callConfigs, featureLabels } from '../constants/Constants';
import CallsIcons from './views/CallsIcons';
import RapidSOSView from './views/RapiSOSView';
import { BsChevronRight, BsChevronDown } from "react-icons/bs";
import { FaPause } from "react-icons/fa6";
import { MdCallEnd } from "react-icons/md";
import { FaMicrophoneSlash } from "react-icons/fa";
import { PiSpeakerSlashFill } from "react-icons/pi";
import TransferIcon from '../assets/transfer-call.png';
import './calls.scss';

var interval;

function CallsComponent(props) {
    const [displayDate, setDisplayDate] = useState(new Date());
    const [displayTimer, setDisplayTimer] = useState((callConfigs.minutes < 10 ? ('0' + callConfigs.minutes) : callConfigs.minutes) + ':' + (callConfigs.seconds < 10 ? '0' + callConfigs.seconds : callConfigs.seconds > 59 ? '00' : callConfigs.seconds));
    const [moreText, setMoreText] = useState(false);
    const [showPastResidences, setPastResidencies] = useState(false);
    const [showCriminalHistory, setCriminalHistory] = useState(false);

    useEffect(() => {
        if (props.callData) {
            startTimer();
        }
    }, [props.callData])

    const handleCallsData = () => {
        props.handleCallClick();
        setDisplayDate(new Date());
        setDisplayTimer('00:00');
        callConfigs.callstartTime = 0;
        callConfigs.seconds = 0;
        callConfigs.minutes = 0;
    }

    const startTimer = () => {
        if (props.callData) {
            clearInterval(interval);
            if (callConfigs.oldNinaId === '' || (callConfigs.oldNinaId !== props.callData.nenaIncidentId) || (callConfigs.oldNinaId === props.callData.nenaIncidentId && props.callData.callState === callConfigs.ended)) {
                let currentDateTime = new Date();
                callConfigs.oldNinaId = props.callData.nenaIncidentId;
                let diff = moment(new Date(currentDateTime)).diff(new Date(props.callData.connectTime));
                let dateObj = moment.duration(diff);
                callConfigs.seconds = dateObj._data.seconds;
                callConfigs.minutes = dateObj._data.minutes;
                handleTimer();
            }
            reset911call()
        }
    }

    const reset911call = () => {
        interval = setInterval(() => {
            let initCount = 0;
            callConfigs.callstartTime = callConfigs.callstartTime + 1;
            let timerSeconds = parseInt(callConfigs.seconds) + 1;
            callConfigs.seconds = timerSeconds === 60 ? initCount : timerSeconds;
            callConfigs.minutes = timerSeconds === 60 ? (parseInt(callConfigs.minutes) + 1) : parseInt(callConfigs.minutes);
            handleTimer();
        }, callConfigs.counterTime);
    }

    const handleTimer = () => {
        setDisplayTimer((callConfigs.minutes < 10 ? ('0' + callConfigs.minutes) : callConfigs.minutes) + ':' + (callConfigs.seconds < 10 ? '0' + callConfigs.seconds : callConfigs.seconds > 59 ? '00' : callConfigs.seconds));
    }

    const handleDisabilities = () => {
        setMoreText(moreText => !moreText);
    }

    const handlePastResidences = () => {
        setPastResidencies(showPastResidences => !showPastResidences);
    }

    const handleCriminalHistory = () => {
        setCriminalHistory(showCriminalHistory => !showCriminalHistory);
    }

    const handleEndCallClick = (callData) => {
        props.handleEndCallClick(callData)
    };

    return (
        <>
            <div className='calls-left-panel'>
                <div className='call-details'>
                    <span>Call Details</span>
                    <div className='d-flex align-items-center'>
                        <span className='station-label'>Position ID:</span> <span className='station-id'>{props.stationId}</span>
                    </div>
                </div>
                    <div className='calls-list'>
                    {props.callData ?
                        <>
                        <div className='py-2 mb-2'>{moment(displayDate).format('MM-DD-YYYY hh:mm:ss A')}</div>
                        <div className='d-flex align-items-center'>
                            <CallsIcons icon={props.callData.classOfService} handleCalls={handleCallsData} />
                            <div>
                                    <div className='service-name'>{props.callData.classOfService}</div>
                                <div className='call-mdn'><b>{props.callData.ani ?
                                    props.callData.ani.includes(';') ? props.callData.ani.split(';')[0].includes('+') ? <><div>{props.callData.ani.split(';')[0].slice(0, 2)} {props.callData.ani.split(';')[0].slice(2, props.callData.ani.split(';')[0].length).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</div><div>{props.callData.ani.split(';')[1]}</div></> :
                                        props.callData.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3') :
                                        props.callData.ani.includes('+') ? <span>{props.callData.ani.slice(0, 2)} {props.callData.ani.slice(2, props.callData.ani.length).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</span> :
                                            props.callData.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3') : ''
                                }</b></div>
                            </div>
                            <div className='event-icon small-no-bg speaker-slash'>
                                <PiSpeakerSlashFill color="#666" />
                            </div>
                        </div>
                            <div className='mt-2 d-flex align-items-center'>
                            <span className='display-counter'>{displayTimer}</span>
                                {/* <span className='call-btn'>In Progress</span> */}
                                <div className='event-icon answer'>
                                    <FaPause/>
                                </div>
                                <div className='event-icon transfer'>
                                    <img src={TransferIcon} className='transfer-icon'/>
                                </div>
                                <div className='event-icon release'>
                                    <MdCallEnd/>
                                </div>
                                <div className='event-icon small-no-bg microphone-slash'>
                                    <FaMicrophoneSlash color="#666" />
                                </div>
                            </div>
                        <div className='main-info my-3'>Main Information</div>
                            {props.callData.address ? <div className='mb-4'>
                            <div className='label'>Call Address:</div>
                            <div>{props.callData.address.address1}{isEmpty(props.callData.address.address1) && isEmpty(props.callData.address.address2) && isEmpty(props.callData.address.city || props.callData.address.state || props.callData.address.zip) ? ',' : ''}</div>
                            <div>{props.callData.address.address2}{isEmpty(props.callData.address.city && props.callData.address.address2) || isEmpty(props.callData.address.state && props.callData.address.address2) || isEmpty(props.callData.address.zip && props.callData.address.address2) ? ',' : ''}</div>
                            <div>{props.callData.address.city}{isEmpty(props.callData.address.city) && isEmpty(props.callData.address.state || props.callData.address.zip) ? ',' : ''} {props.callData.address.state}{isEmpty(props.callData.address.state && props.callData.address.zip) ? ',' : ''} {props.callData.address.zip}</div>
                        </div> : ''}
                            <div className='mb-3'>
                            {props.callData.geometry && props.callData.geometry.coordinates && props.callData.geometry.coordinates.length ?
                                    <>
                                        <div className='label'>Lat/Lon:</div>
                                        <div className='coordinates'>[{parseFloat(props.callData.geometry.coordinates[props.callData.geometry.coordinates.length - 1][1]).toFixed(6)}, {parseFloat(props.callData.geometry.coordinates[props.callData.geometry.coordinates.length - 1][0]).toFixed(6)}]</div>
                                    </>
                                    : 
                                    <><div className='label'>Lat/Lon:</div><div className='unknown-loc'>Location Unknown</div></>
                                    }
                        </div>
                            {props.callData.radius ? <div className='mb-3'>
                                <div className="label">UNC: </div> 
                                <div>{parseFloat(props.callData.radius * callConfigs.meterToFeet).toFixed() + ' ft' }</div>
                            </div> : ''}

                        {props.callData.disabilities ?
                                <div className='mb-3'>
                                <div className='label'>Disabilities: </div>
                                <div className='disability'>{props.callData.disabilities.length > 60 ?
                                    <span>{moreText ? props.callData.disabilities : props.callData.disabilities.slice(0, 60)} <span className='hand-cursor more-btn' onClick={handleDisabilities}>See {moreText ? 'Less' : 'More'}</span></span>
                                    : props.callData.disabilities}</div>
                            </div>
                            : ''}
                                {props.callData.serviceProvider ? 
                        <div className='my-3'>
                                <div className='text-left'><div className='label'>Provider ID:</div> <div color={'black'}>{props.callData.serviceProvider}</div></div>

                        </div>
                            : ''}
                        <div className='main-info add-info my-3'>Additional Information</div>
                        <div className='history-details'>
                                <RapidSOSView showRapidSOS={props.showRapidSOS} callData={props.callData} rapidSOSInfo={props.callData.rapidSOS} address={props.callData.rapisSOSAddress} contourLineProperties={props.contourLineProperties} />
                            {isFeatureAllowed(featureLabels.person) ?
                                <>
                                    <div className='history-list'>
                                        <div className='title'>
                                            <div className={`hand-cursor d-flex align-items-center justify-content-between ${showPastResidences ? 'active' : ''}`}
                                                onClick={handlePastResidences}>
                                                <span >Past Residences</span>
                                                {showPastResidences ?
                                                    <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                                    :
                                                    <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                                }
                                            </div>
                                        </div>
                                        {showPastResidences && props.identity && props.identity.length ?
                                            <div className='border-bottom pb-3'>
                                                {props.identity.map((list, ind) => {
                                                    return (
                                                        <div key={ind}>
                                                            {list.addresses.map((residence, key) => {
                                                                return (
                                                                    <div key={key} className='address-details'>
                                                                        <div className='label'>
                                                                            From {residence.firstDate.month + '/' + residence.firstDate.year} To {residence.lastDate.month + '/' + residence.lastDate.year}
                                                                        </div>
                                                                        <div>
                                                                            {residence.line1},
                                                                        </div>
                                                                        <div>{residence.city}, {residence.state} {residence.zip}</div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            : ''
                                        }
                                    </div>
                                    <div className='history-list'>
                                        <div className='title'>
                                            <div className={`hand-cursor d-flex align-items-center justify-content-between ${showCriminalHistory ? 'active' : ''}`}
                                                onClick={handleCriminalHistory}>
                                                <span>Criminal History</span>
                                                {showCriminalHistory ?
                                                    <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                                    :
                                                    <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                                }
                                            </div>
                                        </div>
                                        {showCriminalHistory && props.criminalData && props.criminalData.length ?
                                            <div className='border-bottom pb-3'>
                                                {props.criminalData.map((list, ind) => {
                                                    return (
                                                        <div key={ind}>
                                                            {list.offenses.offense.map((history, key) => {
                                                                return (
                                                                    <div key={key} className='address-details'>
                                                                        <div><span className='label'>Offence Description:</span> {history.offensedescription}</div>
                                                                        <div><span className='label'>Offence Type:</span> {history.casetype}</div>
                                                                        <div><span className='label'>Disposition Date :</span> {history.dispositiondate}</div>
                                                                        <div><span className='label'>Court :</span> {history.court}</div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </>
                                : ''
                            }
                        </div>
                        </>
                        : ''}
                        <>
                            {props.endCallsData && props.endCallsData.length ? (
                                <>
                                    <div className='main-info end-calls-info my-3'>Ended Calls</div>
                                    <div className="position-relative h-auto">
                                        {props.endCallsData
                                            .sort((a, b) => b.disconnectTime - a.disconnectTime)
                                            .map((callData, index) => (
                                                <div
                                                    key={index}
                                                    className={`end-call-details m-0 mb-2 hand-cursor ${props.selectedEndCallId === callData.nenaIncidentId ? 'active' : ''}`}
                                                    onClick={() => handleEndCallClick(callData)}
                                                >
                                                    <p className="noMargin">
                                                        <span className="grey-font">
                                                            {moment(callData.disconnectTime).format('MM-DD-YYYY hh:mm:ss A')}
                                                        </span>
                                                    </p>

                                                    {props.selectedEndCallId === callData.nenaIncidentId ? (
                                                        <>
                                                            <div className='my-3'>
                                                                <div className='label'>Call Back:</div>
                                                                <div>
                                                                    {callData.ani ? (
                                                                        callData.ani.includes('+') ? (
                                                                            <span>{callData.ani.slice(0, 2)} {callData.ani.slice(2).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</span>
                                                                        ) : (
                                                                            callData.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')
                                                                        )
                                                                    ) : ''}
                                                                </div>
                                                            </div>
                                                            {callData.address ? (
                                                                <div className='my-3'>
                                                                    <div className='label'>Call Address:</div>
                                                                    <div>{callData.address.address1}{isEmpty(callData.address.address1) && isEmpty(callData.address.address2) && isEmpty(callData.address.city || callData.address.state || callData.address.zip) ? ',' : ''}</div>
                                                                    <div>{callData.address.address2}{isEmpty(callData.address.city && callData.address.address2) || isEmpty(callData.address.state && callData.address.address2) || isEmpty(callData.address.zip && callData.address.address2) ? ',' : ''}</div>
                                                                    <div>{callData.address.city}{isEmpty(callData.address.city) && isEmpty(callData.address.state || callData.address.zip) ? ',' : ''} {callData.address.state}{isEmpty(callData.address.state && callData.address.zip) ? ',' : ''} {callData.address.zip}</div>
                                                                </div>
                                                            ) : ''}
                                                        </>
                                                    ) : ''}
                                                </div>
                                            ))}
                                    </div>
                                </>
                            ) : ''}
                        </>
                    </div>
                {props.callData || (props.endCallsData && props.endCallsData.length) ?
                ''
                : <div className='calls-list'><div className='no-data-found'>No Active Call Found</div></div>}
            </div>
        </>
    );
}

export default CallsComponent;