import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import { oktaSignInWidgetConfig } from './OktaLoginUtils';
import '../../../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

const OktaLogin = ({ onLoginSuccess, onLoginError }) => {
  const widgetRef = useRef();
  
  useEffect(() => {
    if (!widgetRef.current)
      return false;

    const oktaSignInConfig = oktaSignInWidgetConfig();
    const widget = new OktaSignIn(oktaSignInConfig);

    widget.showSignInToGetTokens({
      el: widgetRef.current,
    }).then(onLoginSuccess).catch(onLoginError);

    return () => widget.remove();
  }, [onLoginSuccess, onLoginError]);

  return (<div ref={widgetRef} />);
};

export default OktaLogin;
