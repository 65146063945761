import React, { useEffect, useState } from 'react';
import { isEmpty, isFeatureAllowed } from '../utils/CommonUtils';
import RapidSOSView from './views/RapiSOSView';
import SolacomIcons from './views/SolacomIcons';
import { callConfigs } from '../constants/Constants';
import moment from "moment";
import { FaPause } from "react-icons/fa6";
import { MdCallEnd } from "react-icons/md";
import { FaMicrophoneSlash } from "react-icons/fa";
import { PiSpeakerSlashFill } from "react-icons/pi";
import TransferIcon from '../assets/transfer-call.png';
import './calls.scss';

var interval;
var callEvent;

function SolacomComponent(props) {
    const [displayDate, setDisplayDate] = useState(new Date());
    const [displayTimer, setDisplayTimer] = useState((callConfigs.minutes < 10 ? ('0' + callConfigs.minutes) : callConfigs.minutes) + ':' + (callConfigs.seconds < 10 ? '0' + callConfigs.seconds : callConfigs.seconds > 59 ? '00' : callConfigs.seconds));
    const [moreText, setMoreText] = useState(false);
    // const [showPastResidences, setPastResidencies] = useState(false);
    // const [showCriminalHistory, setCriminalHistory] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);

    useEffect(() => {
        if (props.callData) {
            startTimer();
        }
    }, [props.callData])

    const startTimer = () => {
        if (props.solacomData) {
            clearInterval(interval);
            if (callConfigs.oldNinaId === '' || (callConfigs.oldNinaId !==props.callData.nenaIncidentId) || (callConfigs.oldNinaId ===props.callData.nenaIncidentId &&props.callData.callState === callConfigs.ended)) {
                let currentDateTime = new Date();
                callConfigs.oldNinaId =props.callData.nenaIncidentId;
                let diff = moment(new Date(currentDateTime)).diff(new Date(props.callData.connectTime));
                let dateObj = moment.duration(diff);
                callConfigs.seconds = dateObj._data.seconds;
                callConfigs.minutes = dateObj._data.minutes;
                handleTimer();
            }
            reset911call();
        }
    }

    const reset911call = () => {
        interval = setInterval(() => {
            let initCount = 0;
            callConfigs.callstartTime = callConfigs.callstartTime + 1;
            let timerSeconds = parseInt(callConfigs.seconds) + 1;
            callConfigs.seconds = timerSeconds === 60 ? initCount : timerSeconds;
            callConfigs.minutes = timerSeconds === 60 ? (parseInt(callConfigs.minutes) + 1) : parseInt(callConfigs.minutes);
            handleTimer();
        }, callConfigs.counterTime);
    }

    const handleTimer = () => {
        setDisplayTimer((callConfigs.minutes < 10 ? ('0' + callConfigs.minutes) : callConfigs.minutes) + ':' + (callConfigs.seconds < 10 ? '0' + callConfigs.seconds : callConfigs.seconds > 59 ? '00' : callConfigs.seconds));
    }

    const handleDisabilities = () => {
        setMoreText(moreText => !moreText);
    }

    // const handlePastResidences = () => {
    //     setPastResidencies(showPastResidences => !showPastResidences);
    // }

    // const handleCriminalHistory = () => {
    //     setCriminalHistory(showCriminalHistory => !showCriminalHistory);
    // }

    const onPopupHover = () => {
        setPopoverOpen(true);
    }
    const onPopupHoverLeave = () => {
        setPopoverOpen(false);
    }

    const handleEndCallClick = (callData) => {
        props.handleEndCallClick(callData)
    };
    if(props.callData){
        if(props.callData.eventType === 'Released'){
            callEvent = 'release';
        }
        else if(props.callData.callStateExt === 'transferred'){
            callEvent = 'transfer';
        }
        else{
            callEvent = 'answer';
        }
    }
    return (
        <>
            <div className='calls-left-panel'>
                <div className='call-details'>
                    <span>Call</span>
                    <div className='d-flex align-items-center'>
                        <span className='station-label'>Position ID:</span> <span className='station-id'>{props.stationId}</span>
                    </div>
                </div>
                    <div className='calls-list'>
                    {props.callData && props.callData && Object.keys(props.callData).length && (props.callData.callState == callConfigs.active || props.callData.callState == callConfigs.release) ?
                        <>
                        <div className='py-2 mb-2'>{moment(displayDate).format('MM-DD-YYYY hh:mm:ss A')}</div>
                        <div className='d-flex align-items-center'>
                            <SolacomIcons callData={props.callData}/>
                            <div>
                                    <div className='service-name'>{props.callData.classOfService}</div>
                                <div className='call-mdn'><b>{props.callData.ani ?
                                    props.callData.ani.includes(';') ? props.callData.ani.split(';')[0].includes('+') ? <><div>{props.callData.ani.split(';')[0].slice(0, 2)} {props.callData.ani.split(';')[0].slice(2, props.callData.ani.split(';')[0].length).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</div><div>{props.callData.ani.split(';')[1]}</div></> :
                                        props.callData.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3') :
                                        props.callData.ani.includes('+') ? <span>{props.callData.ani.slice(0, 2)} {props.callData.ani.slice(2, props.callData.ani.length).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</span> :
                                            props.callData.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3') : ''
                                }</b></div>
                            </div>
                            <div className='event-icon small-no-bg speaker-slash'>
                                <PiSpeakerSlashFill color="#666" />
                            </div>
                        </div>
                            <div className='mt-2 d-flex align-items-center'>
                            <span className='display-counter'>{displayTimer}</span>
                                {/* <div className={`event-icon ${callEvent}`}>
                                    {props.callData.eventType === 'Released' ? <MdCallEnd/> : props.callData.callStateExt === 'transferred' ? <img src={TransferIcon} className='transfer-icon'/> : <FaPause/> }
                                </div> */}
                                <div className='event-icon answer'>
                                    <FaPause/>
                                </div>
                                <div className='event-icon transfer'>
                                    <img src={TransferIcon} className='transfer-icon'/>
                                </div>
                                <div className='event-icon release'>
                                    <MdCallEnd/>
                                </div>
                                <div className='event-icon small-no-bg microphone-slash'>
                                    <FaMicrophoneSlash color="#666" />
                                </div>
                                {/* <span className='call-btn'>{props.callData.eventType === 'Released' ? 'Released' : props.callData.callStateExt === 'transferred' ? 'Transferred' : 'In Progress'}</span> */}
                        </div>
                        <div className='main-info my-3'>Main Information</div>
                            {props.callData.callerName ? <div className='mb-3'>
                            <div className="label">Caller Name: </div> {props.callData.callerName}
                        </div> : ''}
                            {props.callData.address ? <div className='mb-4'>
                            <div className='label'>Call Address:</div>
                            <div>{props.callData.address.address1}{isEmpty(props.callData.address.address1) && isEmpty(props.callData.address.address2) && isEmpty(props.callData.address.city || props.callData.address.state || props.callData.address.zip) ? ',' : ''}</div>
                            <div>{props.callData.address.address2}{isEmpty(props.callData.address.city && props.callData.address.address2) || isEmpty(props.callData.address.state && props.callData.address.address2) || isEmpty(props.callData.address.zip && props.callData.address.address2) ? ',' : ''}</div>
                            <div>{props.callData.address.city}{isEmpty(props.callData.address.city) && isEmpty(props.callData.address.state || props.callData.address.zip) ? ',' : ''} {props.callData.address.state}{isEmpty(props.callData.address.state && props.callData.address.zip) ? ',' : ''} {props.callData.address.zip}</div>
                        </div> : ''}
                            <div className='mb-3'>
                            {props.callData.geometry && props.callData.geometry.coordinates && props.callData.geometry.coordinates.length ?
                                    <>
                                        <div className='label'>Lat/Lon:</div>
                                        <div className='coordinates'>[{parseFloat(props.callData.geometry.coordinates[props.callData.geometry.coordinates.length - 1][1]).toFixed(6)}, {parseFloat(props.callData.geometry.coordinates[props.callData.geometry.coordinates.length - 1][0]).toFixed(6)}]</div>
                                    </>
                                    : 
                                    <><div className='label'>Lat/Lon:</div><div className='unknown-loc'>Location Unknown</div></>
                                    }
                        </div>
                            {props.callData.radius ? <div className='mb-3'>
                                <div className="label">UNC: </div> 
                                <div>{parseFloat(props.callData.radius * callConfigs.meterToFeet).toFixed() + ' ft' }</div>
                            </div> : ''}

                        {props.callData.disabilities ?
                                <div className='mb-3'>
                                <div className='label'>Disabilities: </div>
                                <div className='disability'>{props.callData.disabilities.length > 60 ?
                                    <span>{moreText ? props.callData.disabilities : props.callData.disabilities.slice(0, 60)} <span className='hand-cursor more-btn' onClick={handleDisabilities}>See {moreText ? 'Less' : 'More'}</span></span>
                                    : props.callData.disabilities}</div>
                            </div>
                            : ''}
                                {props.callData.serviceProvider ? 
                        <div className='my-3'>
                                <div className='text-left'><div className='label'>Provider ID:</div> <div color={'black'}>{props.callData.serviceProvider}</div></div>

                        </div>
                            : ''}
                        {props.callData.transfer && props.callData.transfer.target ?
                            <div className='mb-1 mt-2'>
                                    <div><div className="label">Transfer Target: </div> <div>{props.callData.transfer.target}</div></div>
                            </div>
                            : ''}
                        <div className='main-info add-info my-3'>Additional Information</div>
                        <div className='history-details'>
                                <RapidSOSView showRapidSOS={props.showRapidSOS} callData={props.callData} rapidSOSInfo={props.callData.rapidSOS} address={props.callData.rapisSOSAddress} contourLineProperties={props.contourLineProperties} />
                        </div>
                        </>
                        : ''}
                    <>
                        {props.endCallsData && props.endCallsData.length ? (
                            <>
                                <div className='main-info end-calls-info my-3'>Ended Calls</div>
                                <div className="position-relative h-auto">
                                    {props.endCallsData
                                        .sort((a, b) => b.disconnectTime - a.disconnectTime)
                                        .map((callData, index) => (
                                            <div
                                                key={index}
                                                className={`end-call-details m-0 mb-2 hand-cursor ${props.selectedEndCallId === callData.nenaIncidentId ? 'active' : ''}`}
                                                onClick={() => handleEndCallClick(callData)}
                                            >
                                                <p className="noMargin">
                                                    <span className="grey-font">
                                                        {moment(callData.disconnectTime).format('MM-DD-YYYY hh:mm:ss A')}
                                                    </span>
                                                </p>
                                                {props.selectedEndCallId === callData.nenaIncidentId ? (
                                                    <>
                                                        <div className='my-3'>
                                                            <div className='label'>Call Back:</div>
                                                            <div>
                                                                {callData.ani ? (
                                                                    callData.ani.includes('+') ? (
                                                                        <span>{callData.ani.slice(0, 2)} {callData.ani.slice(2).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</span>
                                                                    ) : (
                                                                        callData.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')
                                                                    )
                                                                ) : ''}
                                                            </div>
                                                        </div>
                                                        {callData.address ? (
                                                            <div className='my-3'>
                                                                <div className='label'>Call Address:</div>
                                                                <div>{callData.address.address1}{isEmpty(callData.address.address1) && isEmpty(callData.address.address2) && isEmpty(callData.address.city || callData.address.state || callData.address.zip) ? ',' : ''}</div>
                                                                <div>{callData.address.address2}{isEmpty(callData.address.city && callData.address.address2) || isEmpty(callData.address.state && callData.address.address2) || isEmpty(callData.address.zip && callData.address.address2) ? ',' : ''}</div>
                                                                <div>{callData.address.city}{isEmpty(callData.address.city) && isEmpty(callData.address.state || callData.address.zip) ? ',' : ''} {callData.address.state}{isEmpty(callData.address.state && callData.address.zip) ? ',' : ''} {callData.address.zip}</div>
                                                            </div>
                                                        ) : ''}
                                                    </>
                                                ) : ''}
                                            </div>
                                        ))}
                                </div>
                            </>
                        ) : ''}
                    </>
            </div>
            <>
                {props.solacomData.unplottedCalls && props.solacomData.unplottedCalls.length ? <div className='uplotted-calls hand-cursor' onMouseEnter={onPopupHover} onMouseLeave={onPopupHoverLeave}><span className='count'>{props.solacomData.unplottedCalls.length}</span></div> : ''}
                {popoverOpen ?
                    <div className="mapboxgl-popup mapboxgl-popup-anchor-bottom unplotted-popup">
                        <div className="mapboxgl-popup-tip"></div>
                        <div className="mapboxgl-popup-content">
                            <div className="marker_popup address-marker">
                                <div className="popup-text">
                                    {props.solacomData.unplottedCalls.map((unplottedCall, ind) => {
                                        return (
                                            <div className='call-list' key={ind}>
                                                <div className='d-flex'>
                                                    <div className="street-label">Ani: </div>
                                                    <span className='pl-1'>{unplottedCall.ani}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className="street-label">Caller Name: </div>
                                                    <span className='pl-1'>{unplottedCall.callerName}</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            </>
            {props.callData || (props.endCallsData && props.endCallsData.length) ?
                ''
                : <div className='calls-list'><div className='no-data-found'>No Active Call Found</div></div>}
                </div>
        </>
    )
}

export default SolacomComponent;