import { criminalSearchApi, identitySearchApi } from '../../services/CallsServices';
import {
    IDENTITY_SEARCH_SUCCESS, IDENTITY_SEARCH_FAIL, CRIMINAL_SEARCH_SUCCESS,
    CRIMINAL_SEARCH_FAIL
} from '../actions/actionTypes';
import axios from 'axios';

const fetchCriminalSuccess = (data) => {
    return {
        type: CRIMINAL_SEARCH_SUCCESS,
        data: data
    }
}

const fetchCriminalFail = (error) => {
    return {
        type: CRIMINAL_SEARCH_FAIL,
        data: error
    }
}

export const getCriminalsData = (firstName, lastName, dob) => {
    return function (dispatch) {
        criminalSearchApi(firstName, lastName, dob)
        .then(response => {
                if (response.status == 200 && response.data.records.length) {
                    dispatch(fetchCriminalSuccess(response.data.records));
                }
            })
            .catch(error => {
                dispatch(fetchCriminalFail(error.response.data.message));
            })
    }
}

const fetchIdentitySuccess = (data) => {
    return {
        type: IDENTITY_SEARCH_SUCCESS,
        data: data
    }
}

const fetchIdentityFail = (error) => {
    return {
        type: IDENTITY_SEARCH_FAIL,
        data: error
    }
}

export const getIdentities = (mdn) => {
    let dob, name, data = [];
    return function (dispatch) {
        identitySearchApi(mdn)
        .then(response => {
                if (response.status == 200 && response.data.persons.length) {
                    data = response.data.persons;
                    dispatch(fetchIdentitySuccess(data));
                    dob = data[0].DOB.month + "/" + data[0].DOB.day + "/" + data[0].DOB.year;
                    name = data[0].names[0];
                    dispatch(getCriminalsData(name.firstName, name.lastName, dob));
                }
            })
            .catch(error => {
                dispatch(fetchIdentityFail(error.response.data.message));
            })
    }
}