import axios from 'axios';
import { serverUrls } from '../constants/Constants'
import { configTimers } from '../constants/Timers'

export default class WeatherServices {
    getForeCastTilePath(url, source) {
        return axios.get(url, {
            cancelToken: source.token,
            timeout: configTimers.weatherReqTimeOut
        });
    }
}