import { useEffect, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { IdleTimerProvider } from 'react-idle-timer';
import moment from "moment";
import {  featureLabels, appName, notificationBody, AUTH_TYPES, notifyTypeVals, appConfigs, callConfigs, transcriptStatus } from '../constants/Constants';
import { AuthContext } from './../context/AuthContext';
import { getOktaSessionStatus, refreshOktaSession, refreshOktaToken, handleOktaExpiredSession } from '../presentation/login/okta/OktaLoginUtils';
import { refreshSessionToken } from "./login/LoginUtils";
import { SolacomGroup, configAuthType, getStationIdFromLocalStorage, swapCoordinates , isFeatureAllowed, isMotorolaGroup} from '../utilities/Utils';
import { STORAGE_KEYS } from '../constants/SessionConstants';
import { configTimers } from '../constants/ConfigTimers';
import { initNotifications, subscribeToCalls, subscribeToSolacom } from '../notifications/IotNotifications';
import { updateNotificationClient } from '../redux/actions/notificationActions';
import { setCallsData, setSolacomCallsData } from '../redux/actions/callsActions';

function AppCommon(props) {
  const authType = configAuthType();
  const { authClient, updateAuthClient } = useContext(AuthContext);
  let isPageActive = true;
  let refreshSessionTimeoutId = null;
  let oktaTokenTimeoutID = {
    accessToken: null,
    idToken: null,
  }
  const renewOktaTokenRetry = {
    accessToken: {count: 0, timeoutID: null},
    idToken: {count: 0, timeoutID: null},
  };

  useEffect(() => {
    console.log(":::: App Common ::: ")
    if (configAuthType() === AUTH_TYPES.OKTA) {
      handleOktaSession();
      handleOktaToken(STORAGE_KEYS.ACCESS_TOKEN);
      handleOktaToken(STORAGE_KEYS.ID_TOKEN);
    } else {
      setSessionExpiry();
    }

    if (appConfigs.notificationsType === notifyTypeVals.AWS_IOT) {
      initiateNotifications();
    }
  }, []);

  // useEffect(() => {
  //   console.log('notifyObj: ', props.notificationClientObj);
  // }, [props.notificationClientObj])

  const setSessionExpiry = () => {
    console.log('Getting session: ');
    refreshSessionToken()
    .then(nextTimeout => {
      console.log('Session next timeout:', nextTimeout);
      // When access token is expired, allow Amplify for 20 seconds to refresh for a new token.
      setTimeout(() => {
        setSessionExpiry();
      }, nextTimeout > 0 ? nextTimeout : configTimers.refreshTokenExpiryTime);
    })
    .catch(error => {
      console.error('Error refreshing session token:', error);
    });
  }

  const handleOktaSession = () => {
    console.log('Getting session: ');
    refreshOktaSession(authClient)
    .then(nextTimeout => {
      console.log('Session next timeout:', nextTimeout);
      if (refreshSessionTimeoutId) clearTimeout(refreshSessionTimeoutId);
      refreshSessionTimeoutId = setTimeout(() => {
        handleOktaSession();
      }, nextTimeout > 0 ? nextTimeout : configTimers.refreshTokenExpiryTime);
    })
    .catch(error => {
      // console.error('Error refreshing session:', error);
      console.error('Error while refreshing session');
      handleOktaExpiredSession('session');
    });
  }

  const handleOktaToken = (tokenType) => {
    getOktaSessionStatus(authClient)
    .then((session) => {
      console.log('session status:', session.status);
      if (session && session.status && session.status === 'ACTIVE') {
        const oktaTokens = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
        const tokenInfo = oktaTokens[tokenType];
        if (tokenInfo) {
          let currentTime = new Date().getTime();
          let nextTimeout = (tokenInfo.expiresAt * 1000) - currentTime;
          console.log(`${tokenType} next timeout:`, nextTimeout);
          if (nextTimeout > 0) {
            let tokenTimeoutID = oktaTokenTimeoutID[tokenType];
            if (tokenTimeoutID) clearTimeout(tokenTimeoutID);
            const timeOut = (nextTimeout > 60000) ? (nextTimeout - 60000) : nextTimeout;
            oktaTokenTimeoutID[tokenType] = setTimeout(() => {
              handleOktaTokenRefresh(authClient, tokenType);
            }, timeOut);
          } else {
            console.log(`::::::::: ${tokenType} Expired :::::::::::::: `);
            handleOktaTokenRefresh(authClient, tokenType);
          }
        }
      } else {
        handleOktaExpiredSession('session');
      }
    })
    .catch((err) => {
      console.log(`::::::::: handleOktaToken Exception :::::::::::::: `, err);
    });
  }

  const handleOktaTokenRefresh = (authClient, tokenType) => {
    refreshOktaToken(authClient, tokenType)
    .then((renewedToken) => {
      console.log(`::::::::::::: New ${tokenType} generated ::::::::::: `);
      renewOktaTokenRetry[tokenType]['count'] = 0;
      authClient.tokenManager.add(tokenType, renewedToken);
      authClient.authStateManager.updateAuthState();
      handleOktaToken(tokenType);
    })
    .catch(exception => {
      const errorMessage = (exception.message) ? exception.message : exception;
      if (exception.message) console.log(`renew ${tokenType} exception message:`, errorMessage);
      console.log(`::::: Renew ${tokenType} failure count :::::`, renewOktaTokenRetry[tokenType]['count']);
      if (renewOktaTokenRetry[tokenType]['count'] <= 3) {
        if (renewOktaTokenRetry[tokenType]['timeoutID']) clearTimeout(renewOktaTokenRetry[tokenType]['timeoutID']);
        renewOktaTokenRetry[tokenType]['timeoutID'] = setTimeout(() => {
          renewOktaTokenRetry[tokenType]['count'] += 1;
          handleOktaTokenRefresh(authClient, tokenType);
        }, 30000);
      } else {
        console.log(`::::::::: handleOktaTokenRefresh Exception :::::::::::::: `);
        handleOktaExpiredSession('tokens');
      }
    })
  }

  const handleOnAction = (event) => {
    if (event.type === 'visibilitychange') {
      if (event.target.visibilityState === 'hidden') {
        isPageActive = false;
      } else {
        isPageActive = true;
        if (window.navigator.onLine && authType === AUTH_TYPES.OKTA) setTimeout(() => checkSessionValidity(), 5000);
      }
    }
  }

  const checkSessionValidity = () => {
    if (sessionStorage.getItem('currentUser') !== null) {
      getOktaSessionStatus(authClient)
      .then((session) => {
        console.log('check session status:', session.status);
        if (session.status === 'INACTIVE') {
          handleOktaExpiredSession('session');
        }
      })
      .catch((err) => {
        console.log(`::::::::: checkSessionValidity Err :::::::::::::: `, err);
      });
    }
  }

  const initiateNotifications = async () => {
    const notifyClient = await initNotifications({
      onConnectHandler,
      onMessageHandler,
      onErrorHandler,
      onReconnectHandler,
      onDisconnectHandler,
      onUnsubscribeHandler,
      onOfflineHandler,
      onCloseHandler,
    }, props.updateNotificationClient);
    props.updateNotificationClient(notifyClient)
  }

  const onConnectHandler = () => {
    console.log('--- onConnectHandler ---')
    // this.deviceStatus = 'connected';
    var stationID = getStationIdFromLocalStorage();
    var showNotifications = sessionStorage.getItem('showNotifications') == 'true' ? true : false;
    // toast.dismiss()
    if(SolacomGroup()){
      subscribeToSolacom(props.notificationClientObj);
    } else if (isMotorolaGroup() && stationID && stationID != null && stationID.length > 0 && !callConfigs.isSimulationEnabled && showNotifications) {
      subscribeToCalls(props.notificationClientObj, stationID);
    }
  }
  // TODO ------------------------ remove the below logic later from here -------------------------------------------------
  const tempData = {
    "callIdentifier": "_CI_183DA0383D8C00015C9D@Godzilla_Admin",
    "connectTime": new Date(),
    "disconnectTime": "",
    "agency": "TNG000",
    "incidentIdentifier": "_II_183DA0383D8C00015C9D@Godzilla_Admin",
    "ani": "5123334445",
    "callerName": "IQem_Picto call",
    "callType": "E911",
    "callState": "inProgress",
    "callStateExt": "",
    "classOfService": "VOIP",
    "serviceProvider": "",
    "address": {
      "country": "US",
      "address1": "490 Sherwood Dr",
      "state": "AU"
    },
    "geometry": {
      "type": "LineString",
      "coordinates": [
        [33.992144, -81.063792]
      ]
    },
    "altitude": 0,
    "radius": 0,
    "rapidSOS": {
      "geometry":{"type":"LineString","coordinates":[[-81.0623040801483,33.99410093569998]]},
      "radius": 5,
      "altitude": 35,
      "zuncer": 5,
      "confidence": 0,
      "uber": null,
      "sxm": null
    },
    "routes": [
      {
        "rule": "rule #9",
        "reason": "alternate"
      }
    ],
    "agents": [
      {
        "agent": "op59",
        "routes": [
          {
            "rule": "rule #661",
            "reason": "alternate"
          }
        ]
      }
    ],
    "transfer": {
      "target": "",
      "name": ""
    },
    "eventType": "Answer"
  };

  const [timeoutId, setTimeoutId] = useState(null);
  useEffect(() => {
    if (SolacomGroup() && props.transcriptStatus && props.transcriptStatus != '' && props.transcriptStatus === transcriptStatus.ended) {
     // Clear the timeout when transcriptStatus is ENDED
     clearTimeout(timeoutId);
     setTimeoutId(null);
    } else if (SolacomGroup() && props.transcriptStatus && props.transcriptStatus != '' && props.transcriptStatus === transcriptStatus.started) {
      // If callInfo has data, use it. Otherwise, use tempData
      const dataToUse = props.callInfo ? props.callInfo : tempData;
      var solacomTopic = callConfigs.sim911Topic + callConfigs.solacomTopic;
      onMessageHandler(solacomTopic,  JSON.stringify(dataToUse))
      let indexVal = 1
      // Start the setTimeout
      const intervalId = setInterval(() => {
        indexVal++
        // Append new coordinates to the existing array in dataToUse
        if(!props.callInfo) {
          tempData.geometry.coordinates.push([33.991265 + (0.00006* indexVal) , -81.066178 + (0.00006* indexVal)])
        }
        onMessageHandler(solacomTopic, JSON.stringify(tempData))
      }, 30000);

      setTimeoutId(intervalId);
    }
    // Clean up the timeout on unmount
    return () => clearTimeout(timeoutId);
  }, [props.transcriptStatus])
// ------------------------ to here. For demo we are forcefully setting the call data in the above logic --------------------
  const onMessageHandler = (topic, payload) => {
    console.log('Message Received at ', moment().format('YYYY-MM-DD HH:mm:ss:ms'));
    console.log('Message payload ::::::: ' + payload);
    let data = JSON.parse(payload);
    data.routesList = [];
    data.cityRadius = data.radius;
    data.radius = data.radius >= 0 ? data.radius : 0;
    if (data.rapidSOS) {
      data.rapidSOS.radius = data.rapidSOS.radius >= 0 ? data.rapidSOS.radius : 0
    }

    var stationID = getStationIdFromLocalStorage();
    var callsTopic = callConfigs.sim911Topic + stationID;
    var solacomTopic = callConfigs.sim911Topic + callConfigs.solacomTopic;
    if (topic === (callsTopic)) {
      if (props.callsInfo === '' && data.callState === callConfigs.endStaus) {
        props.setCallsData(null);
      }
      else {
        data.callState = callConfigs.active;
        props.setCallsData(data);
      }
    } else if ((topic === solacomTopic) && SolacomGroup()) {
      data.nenaIncidentId = data.callIdentifier;
      if (data.routes && data.routes.length) {
        data.routesList.push(...data.routes);
      }
      if (data.agents && data.agents.length) {
        data.agents.map(agent => {
          if (agent.routes && agent.routes.length) {
            data.routesList.push(...agent.routes);
          }
        })
      }
      if (data.geometry && data.geometry.coordinates && data.geometry.coordinates.length) {
        data.geometry.coordinates = swapCoordinates(data.geometry.coordinates);
      }
      if ((data.callState === callConfigs.active || data.callState === callConfigs.release) && data.agents && data.agents.length) {
        if (getStationIdFromLocalStorage() === data.agents[0].agent) {
          if (data.geometry && data.geometry.coordinates && data.geometry.coordinates.length > 1) {
            if (data.callStateExt === 'transferred' && data.callState === callConfigs.active) {
              data.eventType = 'TransferCall';
            }
            else if (data.callStateExt === '' && data.callState === callConfigs.active) {
              data.eventType = 'Answer';
            }
            else if (data.callState === callConfigs.release) {
              data.eventType = 'Released';
            }
          }
          props.setCallsData(data);
        }
      }
      if (data.eventType === 'EndCall') {
        if (data.callState === callConfigs.ended && getStationIdFromLocalStorage() === data.agents[0].agent) {
          props.setCallsData(data);
        }
      }
      props.setSolacomCallsData(data);
    }

    if(!isFeatureAllowed(featureLabels.tracking_factory)) {
      if (Notification.permission === 'granted') {
        if (!isPageActive) {
          showNotification();
        } else if (window.location.pathname === '/login') {
          showNotification();
        }
      } else {
        console.log('::::::::::::: Browser Notifications popup permissions are blocked');
      }
    }
  }
  
  // create and show the notification
  const showNotification = () => {
    console.log('::::::::::::: showNotification :::: ');
    const notification = new Notification(appName, {
      body: notificationBody,
      icon: '../assets/comtech_corp_logo.svg'
    });

    // close the notification after 10 seconds
    setTimeout(() => {
      notification.close();
    }, 10 * 1000);
    // navigate to a URL when clicked
    notification.addEventListener('click', (event) => {
      console.log('On notification click: ');
      event.preventDefault();
      var win = window.open('', '_top', '');
      win.focus();
    });
  }

  const onErrorHandler = (data) => {
    // this.deviceStatus = 'error';
  }
  
  const onReconnectHandler = () => {
    // this.deviceStatus = 'reconnect';
    // if(this.state.online) {
    //     consoleLog("::::::::::::: Reconnect AWS ::::: " )
    //     this.stopPolling();
    //     device.end(true);
    //     this.initializeAwsDevice();
    // }
  }
  
  const onDisconnectHandler = () => {
    // this.deviceStatus = 'disconnect';
  }
  
  const onUnsubscribeHandler = () => {
    // this.deviceStatus = 'unsubscribe';
  }
  
  const onOfflineHandler = () => {
    // this.deviceStatus = 'offline'; 
    // this.requestPing();
    // this.startPolling();
  }
  
  const onCloseHandler = () => {
    // this.deviceStatus = 'close';
  }

  return (
    <>
      <Outlet />
      <IdleTimerProvider timeout={1000 * 60 * 15} onAction={handleOnAction}  />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    notificationClientObj: state.notifications.notificationClient,
    callsInfo: state.callsInfo ? state.callsInfo.data : null,
    socketData: state.socketData.socketData,
    transcriptStatus: state.transcriptStatus.transcriptStatus
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateNotificationClient: (data) => dispatch(updateNotificationClient(data)),
    setSolacomCallsData: (data) => dispatch(setSolacomCallsData(data)),
    setCallsData: (data) => dispatch(setCallsData(data))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AppCommon);