import { STORAGE_KEYS } from '../../constants/SessionConstants';
import {
    FETCH_SPLIT_SCREEN,
    FETCH_MAP_THEME_REQUEST, 
    FETCH_MAP_THEME_SUCCESS, 
    FETCH_MAP_THEME_FAIL, 
    FETCH_MAP_THEME_URL, 
    FETCH_MAP_THEME_CANCEL,
    FETCH_RAPID_SOS,
    FETCH_RENDER_THEME,
    FETCH_UNCERTAINITY, SET_TOOLBAR, SET_MAP
  } from '../actions/actionTypes';

const initialState = {
    error: '',
    isLoading: false
}
export const MapInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MAP_THEME_REQUEST:
            return { ...state, isLoading: action.isLoading }
        case FETCH_MAP_THEME_SUCCESS:
            return {...state, isLoading: action.isLoading, mapThemes: action.data, error: '' }
        case FETCH_MAP_THEME_FAIL:
            return {...state, error: action.error, isLoading: false }
        case FETCH_MAP_THEME_CANCEL:
            return { ...state, cancelError: action.error, callEnable: action.retires, isLoading: false }
        case FETCH_MAP_THEME_URL:
            return {...state, mapUrl: action.data, styleLoad: true}
        case FETCH_RENDER_THEME:
            return {...state,mapRender: action.data}
        case FETCH_RAPID_SOS:
            return {...state,isRapiSOSEnable: action.data} 
        case FETCH_SPLIT_SCREEN:
            return {...state,isSplitScreen: action.data}
        case FETCH_UNCERTAINITY:
            return {...state, isUncertainity: action.data}       
        default:
            return state;
    }
}

var settings = {
    isToolbar: (sessionStorage.getItem(STORAGE_KEYS.TOOLBAR) !== null) ? sessionStorage.getItem(STORAGE_KEYS.TOOLBAR) == 'true' ? true : false : false,
    isMap: (sessionStorage.getItem(STORAGE_KEYS.MAP) !== null) ? sessionStorage.getItem(STORAGE_KEYS.MAP) == 'true' ? true : false : true
}
export const settingsInfoReducer = (state = settings, action) => {
    switch (action.type) { 
            case SET_TOOLBAR:
            return {...state, isToolbar: action.data} 
            case SET_MAP: 
            return {...state, isMap: action.data}     
        default:
            return state;
    }
}
