import { STORAGE_KEYS } from "../constants/SessionConstants";


export const storeRegionInfo = (primaryRegionData, secondaryRegionData) => {
    var currentRegion = localStorage.getItem(STORAGE_KEYS.CURRENT_REGION)|| primaryRegionData.aws_project_region;;
    if ((currentRegion != null || currentRegion) && currentRegion === secondaryRegionData.aws_project_region) {
      sessionStorage.setItem(STORAGE_KEYS.BASE_URL, secondaryRegionData.baseUrl);
      localStorage.setItem(STORAGE_KEYS.CURRENT_REGION, secondaryRegionData.aws_project_region);
    } else {
      sessionStorage.setItem(STORAGE_KEYS.BASE_URL, primaryRegionData.baseUrl);
      localStorage.setItem(STORAGE_KEYS.CURRENT_REGION, primaryRegionData.aws_project_region);
    }
  };