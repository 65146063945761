import { useEffect, useContext } from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import AppRouter from './routes/AppRouter';
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, Slide, toast } from 'react-toastify';
import './styles/ReactToastify.css';
import  NetworkContext  from 'sa-common/context/NetworkContext';

function App() {

  const toastId = "toast-id";
  const { isOnline } = useContext(NetworkContext);

  const showNetworkError = () => {
    toast.dismiss();
    toast.error('errorMessages.networkError', { toastId: toastId, autoClose: false});
  }
  const dismissNetworkError = () => {
    toast.dismiss();
  }
  
  return (
    <div id="app">
      <AppRouter />
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        closeButton={false}
        transition={Slide}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      {isOnline ?dismissNetworkError() : showNetworkError()}
    </div>
  );
}

export default App;
