import { useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import '../styles/header.scss';
import "../styles/auth.scss";
import CognitoLogin from "../presentation/login/CognitoLogin";
import { ROUTE_ENDPOINTS } from "../constants/RouteConstants";
import { getLoginToken, setStationIdInLocalStorage, getEndCallTimeFromLocalStorage, setEndCallTimeInLocalStorage } from "../utilities/Utils";
import { featureLabels,appName } from "../constants/Constants";
import { STORAGE_KEYS } from "../constants/SessionConstants"
import _ from 'lodash';
import srLogo from "../assets/sr_logo.svg";

function LoginPage(props) {
  const navigate = useNavigate();
  const loginCompRef = useRef();
  const isLoggedIn = getLoginToken();
  const sessionGroupProfile = sessionStorage.getItem(STORAGE_KEYS.GROUP_PROFILE);
  const [showStationList, setShowStationList] = useState(false);
  const [selectedStation, setStation] = useState(false);
  const [stationList, setStationList] = useState(
    (sessionGroupProfile !== null && sessionGroupProfile !== 'null')
      ? JSON.parse(sessionGroupProfile).stationID
      : []
  );

  const handleLogin = (user) => {
    console.log('user: ', user);
    if (user) {
      // masking sensitive data
      var n = sessionStorage.length;
      while (n--) {
        var key = sessionStorage.key(n);
        if (/userData/.test(key)) {
          let currentData = sessionStorage.getItem(key);
          let maskedData = currentData.replace(
            '"' + user.attributes["email"] + '"',
            '"xxxx"'
          );
          maskedData = maskedData.replace(
            '"' + user.attributes["custom:company"] + '"',
            '"xxxx"'
          );
          maskedData = maskedData.replace(
            '"' + user.attributes["family_name"] + '"',
            '"xxxx"'
          );
          sessionStorage.setItem(key, maskedData);
        }
      }
      let groupFeatures = user.attributes["custom:group_profile"];
      try {
        JSON.parse(groupFeatures);
        sessionStorage.setItem(STORAGE_KEYS.GROUP_PROFILE, groupFeatures ? groupFeatures : null);
      } catch (error) {
        groupFeatures = null;
        sessionStorage.setItem(STORAGE_KEYS.GROUP_PROFILE, null);
        console.log(':::::::::::: Uploaded Invalid Group features file ::::::: ');
      }
      sessionStorage.setItem(STORAGE_KEYS.SHOW_NOTIFICATIONS, true);
      if (groupFeatures != null) {
        storeUserInfo(user)
      } else {
        // TODO - Show toast saying groupfropile not found to this user
      }

    }
  }
  const storeUserInfo = (userInfo) => {
    console.log('userInfo: ', userInfo);
    sessionStorage.setItem(STORAGE_KEYS.CURRENT_USER, userInfo.username);
    sessionStorage.setItem(STORAGE_KEYS.USER, userInfo.username);
    sessionStorage.setItem(STORAGE_KEYS.API_KEY, userInfo.attributes["custom:ls_api_key"]);
    let idTokenFeatures = userInfo.signInUserSession["idToken"];
    sessionStorage.setItem(STORAGE_KEYS.ID_TOKEN, idTokenFeatures.jwtToken);
    sessionStorage.setItem(STORAGE_KEYS.USER_INFO, JSON.stringify(userInfo));

    const groupFeatures = sessionStorage.getItem(STORAGE_KEYS.GROUP_PROFILE)
    setStationList(groupFeatures ? JSON.parse(groupFeatures).stationID : []);
    var endCall = getEndCallTimeFromLocalStorage();
    if (endCall === null || endCall === '') {
      setEndCallTimeInLocalStorage(userInfo.username, JSON.parse(groupFeatures).endCallTimer);
    }
    let groups = userInfo.signInUserSession.idToken.payload["cognito:groups"];
    if (groups) {
      for (let group in groups) {
        if (groups[group] != "admin" && groups[group] != "SAUsers") {
          sessionStorage.setItem(STORAGE_KEYS.GROUP_NAME, groups[group]);
        }
      }
    }
    navigateToNextScreen(userInfo)
  }

  const navigateToNextScreen = (userInfo) => {
    const groupFeatures = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.GROUP_PROFILE))
    if (groupFeatures.features.includes(featureLabels.call)) {
      if (groupFeatures.stationID && groupFeatures.stationID.length > 0 && Array.isArray(groupFeatures.stationID)) {
        if (localStorage.getItem(STORAGE_KEYS.STATION_ID_PREF) && _.find([...JSON.parse(localStorage.getItem(STORAGE_KEYS.STATION_ID_PREF))], ["userName", userInfo.username])) {
          navigate(ROUTE_ENDPOINTS.CALLS);
        } else {
          setShowStationList(true)
        }
      } else {
        // TODO - Show toast saying stationID not found in groupfropile
      }
    }
  }

  const handleStation = (e) => {
    setStation(e.target.value)
  };

  const submitStation = (e) => {
    e.preventDefault();
    let currentUser = sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER);
    setStationIdInLocalStorage(currentUser, selectedStation);
    navigate(ROUTE_ENDPOINTS.CALLS);
  }; 

  return (
    <>
      <div className="header">
        <div className="psap-header header-bar">
          <div className="navbar-header container">
            <div className="d-flex align-items-center">
              {/* <img src={srLogo} /> */}
              <span className="logo-text">{appName}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='main container'>
        <div className="mh-100">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="sign-in">
                <h3 className="py-3">Sign in</h3>
                {showStationList ?
                  <div className="choose-station">
                    <div className="form-group position-relative">
                      <label className="mb-1">Choose MCW Position</label>
                      <select
                        className="form-control form-select"
                        onChange={handleStation}
                      >
                        <option hidden>Position ID</option>
                        {Array.isArray(stationList) &&
                          stationList.length
                          ? stationList.map((stationId) => {
                            return (
                              <option value={stationId} key={stationId}>
                                {stationId}
                              </option>
                            );
                          })
                          : ""}
                      </select>
                    </div>
                    <div className="form-group">
                      <button
                        disabled={selectedStation ? false : true}
                        className="comtech-btn comtech-btn-primary btn-lg"
                        onClick={submitStation}
                        type="submit"
                      >
                        <span>Select</span>
                      </button>
                    </div>
                  </div> : <CognitoLogin
                    onLoginSuccess={handleLogin}
                  />
                }
              </div>
            </div>
            <div className="col-md-6 hidden-sm-down"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage;
