import '../../styles/settings.scss';
import {Form } from "react-bootstrap";
import React, {useEffect, useState } from 'react';
import { setMapVisibility, setToolbarVisibility } from '../../redux/actions/headerActions';
import { connect } from 'react-redux';
import { STORAGE_KEYS } from '../../constants/SessionConstants';

function Settings(props) {
    const [enableMap, setEnableMap] = useState(props.isMap);
    const [enableToolbar, setEnableToolbar] = useState(props.isToolbar);

    useEffect(() => {
        window.location.hash = ''
      }, [])

    const handleMap = (e) => {
        setEnableMap(e.target.checked);
        sessionStorage.setItem(STORAGE_KEYS.MAP, e.target.checked);
        props.setMapVisibility(e.target.checked);
    }

    const handleToolbar = (e) => {
        setEnableToolbar(e.target.checked);
        sessionStorage.setItem(STORAGE_KEYS.TOOLBAR, e.target.checked);
        props.setToolbarVisibility(e.target.checked);
    }

    return (
        <>
            <div className={`settings-page ${props.isToolbar ? 'show-tool' : 'hide-tool'}`}>
                <h3 className='py-3'>Settings</h3>
                <div className='border-bottom'></div>
                <div className='settings-content'>
                    <div className="card mt-5">
                        <div className="d-flex align-items-center py-2">
                            <div className="col-form-label">Map</div>
                            <div className="unsubscribe-text">
                                {props.isMap ? 'Disable' : 'Enable'} Map
                            </div>
                            <Form.Check
                                type="switch"
                                id="map-switch"
                                checked={enableMap}
                                onChange={handleMap}
                            />
                        </div>
                        <div className="d-flex align-items-center py-2">
                            <div className="col-form-label">Vertical Toolbar</div>
                            <div className="unsubscribe-text">
                                {props.isToolbar ? 'Disable' : 'Enable'} Toolbar
                            </div>
                            <Form.Check
                                type="switch"
                                id="toolbar-switch"
                                checked={enableToolbar}
                                onChange={handleToolbar}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
	return {
        isToolbar: state.settings.isToolbar,
        isMap: state.settings.isMap
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		setToolbarVisibility: (val) => dispatch(setToolbarVisibility(val)),
        setMapVisibility: (val) => dispatch(setMapVisibility(val))
	} 
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);