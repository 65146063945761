import React from "react";
import { getGroupProfile } from "../../../utilities/Utils";

function GroupName() {
  const groupFeatures = getGroupProfile();
  const currentUser = sessionStorage.getItem('currentUser');
  const groupName = sessionStorage.getItem('groupName');

  return (
    <div className="logo px-3">
      {currentUser !== null && groupName !== null ? (
        <>
          <div className='logo-text'>
            <span className='upper-text'>{groupName.slice(0, 1)}</span>
            {groupName.slice(1, groupName.length)}
            {groupFeatures && groupFeatures.gis_id && groupFeatures.gis_id !== '' ? ',' : ''}
          </div>
          {groupFeatures && groupFeatures.gis_id && groupFeatures.gis_id !== '' ? (
            <div className='logo-text'>
              <span className='upper-text'>{groupFeatures.gis_id.substring(0, 2)}</span>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default GroupName;