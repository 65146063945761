import axios from 'axios';
import { serverUrls } from '../constants/Constants'
import { configTimers } from '../constants/Timers'
import { STORAGE_KEYS } from "../constants/SessionConstants"

export default class SearchServices {

    getSuggestions(searchCenter, searchTerm, source) {
        const suggestionUrl = sessionStorage.getItem(STORAGE_KEYS.BASE_URL) + serverUrls.suggestionUrl;
        return axios.get(suggestionUrl, {
            cancelToken: source.token,
            params: {
                api_key: sessionStorage.getItem(STORAGE_KEYS.API_KEY),
                query: searchTerm,
                search_center: searchCenter.lat + ',' + searchCenter.lng
            },
            timeout: configTimers.searchCallTimeOut
        });
    }

    getSearchResultsBySuggestionFilter(searchCenter, searchTerm) {
        const poiUrl = sessionStorage.getItem(STORAGE_KEYS.BASE_URL) + serverUrls.poiSearchUrl;
        return axios.get(poiUrl, {
            params: {
                api_key: sessionStorage.getItem(STORAGE_KEYS.API_KEY),
                search_id: searchTerm,
                search_center: searchCenter.lat + ',' + searchCenter.lng
            }, timeout: configTimers.searchCallTimeOut
        });
    }

    getAddressSearchResultsByQuery(searchCenter, searchTerm) {
        const addressSearchUrl = sessionStorage.getItem(STORAGE_KEYS.BASE_URL) + serverUrls.addressSearchUrl;

        return axios.get(addressSearchUrl, {
            params: {
                api_key: sessionStorage.getItem(STORAGE_KEYS.API_KEY),
                query: searchTerm,
                search_center: searchCenter.lat + ',' + searchCenter.lng,
                uid: serverUrls.uid
            }, timeout: configTimers.searchCallTimeOut
        });
    }
    
    getW3WFromLatLng(latlng) {
        const addressSearchUrl = sessionStorage.getItem(STORAGE_KEYS.BASE_URL) + serverUrls.w3wUrl;

        return axios.get(addressSearchUrl, {
            params: {
                api_key: sessionStorage.getItem(STORAGE_KEYS.API_KEY),
                coordinates: latlng
            }, timeout: configTimers.searchCallTimeOut
        });
    }

    getReverseGeocode(lngLat) {
        let searchUrl = sessionStorage.getItem(STORAGE_KEYS.BASE_URL) + serverUrls.reversegeocodeUrl;
        return axios.get(searchUrl, {
            params: {
                api_key: sessionStorage.getItem(STORAGE_KEYS.API_KEY),
                search_center: lngLat,
                named_road: 'True'
            }, timeout: configTimers.reverseGeocodeTimeOut
        });
    }

    getAddressSearchResultsByPoiId(searchCenter, poiId) {
        const addressSearchUrl = sessionStorage.getItem(STORAGE_KEYS.BASE_URL) + serverUrls.addressSearchUrl;

        if (addressSearchUrl.includes('v2')) {
            return axios.get(addressSearchUrl, {
                params: {
                    api_key: sessionStorage.getItem(STORAGE_KEYS.API_KEY),
                    point_address_id: poiId,
                    uid: serverconfigs.uid,
                    search_center: `${searchCenter.lat}, ${searchCenter.lng}`,
                }
            });
        } else {
            return axios.get(addressSearchUrl, {
                params: {
                    api_key: sessionStorage.getItem(STORAGE_KEYS.API_KEY),
                    place_id: "PA:" + poiId,
                    uid: serverconfigs.uid,
                    search_center: searchCenter.lat + ',' + searchCenter.lng
                }
            });
        }
    }
}